export const TRACK_DATA = "helix/TRACK_DATA";

export default function trackData(id, adHocData, options) {
    return {
            "payload": {id,
                        adHocData,
                        options},
            "type": TRACK_DATA
        };
}
