module.exports = {
  "accountManagement": {
    "accountListHeading": "People (%{accountCount})",
    "active": "Active User",
    "add": "Add",
    "addPerson": "Add Person",
    "captchaInvalid": "Please verify that you're not a robot.",
    "cancel": "Cancel",
    "changePassword": "Change Password",
    "changesSaved": "Changes Saved",
    "contactInfo": "Contact Info",
    "currentPassword": "Current Password",
    "currentPasswordEmptyMessage": "Please enter your current password",
    "subUserError": {
      "invalidUserMessage": "The user created is invalid, please contact support",
      "tooManyUsersMessage": "Email limit reached.  Please contact support for additional email creation.",
      "validationErrorMessage": "User information is invalid, please verify all information entered below.",
      "409": "This email address is already taken. Please try another."
    },
    "success": "Success",
    "description": "Welcome to User Account Options. User Account Options enable you to administer " +
      "your email account. An email account consists of a Master User and optional Sub Users. As " +
      "the Master User you have full control of your Account Sub Users.",
    "emailAddress": "Email Address",
    "enabled": "Enable User",
    "firstNameCharacter": "First name may only contain letters.",
    "firstNameLabel": "First Name",
    "firstNameLength": "First name must be between 2 and 30 characters in length.",
    "firstNameNotEmpty": "First name must not be blank.",
    "inactive": "Inactive User",
    "PrimaryAccountLabel": "Primary",
    "StandardAccountLabel": "Standard",
    "lastNameCharacter": "Last name may only contain letters.",
    "lastNameLabel": "Last Name",
    "lastNameLength": "Last name must be between 2 and 30 characters in length.",
    "lastNameNotEmpty": "Last name must not be blank.",
    "manage": "Manage",
    "manageContactInfo": "Manage Contact Info",
    "manageUpdatePassword": "Update Password",
    "managePasswordLabel": "New Password",
    "manageConfirmNewPassword": "Confirm New Password",
    "noRepeatCharacter": "Don't repeat the same character three times in a row.",
    "options": "Options for %{first} %{last}",
    "ok": "OK",
    "passwordCharacterMessage": "Include a number, uppercase letter, lowercase letter and any of the following " +
      "special characters: ! @ # $ % - _",
    "passwordDisallowedWordsMessage": "Don't use the following terms: welcome, abc, qwer, asdf, zxcv, 123, 4321, " +
      "charter, spectrum, timewarner, roadrunner, password, default, or changeme.",
    "passwordEmptyMessage": "Please enter a valid password.",
    "passwordInvalidMessage": "This password doesn't meet our guidelines. Please enter a different password.",
    "passwordNotUnique": "New passwords must be unique",
    "passwordContainsUsernameInvalidMessage": "Please create a password that doesn't contain your email address",
    "passwordGuideMessage": "Please create a password that doesn’t include your email address and follows these " +
      "guidelines:",
    "passwordLabel": "Password",
    "passwordLengthMessage": "Keep length between 8 and 16 characters.",
    "passwordConfirmLabel": "Confirm Password",
    "passwordConfirmEmptyMessage": "Please re-enter your new password.",
    "passwordConfirmInvalidMessage": "The passwords you entered don't match. Please enter them again.",
    "people": "People",
    "restore": "Restore",
    "restored": "Standard User Restored",
    "delete": "Delete",
    "deleted": "Standard User Deleted",
    "save": "Save",
    "statusTitle": "Email Services",
    "statusTitleWithName": "Email Services for %{firstName} %{lastName}",
    "statusDisabled": "Disabled",
    "statusEnabled": "Enabled",
    "statusPendingDelete": "User Pending Delete",
    "usernameLabel": "Username",
    "usernameInvalidMessage": "Please create a Sub User ID that follows the guidelines.",
    "usernameGuideMessage": "Please create a Sub User ID that follows these guidelines:",
    "usernameLengthMessage": "Keep length between 1 and 32 characters.",
    "usernameNoStartEndPeriod": "Does not start or end with a period.",
    "usernameAllowedCharacters": "Characters allowed include: A-Z, a-z , 0-9 . - _",
    "yourProfile": "Your Profile"
  },
  "application": {
    "errors": {
      "default": "We're sorry, something didn't work quite right. Please try again.",
      "error": "Error",
      "genericError": "Please correct the following errors.",
      "genericError2": "Please correct the following errors to continue.",
      "systemErrorMessage": "An unexpected error has occurred.  Please try again later.",
      "notFoundMessage": "An unexpected error has occured. Please try again later.",
      "unauthorizedRequestMessage": "An unexpected error has occured. Please try again later.",
      "subUserURLMissingMessage": "An unexpected error has occured. Please try again later.",
      "oauthURLMissingMessage": "An unexpected error has occured. Please try again later.",
      "createSubUserGeoIPDenied": "Creating an email address is unavailable from your location." +
        " Please try again later from your home or ",
      "nothingWrongMessage": "Please update information to continue",
      "429": "Feature Unavailable",
      "noAccount": "This info doesn't match our records for your account. Please check your entry and try again"
    },
    "on": "On",
    "off": "Off"
  },
  "footer": {
    "accessibility": "If you're a customer with a disability, please contact us if you need assistance.",
    "ccpa": {
      "yourPrivacyRights": "Your Privacy Rights",
      "consumerPrivacyRights": "California Consumer Privacy Rights",
      "consumerDoNotSell": "Do Not Sell or Share My Personal Information/Opt-Out of Targeted Advertising",
      "consumerLimitUse": "California Consumer Limit The Use Of My Sensitive Personal Information"
    },
    "contact": "Contact Us",
    "legal": {
      "assist": "Go to Assist",
      "copyright": "© %{copyrightYear} Charter Communications",
      "policies": "Policies"
    },
    "location": "Find a Spectrum Store",
    "manageAccount": {
      "accountSummary": "Account Summary",
      "billing": "Billing",
      "internet": "Internet",
      "mobile": "Mobile",
      "settings": "Settings",
      "title": "Manage Account",
      "tv": "TV",
      "voice": "Voice"
    },
    "socialMedia": {
      "facebook": "Visit our Facebook page (opens new window)",
      "heading": "Social Media Links",
      "twitter": "Visit our Twitter page (opens new window)",
      "youtube": "Visit our YouTube page (opens new window)"
    },
    "support": {
      "home": "Support Home",
      "internet": "Internet",
      "support": "Account Support",
      "title": "Get Support",
      "tv": "TV",
      "voice": "Voice"
    },
    "watchTv": {
      "dvr": "DVR",
      "guide": "Guide",
      "library": "My Library",
      "liveTv": "Live TV",
      "onDemand": "On Demand",
      "title": "Watch TV"
    },
    "weather": "Get Weather Outage Info"
  },
  "header": {
    "closeMenu": "Close Menu",
    "getSupport": "Get Support",
    "manageAccount": "Manage Account",
    "menu": "MENU",
    "newWindow": "Opens in new window",
    "support": "Support",
    "watchTv": "Watch TV",
    "webmailHome": "Webmail home page"
  },
  "loading": "Loading",
  "loginForm": {
    "accountLock": "You’ve exceeded the maximum number of sign-in attempts. Please try again in 15 minutes.",
    "emailHelp": "Please enter your email.",
    "emailLabel": "Email",
    "error": "Error",
    "errors": {
      "401": "The info you entered doesn't match our records. Please try again.",
      "423": "You've exceeded the maximum number of sign-in attempts. Please try again later.",
      "default": "We're sorry, something didn't work quite right. Please try again.",
      "userFetch": "We're sorry, we were unable to fetch your account data.  Please try again."
    },
    "forgotEmail": "Forgot Email Address?",
    "forgotPassword": "Forgot Email Password?",
    "ipLocked": "You've exceeded the maximum number of sign-in attempts. Please try again later.",
    "loginUrlMissing": "Login service is down",
    "passwordHelp": "Please enter your password.",
    "passwordLabel": "Password",
    "rememberMeLabel": "Remember Me",
    "title": "Sign in to Self Care"
  },
  "navTabs": {
    "info": "Your Info",
    "people": "People"
  },
  "password": {
    "show": "Show",
    "hide": "Hide"
  },
  "passwordReset": {
    "accountValidationHeader": "Confirm your identity",
    "accountValidationInstructions": "To get started with a password reset, please verify the email address and MAC " +
      "address associated with your account.",
    "answer": "Answer",
    "answerQuestion": "Answer Your Security Question",
    "answerQuestionGuidance": "For added security, please answer your security question.",
    "cancel": "Cancel",
    "caseSensitive": "This answer is case-sensitive.",
    "email": "Email Address",
    "error": {
      "emptyAnswer": "Please enter the answer to your security question.",
      "emptyQuestion": "Please select a security question.",
      "invalidAnswer": "The answer you entered doesn't match your security question. Please try again.",
      "invalidCharacters": "The only special characters you can include in your answer are . _ % + - ? '",
      "invalidEmail": "Please enter a valid email address.",
      "invalidLength": "Please include at least three characters in your answer.",
      "selectQuestion": "Please select a security question."
    },
    "exampleEmailAddress": "Example: username@example.com",
    "passwordResetConfirmation": "Password Reset",
    "resetPassword": "Reset Your Password",
    "resetPasswordGuidance": "Enter a new password. Make sure it's different from your previous passwords.",
    "saveAndContinue": "Save & Continue",
    "saveAndSignIn": "Save & Sign In",
    "securityQuestion": "Security Question",
    "selectQuestion": "Select a Security Question",
    "selectQuestionDefault": "Select One",
    "selectQuestionGuidance": "For added account security, please choose a security question below. This will be " +
      "used to verify your identity if you forget your email address or password in the future."
  },
  "signIn": "Sign In",
  "signOut": "Sign Out",
  "subUserForm": {
    "errors": {
      "401": "The info you entered doesn't match our records. Please try again.",
      "403": "You are not authorized to access this page. Please try again.",
      "404": "The requested page wasn't available. Please try again.",
      "429": "You've exceeded the maximum number of sub-user accounts.",
      "default": "We are sorry, something didn't work quite right. Please try again."
    }
  },
  "usernameReset": {
    "cancel": "Cancel",
    "cableModemId": "MAC Address",
    "cableModemIdLink": "How to find your MAC Address",
    "continue": "Continue",
    "error": {
      "invalidNumber": "Please enter a valid phone number.",
      "invalidMacAddress": "Please enter a valid MAC address."
    },
    "exampleCableModemId": "Example: 0012ab34cd5e",
    "examplePhone": "Example: (123) 555-9401",
    "header": "Username Retrieval Tool",
    "instruction": "To recover your email address, please verify the phone number and MAC address associated with " +
      "your account.",
    "phone": "Phone Number",
    "resetPassword": "Reset Password",
    "successHeader": "Email Address Found"
  }
};
