export const POST_CREATE_SUB_USER_ACTION = "sub-users/POST_CREATE_SUB_USER_ACTION";

export default function postCreateSubUserAction(user) {
  return {
    "payload": {
      user
    },
    "type": POST_CREATE_SUB_USER_ACTION
  };
}
