import validateUsernameChars from "./string-has-username-chars";
import validateStringLength from "./string-length";
import validateNoStartDot from "./string-has-no-dot-at-start";
import validateNoEndDot from "./string-has-no-dot-at-end";

export function isUsernameValidAggregation(username) {
  const aggregate = {};
  aggregate.hasNeededChar = validateUsernameChars(username);
  aggregate.isLengthValid = validateStringLength(username, 1, 32);
  aggregate.hasNoStartEndDot = validateNoStartDot(username) && validateNoEndDot(username);
  aggregate.isValid = aggregate.hasNeededChar &&
    aggregate.isLengthValid &&
    aggregate.hasNoStartEndDot;

  return aggregate;
}

export default function isUsernameValid(username) {
  return isUsernameValidAggregation(username).isValid;
}
