export const MANAGE_SUB_USER_PASSWORD_ERROR = "sub-users/MANAGE_SUB_USER_PASSWORD_ERROR";

export default function manageSubUserPasswordErrorAction(error = "", data) {
  return {
    "payload": {
      error,
      data
    },
    "type": MANAGE_SUB_USER_PASSWORD_ERROR
  };
}
