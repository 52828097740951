import produce from "immer";
import {APPLICATION_ERROR_CLOSE_ACTION} from "../actions/application/error/application-error-close-action";
import {APPLICATION_ERROR_ACTION} from "../actions/application/error/application-error-action";

function initialState() {
  if (localStorage.getItem("selfcare-form")) {
    return JSON.parse(localStorage.getItem("selfcare-form"));
  }

  return {
    "errorActive": null,
    "errorStatus": "default"
  };
}

export default (state = initialState(), action = {}) => produce(state, draft => {
  switch (action.type) {
    case APPLICATION_ERROR_ACTION:
      draft.errorStatus = action.payload;
      draft.errorActive = true;

      return draft;

    case APPLICATION_ERROR_CLOSE_ACTION:
      draft.errorActive = false;

      return draft;

    default:
      return state;
  }
});
