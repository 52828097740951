import validateFieldNotEmpty from "./empty-field";

export function isPasswordConfirmValidAggregation(password, passwordConfirm) {
  const aggregate = {};
  aggregate.isNotEmpty = validateFieldNotEmpty(passwordConfirm);
  aggregate.isValid = password === passwordConfirm;

  return aggregate;
}

export default function isPasswordConfirmValid(password, passwordConfirm) {
  return isPasswordConfirmValidAggregation(password, passwordConfirm).isValid;
}
