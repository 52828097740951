export const MANAGE_SUB_USER_ERROR = "sub-users/MANAGE_SUB_USER_ERROR";

export default function manageSubUserErrorAction(error = "", data) {
  return {
    "payload": {
      error,
      data
    },
    "type": MANAGE_SUB_USER_ERROR
  };
}
