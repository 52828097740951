import AnalyticsService from "../../components/helix/analytics";
import loadAnalyticsService from "./load-analytics-service-action";
import {get} from "lodash";

export default function initialize(store) {
    const helixConfig = get(store.getState(), "config.data.helix.data");
    const analytics = new AnalyticsService(helixConfig);

    store.dispatch(loadAnalyticsService(analytics));
}
