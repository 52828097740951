import React from "react";
import {Translate} from "react-i18nify";
import PropTypes from "prop-types";
import SubAccountRow from "./sub-account-row";
import {connect} from "react-redux";
import Tabs from "../tabs/index";
import SubAccountStickyList from "./sub-account-sticky-list";
import Loader from "../loader";
import applicationStartActionCreator from "../../action-creators/application-start-action-creator";
import canCreateSubUser from "../../utilities/can-create-sub-user";
import {scroller} from "react-scroll";
import trackPageView from "../../actions/helix/track-pageview-action";

class SubAccountList extends React.Component {
  constructor(props) {
    super(props);
    this.strollToActiveUser = this.strollToActiveUser.bind(this);

    this.userRefs = [];
  }

  strollToActiveUser() {
    scroller.scrollTo(this.props.scrollToUser, {
      "duration": 500,
      "delay": 200,
      "smooth": true,
      "offset": -50
    });
  }

  static get propTypes() {
    const userPropTypes = PropTypes.shape({
      "email": PropTypes.string,
      "firstName": PropTypes.string,
      "isHoh": PropTypes.bool,
      "lastName": PropTypes.string,
      "status": PropTypes.oneOf([
        "Enabled",
        "Disabled",
        "Pending Delete"
      ])
    });

    return {
      "activeUser": PropTypes.string,
      "appStart": PropTypes.func,
      "loading": PropTypes.bool,
      "loadingStatus": PropTypes.string,
      "loaderSuccessMessage": PropTypes.string,
      "loaderFailureMessage": PropTypes.string,
      "maxUsers": PropTypes.number,
      "user": userPropTypes,
      "scrollToUser": PropTypes.string,
      "trackPageView": PropTypes.func,
      "userList": PropTypes.arrayOf(userPropTypes)
    };
  }

  componentDidUpdate() {
    if (this.props.scrollToUser) {
      this.strollToActiveUser();
    }
  }

  componentDidMount() {
    this.props.appStart(this.props.user);
    this.props.trackPageView();
  }

  createSubUserButton() {
    if (!canCreateSubUser(this.props)) {
      return "";
    }

    return <div className="row d-block d-md-none">
      <div className="col-sm-3 col-xs-12 mb-3 mt-1">
        <button className="btn btn-primary btn-block" id="add-user-button">
          <Translate value="accountManagement.addPerson"/>
        </button>
      </div>
    </div>;
  }

  render() {
    return (
      <div className="container">
        <Loader fullscreen={true} overlay={true}/>
        <Tabs showUserList={this.props.user.isHoh}/>
        <div className="account-list-heading row">
          <div className="col-md-8 col-12">
            <h3>
              <Translate value="accountManagement.accountListHeading" accountCount={this.props.userList.length + 1}/>
            </h3>
            <p>
              <Translate value="accountManagement.description"/>
            </p>
          </div>
        </div>

        {this.createSubUserButton()}

        <div className="row">
          <div className="account-list-quick-nav col-3 d-sm-none d-md-block order-1">
            <SubAccountStickyList userRefs={this.userRefs} />
          </div>
          <div className="account-list-cards col-md-8 col-12 order-0">
            <SubAccountRow user={this.props.user} userRefs={this.userRefs} />
            {this.props.userList.map((user, index) => <SubAccountRow user={user} key={index} userRefs={this.userRefs} />)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  "activeUser": state.activeUser.userId,
  "scrollToUser": state.activeUser.toUserId,
  "maxUsers": state.user.maxUsers,
  "user": state.user,
  "userList": state.subUsers.users
});

function mapDispatchToProps(dispatch) {
  return {
    "appStart": () => dispatch(applicationStartActionCreator()),
    "trackPageView": () => dispatch(trackPageView("SELFCARE_pageView_people", "people"))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubAccountList);
