export const UPDATE_LOGIN_PASSWORD = "login_form/UPDATE_LOGIN_PASSWORD";

export default function updateLoginPassword(password) {
  return {
    "payload": {
      password
    },
    "type": UPDATE_LOGIN_PASSWORD
  };
}
