import produce from "immer";
import {POST_ACCOUNT_VALIDATION_ACTION} from "../actions/password-reset/post-account-validation-action";
import {get} from "lodash";
import {POST_SET_SECURITY_QUESTION_ACTION} from "../actions/password-reset/post-set-security-question-action";
import {POST_VERIFY_SECURITY_QUESTION_ACTION} from "../actions/password-reset/post-verify-security-question-action";

const initialState = () => ({
  "securityQuestion": "",
  "securityQuestionList": [],
  "securityAnswer": ""
});

export default (state = initialState(), action = {}) => produce(state, draft => {
  switch (action.type) {
    case POST_ACCOUNT_VALIDATION_ACTION:
      draft.securityQuestion = get(action, "payload.securityQuestion");
      draft.securityQuestionList = get(action, "payload.securityQuestionList");

      return draft;
    case POST_VERIFY_SECURITY_QUESTION_ACTION:
      draft.securityAnswer = get(action, "payload.answer");

      return draft;
    case POST_SET_SECURITY_QUESTION_ACTION:
      draft.securityQuestion = get(action, "payload.question");
      draft.securityAnswer = get(action, "payload.answer");

      return draft;
    default:
      return state;
  }
});
