export const POST_MANAGE_USER = "users/POST_MANAGE_USER";

export default function postManageUserAction(user) {
  return {
    "payload": {
      user
    },
    "type": POST_MANAGE_USER
  };
}
