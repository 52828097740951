export const POST_ENABLE_DISABLE_SUB_USER = "sub-users/POST_ENABLE_DISABLE_SUB_USER";

export default function postEnableDisableSubUserAction(user) {
  return {
    "payload": {
      user
    },
    "type": POST_ENABLE_DISABLE_SUB_USER
  };
}
