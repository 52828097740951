import axios from "axios";
import "core-js";
import {get} from "lodash";
import queryString from "querystring";
import preManageUserAction from "../../actions/users/pre-manage-user-action";
import manageUserErrorAction from "../../actions/users/manage-user-error-action";
import postManageUserAction from "../../actions/users/post-manage-user-action";
import isManageUserPasswordValid from "../../validators/is-manage-user-password-valid-aggregator";
import API from "../../action-creators/common/api-checks";
import UserCheck from "../../action-creators/common/api-user-checks";

// eslint-disable-next-line camelcase
export default function manageUserPasswordActionCreator() {
  /* eslint-disable max-statements */
  return (dispatch, getState) => {

    const state = getState();

    const formData = {
      "userId": get(state, "user.userId"),
      "currentPassword": get(state, "currentPasswordField.currentPassword"),
      "password": get(state, "passwordFields.password"),
      "passwordConfirm": get(state, "passwordFields.passwordConfirm")
    };

    if (formData.currentPassword === formData.password) {
      dispatch(manageUserErrorAction("accountManagement.passwordNotUnique", formData));

      return false;
    }

    if (!isManageUserPasswordValid(formData)) {
      dispatch(manageUserErrorAction("application.errors.genericError", formData));

      return false;
    }

    const url = state.config.get("api.urls.manageUserPassword");
    if (!url) {
      dispatch(manageUserErrorAction("application.errors.subUserURLMissingMessage", formData));

      return false;
    }

    const payloadData = {
      "old_password": formData.currentPassword,
      "new_password": formData.password,
      "re_new_password": formData.passwordConfirm
    };

    dispatch(preManageUserAction(state.user));

    return axios({
      "headers": {
        ...API.getAuthorization(state),
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": queryString.stringify(payloadData),
      "method": "PATCH",
      url
    })
      .then(response => {
        if (API.hasData(response) && UserCheck.hasUserFields(response.data)) {
          dispatch(postManageUserAction(response.data));

          return true;
        }
        dispatch(manageUserErrorAction("application.errors.systemErrorMessage", formData));

        return false;
      })
      .catch(error => {
        dispatch(manageUserErrorAction(error, formData));

        return false;
      });
  };
}
