import React from "react";
import {connect} from "react-redux";
import getId from "react-id-generator";
import PropTypes from "prop-types";
import {Translate, I18n} from "react-i18nify";
import updateMacAddressAction from "../../actions/mac-address/update-mac-address-action";
import blurMacAddressAction from "../../actions/mac-address/blur-mac-address-action";
import trackData from "../../actions/helix/track-data-action";

class MacAddressField extends React.Component {
  constructor(props) {
    super(props);
    this.handleMacAddressLink = this.handleMacAddressLink.bind(this);
  }

  static get propTypes() {
    return {
      "blurMacAddress": PropTypes.func,
      "inputRef": PropTypes.object,
      "macAddress": PropTypes.string,
      "macAddressError": PropTypes.string,
      "macAddressHelpLink": PropTypes.string,
      "updateMacAddress": PropTypes.func,
      "trackMacAddressUse": PropTypes.func
    };
  }

  handleMacAddressLink(event) {
    event.preventDefault();
    this.props.trackMacAddressUse(this.props.macAddressHelpLink);
    setTimeout(() => {
      window.location.href = this.props.macAddressHelpLink;
    }, 100);
  }

  render() {
    let macAddressInputClass = "";
    let macAddressError = "";

    const id = getId();
    const showError = this.props.macAddressError !== "";

    if (showError) {
      macAddressError = <Translate
        className="mac-address-error d-block"
        value={`usernameReset.${this.props.macAddressError}`}/>;
      macAddressInputClass = "border-danger";
    }

      return <div className="form-group">
        <label className="w-100">
          <Translate value="usernameReset.cableModemId"/>
          <input
            aria-invalid={showError}
            aria-required
            aria-describedby={id}
            type="text"
            className={`mac-address-input form-control focus-outline-primary col-sm-12 col-md-7 col-lg-5 col-xl-4 ${macAddressInputClass}`}
            onChange={this.props.updateMacAddress}
            onBlur={this.props.blurMacAddress}
            placeholder={I18n.t("usernameReset.exampleCableModemId")}
            ref={this.props.inputRef}
            name="macAddress"
            value={this.props.macAddress}/>
        </label>
        <small
          className="text-danger"
          aria-live="polite"
          id={id}>
          {macAddressError}
        </small>
        <a href="" onClick={this.handleMacAddressLink}><Translate value="usernameReset.cableModemIdLink"/></a>
      </div>;

  }
}

const mapStateToProps = state => ({
  "macAddress": state.macAddress.macAddress,
  "macAddressError": state.macAddress.macAddressError,
  "macAddressHelpLink": state.config.get("self-care.cableModemHelp")
});

const mapDispatchToProps = dispatch => ({
  "updateMacAddress": event => {
    dispatch(updateMacAddressAction(event.target.value));
  },
  "blurMacAddress": () => {
    dispatch(blurMacAddressAction());
  },
  "trackMacAddressUse": linkUrl => {
    dispatch(trackData("SELFCARE_selectAction_link", {
      "currPageElemUiName": "How to find your MAC address",
      "currPageElemUrl": linkUrl,
      "sendImmediately": true
    }));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MacAddressField);
