import isUsernameValid from "./is-username-valid-aggregator";
import isPasswordValid from "./is-password-valid-aggregator";
import isNameValid from "./is-name-valid-aggregator";
import isPasswordConfirmValid from "./is-password-confirm-valid-aggregator";
import isCaptchaValid from "./is-captcha-valid-aggregator";

// eslint-disable-next-line max-params
export default function isCreateSubUserValid(data) {
  return isUsernameValid(data.userId) &&
    isNameValid(data.firstName) &&
    isNameValid(data.lastName) &&
    isPasswordValid(data.password, data.userId) &&
    isPasswordConfirmValid(data.password, data.passwordConfirm) &&
    isCaptchaValid(data.recaptcha);
}
