import React from "react";

class DynamicHints extends React.Component {
  render() {
    return (
      <div className="dynamic-hint-group">
        {/* eslint-disable-next-line react/prop-types */}
        {this.props.children}
      </div>
    );
  }
}

export default DynamicHints;
