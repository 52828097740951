export const UPDATE_CURRENT_PASSWORD = "users/UPDATE_CURRENT_PASSWORD";

export default function updateCurrentPasswordAction(currentPassword) {
  return {
    "payload": {
      currentPassword
    },
    "type": UPDATE_CURRENT_PASSWORD
  };
}
