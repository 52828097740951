import {get} from "lodash";

export default {
  "getAuthorization": state => ({"Authorization": `Bearer ${get(state, "token.accessToken")}`}),
  "hasData": response => {
    if (!response || !response.data) {

        return false;
    }

    return response;
  }
};
