export const URT_ERROR_ACTION = "username-recovery/URT_ERROR_ACTION";

export default function usernameRecoveryErrorAction(error) {
  return {
    "payload": {
      error
    },
    "type": URT_ERROR_ACTION
  };
}
