import React from "react";
import PropTypes from "prop-types";
import {Translate} from "react-i18nify";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import newPasswordActionCreator from "../../action-creators/password-reset/new-password-action-creator";
import PasswordFields from "../password-fields";
import trackPageView from "../../actions/helix/track-pageview-action";
import trackData from "../../actions/helix/track-data-action";
import checkForError from "../../utilities/track-ui-error";

class NewPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setFocusToError = this.setFocusToError.bind(this);
    this.passwordRef = React.createRef();
    this.passwordConfirmRef = React.createRef();
  }

  static get propTypes() {
    return {
      "email": PropTypes.string,
      "history": PropTypes.object,
      "passwordError": PropTypes.string,
      "passwordConfirmError": PropTypes.string,
      "errorMessage": PropTypes.string,
      "submitNewPassword": PropTypes.func,
      "trackPageView": PropTypes.func,
      "trackCancelAction": PropTypes.func,
      "trackError": PropTypes.func
    };
  }

  handleCancel(event) {
    event.preventDefault();
    this.props.trackCancelAction();
    this.props.history.push("/login");
  }

  async handleSubmit(event) {
    event.preventDefault();
    await this.props.submitNewPassword();
    this.setFocusToError();

    return false;
  }

  setFocusToError() {
    if (this.props.passwordError && this.passwordRef.current) {
      this.passwordRef.current.focus();

      return;
    }
    if (this.props.passwordConfirmError && this.passwordConfirmRef.current) {
      this.passwordConfirmRef.current.focus();
    }
  }

  componentDidMount() {
    this.props.trackPageView();
  }

  componentDidUpdate(prevProps) {
    checkForError(prevProps, this.props, "accountManagement", "passwordError", this.passwordRef);
    checkForError(prevProps, this.props, "accountManagement", "passwordConfirmError", this.passwordConfirmRef);
  }

  render() {
    return <form className="security-question-form w-100" onSubmit={this.handleSubmit}>
      <h1 className="h3"><Translate value="passwordReset.resetPassword"/></h1>
      <div className="form-section">
        <div className="form-group">
          <label className="d-block">
            <Translate className="font-weight-bold" value="accountManagement.emailAddress"/>
            <p className="new-password-email">{this.props.email}</p>
          </label>
        </div>
        <p className="new-password-guidance"><Translate value="passwordReset.resetPasswordGuidance"/></p>
      </div>
      <div className="form-section">
        <PasswordFields
          inputPasswordRef={this.passwordRef}
          inputPasswordConfirmRef={this.passwordConfirmRef}/>
      </div>
      <div className="form-row m-0">
        <div className="col-12 col-lg-3 order-1 order-lg-0 p-0 m-0 mr-lg-4">
          <button
            type="reset"
            className="btn btn-secondary btn-block m-0 new-password-cancel-btn"
            onClick={this.handleCancel}>
            <Translate value="passwordReset.cancel"/>
          </button>
        </div>
        <div className="col-12 col-lg-3 order-0 order-lg-1 p-0 m-0 mb-3 mb-lg-0">
          <button
            className="btn btn-primary btn-block m-0 new-password-submit-btn"
            type="submit">
            <Translate value="passwordReset.saveAndSignIn"/>
          </button>
        </div>
      </div>
    </form>;
  }
}

const mapStateToProps = state => ({
  "email": state.emailAddress.emailAddress,
  "passwordError": state.passwordFields.passwordError,
  "passwordConfirmError": state.passwordFields.passwordConfirmError,
  "errorMessage": state.apiError.errorMessage
});

const mapDispatchToProps = dispatch => ({
  "submitNewPassword": () => dispatch(newPasswordActionCreator()),
  "trackPageView": () => dispatch(trackPageView(
      "SELFCARE_pageView_passwordReset",
      "PRT",
      {"currPageTitle": "Reset Your Password"})),
  "trackCancelAction": () => dispatch(trackData("SELFCARE_selectAction_cancel")),
  "trackError": errorMessage => dispatch(trackData(
      "SELFCARE_error_PRT",
      {
        "appErrorType": "generic",
        "appErrorMessage": errorMessage
      }
  ))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewPasswordForm));
