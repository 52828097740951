export const POST_DELETE_SUB_USER_ACTION = "sub-users/POST_DELETE_SUB_USER_ACTION";

export default function postDeleteSubUserAction(data, user) {
  return {
    "payload": {
      data,
      user
    },
    "type": POST_DELETE_SUB_USER_ACTION
  };
}
