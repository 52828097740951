import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Translate} from "react-i18nify";
import getId from "react-id-generator";
import validateCurrentPasswordAction from "../../actions/users/validate-current-password-action";
import toggleCurrentPasswordShowHideAction from "../../actions/users/toggle-current-password-show-hide-action";
import updateCurrentPasswordAction from "../../actions/users/update-current-password-action";

class CurrentPasswordField extends React.Component {
  static get propTypes() {
    return {
      "inputCurrentPasswordRef": PropTypes.object,
      "currentPassword": PropTypes.string,
      "currentPasswordError": PropTypes.string,
      "showCurrentPasswordChars": PropTypes.bool,
      "toggleCurrentPasswordShowHide": PropTypes.func,
      "updateCurrentPassword": PropTypes.func,
      "validateCurrentPassword": PropTypes.func
    };
  }

  render() {
    const id = getId();
    let showHideTextKey = "password.show";
    let elementType = "password";

    if (this.props.showCurrentPasswordChars) {
      showHideTextKey = "password.hide";
      elementType = "text";
    }

    let currentPasswordInputClass = "";
    let currentPasswordError = "";
    const showError = this.props.currentPasswordError !== "";

    if (showError) {
      currentPasswordError = <Translate
        className="current-password-error d-block"
        value={`accountManagement.${this.props.currentPasswordError}`}/>;
      currentPasswordInputClass = "border-danger";
    }

    return (
      <div className="form-group">
        <label>
          <Translate value="accountManagement.currentPassword"/>
          <input
            aria-invalid={showError}
            aria-describedby={id}
            type={elementType}
            className={`current-password-input form-control ${currentPasswordInputClass}`}
            onChange={this.props.updateCurrentPassword}
            onBlur={this.props.validateCurrentPassword}
            ref={this.props.inputCurrentPasswordRef}
            name="current-password"
            value={this.props.currentPassword}/>
        </label>
        <button
          onClick={this.props.toggleCurrentPasswordShowHide}
          className="current-password-show-hide d-block d-lg-inline p-0 mx-0 mt-2 mb-3 mx-lg-3 my-lg-0 b-none bg-transparent"
          type="button">
          <small>
            <Translate className="text-primary" value={showHideTextKey} />
          </small>
        </button>
        <small
          className="text-danger"
          aria-live="polite"
          id={id}>
          {currentPasswordError}
        </small>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  "currentPassword": state.currentPasswordField.currentPassword,
  "currentPasswordError": state.currentPasswordField.currentPasswordError,
  "showCurrentPasswordChars": state.currentPasswordField.showCurrentPasswordChars
});

const mapDispatchToProps = dispatch => ({
  "validateCurrentPassword": () => {
    dispatch(validateCurrentPasswordAction(true));
  },
  "toggleCurrentPasswordShowHide": () => {
    dispatch(toggleCurrentPasswordShowHideAction());
  },
  "updateCurrentPassword": event => {
    dispatch(updateCurrentPasswordAction(event.target.value));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentPasswordField);

