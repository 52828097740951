export const POST_MANAGE_SUB_USER = "sub-users/POST_MANAGE_SUB_USER";

export default function postManageSubUserAction(user) {
  return {
    "payload": {
      user
    },
    "type": POST_MANAGE_SUB_USER
  };
}
