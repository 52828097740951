import React from "react";

import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Translate} from "react-i18nify";
import getId from "react-id-generator";
import updateLastNameAction from "../../actions/sub-users/update-last-name-action";
import validateLastNameAction from "../../actions/sub-users/validate-last-name-action";

class LastNameField extends React.Component {
  static get propTypes () {
    return {
      "lastName": PropTypes.string,
      "lastNameError": PropTypes.string,
      "inputRef": PropTypes.object,
      "updateLastName": PropTypes.func,
      "validateLastName": PropTypes.func
    };
  }

  render () {
    const id = getId();
    const showError = this.props.lastNameError !== "";
    let lastNameInputClass = "";
    let lastNameError = "";

    if (showError) {
      lastNameError = <Translate
        className="last-name-error d-block"
        value={`accountManagement.${this.props.lastNameError}`}/>;
      lastNameInputClass = "border-danger";
    }

    return (
      <div className="form-group">
        <label>
          <Translate value="accountManagement.lastNameLabel"/>
          <input
            aria-invalid={showError}
            aria-required
            aria-describedby={id}
            type="text"
            className={`last-name-input form-control ${lastNameInputClass}`}
            onChange={this.props.updateLastName}
            onBlur={this.props.validateLastName}
            ref={this.props.inputRef}
            name="last-name"
            value={this.props.lastName}
          />
        </label>
        <small
          className="text-danger"
          aria-live="polite"
          id={id}>
          {lastNameError}
        </small>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  "lastName": state.nameFields.lastName,
  "lastNameError": state.nameFields.lastNameError
});

const mapDispatchToProps = dispatch => ({
  "updateLastName": event => {
    dispatch(updateLastNameAction(event.target.value));
  },
  "validateLastName": () => {
    dispatch(validateLastNameAction());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LastNameField);
