export const CREATE_SUB_USER_ERROR_ACTION = "sub-users/CREATE_SUB_USER_ERROR_ACTION";

export default function createSubUserErrorAction(error = "", data = "") {
  return {
    "payload": {
      error,
      data
    },
    "type": CREATE_SUB_USER_ERROR_ACTION
  };
}
