export const APPLICATION_ERROR_ACTION = "application/ERROR";

export default function applicationErrorAction(errorCode) {
  return {
    "payload": {
      errorCode
    },
    "type": APPLICATION_ERROR_ACTION
  };
}
