import axios from "axios";
import "core-js";
import preGetSubUserAction from "../../actions/sub-users/pre-get-sub-user-action";
import getSubUserActionErrorAction from "../../actions/sub-users/get-sub-user-action-error-action";
import postGetSubUserAction from "../../actions/sub-users/post-get-sub-user-action";
import isUserValid from "../../validators/is-user-valid";
import {get} from "lodash";
import signOut from "@internet-features/header/src/actions/sign-out-action";

async function handleError(dispatch, error) {
  const status = get(error, "response.status", 500);

  if (status === 403) {
    return;
  }

  await dispatch(signOut(error));
  dispatch(getSubUserActionErrorAction(error));
}

export default function getSubUsersActionCreator() {
  return (dispatch, getState) => {
    const state = getState();

    if (get(state, "user.email", null) && !get(state, "user.isHoh")) {
      return false;
    } else if (get(state, "subUsers.users", []).length > 0) {
      return false;
    }

    const url = state.config.get("api.urls.subUsers");

    dispatch(preGetSubUserAction());

    if (!url) {
      return handleError(dispatch,"SubUsers URL Missing");
    }

    return axios({
      "headers": {
        "Authorization": `Bearer ${get(state, "token.accessToken")}`
      },
      "method": "GET",
      url
    }).then(response => {
      const users = get(response, "data._embedded.users", []);

      if (users.length < 1) {
        return dispatch(postGetSubUserAction(users));
      }

      const returnUsers = [];

      for (let key = users.length - 1; key >= 0; key -= 1) {
        if (isUserValid(users[key])) {
          returnUsers.push(users[key]);
        }
      }

      return dispatch(postGetSubUserAction(returnUsers));
    })
    .catch(error => handleError(dispatch, error));
  };
}
