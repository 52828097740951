import validateFieldNotEmpty from "./empty-field";
import validateLettersOnly from "./string-alpha-only";
import validateStringLength from "./string-length";

export function isNameValidAggregation(name) {
  const aggregate = {};
  aggregate.isNotEmpty = validateFieldNotEmpty(name);
  aggregate.hasNeededChar = validateLettersOnly(name);
  aggregate.isLengthValid = validateStringLength(name, 2, 30);
  aggregate.isValid = aggregate.isNotEmpty &&
    aggregate.hasNeededChar &&
    aggregate.isLengthValid;

  return aggregate;
}

export default function isNameValid(name) {
  return isNameValidAggregation(name).isValid;
}
