import produce from "immer";
import {POST_SSO_LOGIN_ACTION} from "../actions/authentication/post-sso-login-action";
import isTokenValid from "../validators/is-sso-token-valid";
import {SIGN_OUT} from "@internet-features/header/src/actions/sign-out-action";
import {SSO_LOGIN_ERROR_ACTION} from "../actions/authentication/sso-login-error-action";
import {get} from "lodash";

function initialState() {
  if (localStorage.getItem("auth")) {
    return JSON.parse(localStorage.getItem("auth"));
  }

  return {
    "forceRedirect": false,
    "isAuthenticated": false
  };
}

export default (state = initialState(), action = {}) => produce(state, draft => {
  const token = get(action, "payload.token");

  switch (action.type) {
    case POST_SSO_LOGIN_ACTION:
      if (!isTokenValid(token)) {
        draft.isAuthenticated = false;

        return draft;
      }

      draft.isAuthenticated = true;

      localStorage.setItem("auth", JSON.stringify(draft));

      return draft;

    case SIGN_OUT:
      localStorage.clear();
      draft.isAuthenticated = false;
      draft.forceRedirect = true;

      return draft;

    case SSO_LOGIN_ERROR_ACTION:
      if (get(action, "payload.error.response.status") === 401 &&
        get(action, "payload.error.response.data.title") === "invalid_sso_token"
      ) {
        if (localStorage.getItem("auth")) {
          localStorage.removeItem("auth");
        }

        draft.isAuthenticated = false;
        draft.forceRedirect = true;

        return draft;
      }

      return state;
    default:

      return state;
  }
});
