export const POST_RESTORE_SUB_USER_ACTION = "sub-users/POST_RESTORE_SUB_USER_ACTION";

export default function postRestoreSubUserAction(user) {
  return {
    "payload": {
      user
    },
    "type": POST_RESTORE_SUB_USER_ACTION
  };
}
