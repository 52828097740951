export const UPDATE_LAST_NAME = "sub-users/UPDATE_LAST_NAME";

export default function updateLastNameAction (lastName) {
  return {
    "payload": {
      lastName
    },
    "type": UPDATE_LAST_NAME
  };
}
