export const UPDATE_MAC_ADDRESS_ACTION = "mac-address/UPDATE_MAC_ADDRESS_ACTION";

export default function updateMacAddressAction(macAddress) {
  return {
    "payload": {
      macAddress
    },
    "type": UPDATE_MAC_ADDRESS_ACTION
  };
}
