import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import getId from "react-id-generator";
import {Translate} from "react-i18nify";
import {Overlay, Popover} from "react-bootstrap";
import updateUsernameAction from "../../actions/sub-users/update-username-action";
import validateUsernameAction from "../../actions/sub-users/validate-username-action";
import focusUsernameAction from "../../actions/sub-users/focus-username-action";
import blurUsernameAction from "../../actions/sub-users/blur-username-action";
import DynamicHint from "../dynamic-hint/dynamic-hint";
import DynamicHints from "../dynamic-hint";

class UsernameField extends React.Component {
  static get propTypes() {
    return {
      "hasFocus": PropTypes.bool,
      "isEditable": PropTypes.bool,
      "isUsernameCharacters": PropTypes.bool,
      "isUsernameLengthValid": PropTypes.bool,
      "isUsernameNoStartEndDot": PropTypes.bool,
      "inputRef": PropTypes.object,
      "email": PropTypes.string,
      "username": PropTypes.string,
      "usernameError": PropTypes.string,
      "updateUsername": PropTypes.func,
      "focusUsername": PropTypes.func,
      "blurUsername": PropTypes.func
    };
  }

  render() {
    let hintClass = "d-none";
    let usernameInputClass = "";
    let usernameError = "";

    const id = getId();
    const showError = this.props.isEditable && this.props.usernameError !== "" && !this.props.hasFocus;
    const hints = <DynamicHints>
      <Translate value="accountManagement.usernameGuideMessage" className="font-weight-bold"/>
      <DynamicHint isValid={this.props.isUsernameLengthValid}><Translate value={"accountManagement.usernameLengthMessage"} /></DynamicHint>
      <DynamicHint isValid={this.props.isUsernameNoStartEndDot}><Translate value="accountManagement.usernameNoStartEndPeriod"/></DynamicHint>
      <DynamicHint isValid={this.props.isUsernameCharacters}><Translate value="accountManagement.usernameAllowedCharacters"/></DynamicHint>
    </DynamicHints>;

    if (this.props.isEditable && this.props.hasFocus) {
      hintClass = "d-block d-lg-none";
    }

    if (showError) {
      usernameError = <Translate
        className="username-error d-block"
        value={`accountManagement.${this.props.usernameError}`}/>;
      usernameInputClass = "border-danger";
    }

    if (this.props.isEditable) {
      return <div className="form-group">
        <label>
          <Translate value="accountManagement.usernameLabel"/>
          <input
            aria-invalid={showError}
            aria-required
            aria-describedby={id}
            type="text"
            className={`username-input form-control ${usernameInputClass}`}
            onChange={this.props.updateUsername}
            onBlur={this.props.blurUsername}
            onFocus={this.props.focusUsername}
            ref={this.props.inputRef}
            name="username"
            value={this.props.username}/>
        </label>
        <Overlay
          placement="right"
          show={this.props.hasFocus}
          target={this.props.inputRef.current}>
          <Popover className="d-none d-lg-block" id={id}>
            <Popover.Content>
              {hints}
            </Popover.Content>
          </Popover>
        </Overlay>
        <small
          className="text-danger"
          aria-live="polite"
          aria-hidden={!showError}>
          {usernameError}
        </small>
        <div className={`username-hints ${hintClass}`}>
          {hints}
        </div>
      </div>;
    }

    return <div className="form-group">
      <Translate value="accountManagement.emailAddress" className="font-weight-bold" /><br />
      <span className="username">{this.props.email}</span>
    </div>;
  }
}

const mapStateToProps = state => ({
  "hasFocus": state.usernameField.focusUsername,
  "isUsernameCharacters": state.usernameField.isUsernameCharacters,
  "isUsernameLengthValid": state.usernameField.isUsernameLengthValid,
  "isUsernameNoStartEndDot": state.usernameField.isUsernameNoStartEndDot,
  "email": state.usernameField.email,
  "username": state.usernameField.username,
  "usernameError": state.usernameField.usernameError
});

const mapDispatchToProps = dispatch => ({
  "updateUsername": event => {
    dispatch(updateUsernameAction(event.target.value));
    dispatch(validateUsernameAction());
  },
  "blurUsername": () => {
    dispatch(blurUsernameAction());
    dispatch(validateUsernameAction());
  },
  "focusUsername": () => {
    dispatch(focusUsernameAction());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(UsernameField);
