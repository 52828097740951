export const LOAD_ANALYTICS_SERVICE = "helix/LOAD_ANALYTICS_SERVICE";

export default function loadAnalyticsService(analyticsService) {
    return dispatch => {
        dispatch({
            "payload": analyticsService,
            "type": LOAD_ANALYTICS_SERVICE
        });
    };
}
