import produce from "immer";
import {has} from "lodash";
import {VERIFY_CAPTCHA} from "../actions/application/captcha/verify-captcha-action";
import {EXPIRE_CAPTCHA} from "../actions/application/captcha/expire-captcha-action";
import {POST_SSO_LOGIN_ACTION} from "../actions/authentication/post-sso-login-action";
import {SSO_LOGIN_ERROR_ACTION} from "../actions/authentication/sso-login-error-action";
import {SIGN_OUT} from "@internet-features/header/src/actions/sign-out-action";
import {HIDE_CREATE_SUB_USER_MODAL} from "../actions/sub-users/hide-create-sub-user-modal-action";
import {CREATE_SUB_USER_ERROR_ACTION} from "../actions/sub-users/create-sub-user-error-action";
import {SHOW_CREATE_SUB_USER_MODAL} from "../actions/sub-users/show-create-sub-user-modal-action";
import {POST_CREATE_SUB_USER_ACTION} from "../actions/sub-users/post-create-sub-user-action";
import isCaptchaValid from "../validators/is-captcha-valid-aggregator";
import {URT_ERROR_ACTION} from "../actions/username-recovery/username-recovery-error-action";
import {LOGIN_USER_ERROR} from "../actions/login-form/login-user-error-action";
import {ACCOUNT_VALIDATION_ERROR_ACTION} from "../actions/password-reset/account-validation-error-action";

function initialState() {
  return {
    "captchaError": "",
    "captchaToken": ""
  };
}

export default (state = initialState(), action = {}) => produce(state, draft => {
  switch (action.type) {
    case EXPIRE_CAPTCHA:
    case SHOW_CREATE_SUB_USER_MODAL:
    case HIDE_CREATE_SUB_USER_MODAL:
    case POST_CREATE_SUB_USER_ACTION:
    case POST_SSO_LOGIN_ACTION:
    case SIGN_OUT:

      return initialState();
    case SSO_LOGIN_ERROR_ACTION:
    case CREATE_SUB_USER_ERROR_ACTION:
    case URT_ERROR_ACTION:
    case ACCOUNT_VALIDATION_ERROR_ACTION:
      if (!isCaptchaValid(draft.captchaToken)) {
        draft.captchaError = "accountManagement.captchaInvalid";

        return draft;
      }

      return initialState();
    case VERIFY_CAPTCHA:
      if (!has(action, "payload")) {
        return draft;
      }

      draft.captchaToken = action.payload;

      if (!isCaptchaValid(draft.captchaToken)) {
        draft.captchaError = "accountManagement.captchaInvalid";

        return draft;
      }
      draft.captchaError = "";

      return draft;
    case LOGIN_USER_ERROR:
      // eslint-disable-next-line no-case-declarations
      draft.captchaToken = action.payload.data.recaptcha;
      if (!isCaptchaValid(draft.captchaToken)) {
        draft.captchaError = "accountManagement.captchaInvalid";

        return draft;
      }
      draft.captchaError = "";

      return draft;
    default:
      return state;
  }
});
