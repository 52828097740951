export const TRACK_PAGE_VIEW = "helix/TRACK_PAGE_VIEW";

/* eslint-disable-next-line max-params */
export default function trackPageView(id, currPageName, adHocData, options) {
    return {
        "payload": {
            id,
            currPageName,
            adHocData,
            options
        },
        "type": TRACK_PAGE_VIEW
    };
}
