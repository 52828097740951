import React from "react";
import {Translate} from "react-i18nify";
import "./tabs.scss";
import {NavLink, withRouter} from "react-router-dom";
import PropTypes from "prop-types";

class Tabs extends React.Component {
  static get propTypes() {
    return {
      "location": PropTypes.object,
      "showUserList": PropTypes.bool
    };
  }

  render() {
    let infoActive = "";
    let userList = "";

    if (this.props.location.pathname === "/info") {
      infoActive = " active";
    } else if (this.props.location.pathname === "/people") {
      userList = " active";
    }

    if (!this.props.showUserList) {
      userList += " d-none";
    }

    return (
      <div className="tabs row justify-content-md-center mb-5 ml-sm-0 ml-1">
        <div className={`tab col-3 col-md-2 col-xl-1 text-center ${infoActive}`}><p>
          <NavLink className="text-nowrap" to="/info"><Translate value="navTabs.info"/></NavLink>
        </p></div>
        <div className={`tab col-3 col-md-2 col-xl-1 text-center ${userList}`}><p>
          <NavLink className="text-nowrap" to="/people"><Translate value="navTabs.people"/></NavLink>
        </p></div>
      </div>
    );
  }
}

export default withRouter(Tabs);
