import React from "react";
import {connect} from "react-redux";
import {I18n, Translate} from "react-i18nify";
import PropTypes from "prop-types";
import {Dropdown} from "react-bootstrap";
import Icon from "@internet-features/icon";
import showManageUserPasswordModalAction from "../../actions/users/show-manage-user-password-modal-action";

class AccountInfoMenu extends React.Component {
  constructor(props) {
    super(props);
    this.isOpen = false;
    this.menuRef = React.createRef();
    this.toggleMenu = this.toggleMenu.bind(this);

  }

  toggleMenu(isOpen) {
    this.isOpen = isOpen;
  }

  static get propTypes() {
    return {
      "className": PropTypes.string,
      "showManageUserPassword": PropTypes.func,
      "showManageUser": PropTypes.func,
      "user": PropTypes.shape({
        "email": PropTypes.string,
        "firstName": PropTypes.string,
        "isHoh": PropTypes.bool,
        "lastName": PropTypes.string,
        "status": PropTypes.oneOf([
          "Enabled",
          "Disabled",
          "Pending Delete"
        ])
      })
    };
  }

  render() {
    // eslint-disable-next-line react/display-name,react/prop-types
    const CleanToggle = React.forwardRef(({children, onClick}, ref) => <button
        ref={ref}
        className="dropdown-button border-0"
        aria-expanded={this.isOpen}
        onClick={event => {
          event.preventDefault();
          onClick(event);
        }}>
        {children}
      </button>
    );

    return <>
      <Dropdown
        className={this.props.className}
        focusFirstItemOnShow="keyboard"
        alignRight
        onToggle={this.toggleMenu}>
        <Dropdown.Toggle as={CleanToggle} ref={this.menuRef}>
          <Icon
            name="ki-more-vert-f"
            className="kite-icon kebab"
            aria-label={I18n.t("accountManagement.options", {
              "first": this.props.user.firstName,
              "last": this.props.user.lastName
            })}/>
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0">
          <Dropdown.Item className="changePassword-btn py-2 pl-3 pr-5"
                         onClick={() => this.props.showManageUserPassword(this.props.user, this.menuRef)}>
            <Translate value="accountManagement.manageUpdatePassword"/>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>;
  }
}

const mapDispatchToProps = dispatch => ({
  "showManageUserPassword": (user, returnFocusRef) => {
    dispatch(showManageUserPasswordModalAction(user, returnFocusRef));
  }
});

export default connect(null, mapDispatchToProps)(AccountInfoMenu);
