import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Modal from "../modal";
import {Translate, I18n} from "react-i18nify";
import UsernameField from "../username-field";
import NameFields from "../name-fields";
import hideManageSubUserNameModal from "../../actions/sub-users/hide-manage-sub-user-name-modal-action";
import manageSubUserNameActionCreator from "../../action-creators/users/manage-sub-user-name-action-creator";
import manageSubUserErrorAction from "../../actions/sub-users/manage-sub-user-error-action";
import checkForError from "../../utilities/track-ui-error";
import trackData from "../../actions/helix/track-data-action";

class ManageSubUserNameForm extends React.Component {
  constructor(props) {
    super(props);

    this.firstNameRef = React.createRef();
    this.lastNameRef = React.createRef();

    this.setFocusToError = this.setFocusToError.bind(this);
    this.manageSubUserSubmit = this.manageSubUserSubmit.bind(this);
  }

  static get propTypes() {
    return {
      "formErrorMessage": PropTypes.string,
      "hideNameForm": PropTypes.func,
      "isManageNameOpen": PropTypes.bool,
      "returnFocusRef": PropTypes.object,
      "isNameModified": PropTypes.bool,
      "nudgeUser": PropTypes.func,
      "submitName": PropTypes.func,
      "firstNameError": PropTypes.string,
      "lastNameError": PropTypes.string,
      "trackError": PropTypes.func,
      "user": PropTypes.object
    };
  }

  setFocusToError() {
    if (this.props.firstNameError && this.firstNameRef.current) {
      this.firstNameRef.current.focus();

      return;
    }
    if (this.props.lastNameError && this.lastNameRef.current) {
      this.lastNameRef.current.focus();
    }
  }

  async manageSubUserSubmit(event) {
    event.preventDefault();

    if (this.props.isNameModified) {
      await this.props.submitName(this.props.user);
      this.setFocusToError();

      return false;
    }

    this.props.nudgeUser();

    return false;
  }

  componentDidUpdate(prevProps) {
    checkForError(prevProps, this.props, "accountManagement", "firstNameError", this.firstNameRef);
    checkForError(prevProps, this.props, "accountManagement", "lastNameError", this.lastNameRef);
  }

  render() {
    return <Modal
      show={this.props.isManageNameOpen}
      size="lg"
      title={I18n.t("accountManagement.manageContactInfo")}
      error={this.props.formErrorMessage}
      onHide={this.props.hideNameForm}
      returnFocusRef={this.props.returnFocusRef}>
      <form className="manage-sub-user-name" onSubmit={this.manageSubUserSubmit}>
        <div className="form-section">
          <UsernameField isEditable={false} />
          <NameFields
            inputFirstNameRef={this.firstNameRef}
            inputLastNameRef={this.lastNameRef}/>
        </div>
        <div className="form-row m-0">
          <div className="col-12 col-lg-3 order-1 order-lg-0 p-0 m-0 mr-lg-4">
            <button
              className="btn btn-secondary btn-block m-0 manage-sub-user-cancel-btn"
              type="reset"
              onClick={this.props.hideNameForm}>
              <Translate value="accountManagement.cancel"/>
            </button>
          </div>
          <div className="col-12 col-lg-3 order-0 order-lg-1 p-0 m-0 mb-3 mb-lg-0">
            <button
              className="btn btn-primary btn-block m-0 manage-sub-user-submit-btn"
              type="submit">
              <Translate value="accountManagement.save"/>
            </button>
          </div>
        </div>
      </form>
    </Modal>;
  }
}

const mapStateToProps = state => ({
  "formErrorMessage": state.apiError.errorMessage,
  "isManageNameOpen": state.forms.isManageSubUserNameFormOpen,
  "returnFocusRef": state.forms.returnFocusRef,
  "isNameModified": state.nameFields.isNameModified,
  "firstNameError": state.nameFields.firstNameError,
  "lastNameError": state.nameFields.lastNameError
});

const mapDispatchToProps = dispatch => ({
  "hideNameForm": () => dispatch(hideManageSubUserNameModal()),
  "submitName": () => dispatch(manageSubUserNameActionCreator()),
  "nudgeUser": () => dispatch(manageSubUserErrorAction("application.errors.nothingWrongMessage")),
  "trackError": errorMessage => dispatch(trackData(
      "SELFCARE_error_manageContactInfoError",
      {
        "appErrorType": "generic",
        "appErrorMessage": errorMessage
      }
  ))
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageSubUserNameForm);
