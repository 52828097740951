import axios from "axios";
import "core-js";
import {get} from "lodash";
import queryString from "querystring";
import stripHex from "../../formatters/strip-hexadecimals";
import isPasswordFormValidAggregator from "../../validators/is-password-form-valid-aggregator";
import prePasswordResetAction from "../../actions/password-reset/pre-password-reset-action";
import passwordResetErrorAction from "../../actions/password-reset/password-reset-error-action";
import postPasswordResetAction from "../../actions/password-reset/post-password-reset-action";

export default function newPasswordActionCreator() {
  return (dispatch, getState) => {
    const state = getState();

    const formData = {
      "email": get(state, "emailAddress.emailAddress"),
      "userId": get(state, "emailAddress.emailAddress").split("@")[0],
      "macAddress": get(state, "macAddress.macAddress"),
      "securityQuestion": get(state, "passwordReset.securityQuestion"),
      "securityAnswer": get(state, "passwordReset.securityAnswer"),
      "password": get(state, "passwordFields.password"),
      "passwordConfirm": get(state, "passwordFields.passwordConfirm")
    };

    const url = getState().config.get("api.urls.passwordReset");

    if (!url) {
      dispatch(passwordResetErrorAction("URT URL Missing"));

      return false;
    }

    if (!isPasswordFormValidAggregator(formData)) {
      dispatch(passwordResetErrorAction("application.errors.genericError"));

      return false;
    }

    const payload = {
      "email": formData.email,
      "device_id": stripHex(formData.macAddress),
      "question": formData.securityQuestion,
      "answer": formData.securityAnswer,
      "new_password": formData.password,
      "re_new_password": formData.passwordConfirm
    };

    dispatch(prePasswordResetAction());

    return axios({
        "headers": {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        "data": queryString.stringify(payload),
        "method": "PATCH",
        url
      }
    )
    .then(response => {
      if (!response.data.success) {
        dispatch(passwordResetErrorAction(response));

        return;
      }
      dispatch(postPasswordResetAction());
    })
    .catch(error => {
      dispatch(passwordResetErrorAction(error));
    });
  };
}
