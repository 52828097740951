import produce from "immer";
import {PRE_URT_ACTION} from "../actions/username-recovery/pre-username-recovery-action";
import {START_URT_ACTION} from "../actions/username-recovery/start-username-recovery-action";
import {POST_URT_ACTION} from "../actions/username-recovery/post-username-recovery-action";

export const initialState = () => ({
  "emailAddress": "",
  "errorMessage": ""
});

export default (state = initialState(), action = {}) => produce(state, draft => {
  switch (action.type) {
    case PRE_URT_ACTION:
    case START_URT_ACTION:

      return initialState();
    case POST_URT_ACTION:
      draft.emailAddress = action.payload.email;

      return draft;
    default:
      return state;
  }
});
