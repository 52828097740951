export const POST_GET_USER_ACTION = "users/POST_GET_USER_ACTION";

export default function postGetUserAction(user, isoWhiteList) {
  return {
    "payload": {
      user,
      isoWhiteList
    },
    "type": POST_GET_USER_ACTION
  };
}
