import {every, has, partial} from "lodash";

const keys = [
  "token_type",
  "expires_in",
  "access_token",
  "refresh_token"
];

export default token => every(keys, partial(has, token));
