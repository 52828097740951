import validateEmail from "./email";
import validateMacAddress from "./mac-address-is-valid";
import isCaptchaValid from "./is-captcha-valid-aggregator";

export default function isAccountValidationValid(email, mac, captcha) {
  if (email && mac && captcha) {
    return validateEmail(email) && validateMacAddress(mac) && isCaptchaValid(captcha);
  }

  return false;
}
