import axios from "axios";
import "core-js";
import preGetUserAction from "../../actions/users/pre-get-user-action";
import getUserActionError from "../../actions/users/get-user-action-error";
import postGetUserAction from "../../actions/users/post-get-user-action";
import isUserValid from "../../validators/is-user-valid";
import {get} from "lodash";
import signOut from "@internet-features/header/src/actions/sign-out-action";

async function handleError(dispatch, message) {
  await dispatch(signOut(message));
  dispatch(getUserActionError(message));
}

export default function getUserActionCreator() {
  return (dispatch, getState) => {
    if (get(getState(), "user.email")) {
      return false;
    }

    const url = getState().config.get("api.urls.user");

    dispatch(preGetUserAction());

    if (!url) {
      return handleError(dispatch,"User URL Missing");
    }

    const state = getState();

    return axios({
      "headers": {
        "Authorization": `Bearer ${get(state, "token.accessToken")}`
      },
      "method": "GET",
      url
    }).then(response => {
      if (typeof response.data === "undefined") {
        return handleError(dispatch,"User not found");
      }

      if (!isUserValid(response.data)) {
        return handleError(dispatch,"User is invalid");
      }
      const isoWhiteList = state.config.get("geoIpWhitelist", []);

      return dispatch(postGetUserAction(response.data, isoWhiteList));
    })
    .catch(error => handleError(dispatch, error));
  };
}
