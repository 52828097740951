import React, {Component} from "react";
import {Translate, I18n} from "react-i18nify";
import PropTypes from "prop-types";
import showManageSubUserNameModalAction from "../../actions/sub-users/show-manage-sub-user-name-modal-action";
import showManageSubUserPasswordModalAction from "../../actions/sub-users/show-manage-sub-user-password-modal-action";
import deleteSubUserActionCreator from "../../action-creators/users/delete-sub-user-action-creator";
import restoreSubUserActionCreator from "../../action-creators/users/restore-sub-user-action-creator";
import {connect} from "react-redux";
import {Dropdown} from "react-bootstrap";
import Icon from "@internet-features/icon";

class SubAccountRowMenu extends Component {
  constructor(props) {
    super(props);
    this.isOpen = false;
    this.menuRef = React.createRef();
    this.toggleMenu = this.toggleMenu.bind(this);

  }

  toggleMenu(isOpen) {
    this.isOpen = isOpen;
  }

  static get propTypes() {
    return {
      "className": PropTypes.string,
      "deleteSubUser": PropTypes.func,
      "restoreSubUser": PropTypes.func,
      "showManageSubUserName": PropTypes.func,
      "showManageSubUserPassword": PropTypes.func,
      "user": PropTypes.shape({
        "email": PropTypes.string,
        "firstName": PropTypes.string,
        "isHoh": PropTypes.bool,
        "lastName": PropTypes.string,
        "status": PropTypes.oneOf([
          "Enabled",
          "Disabled",
          "Pending Delete"
        ])
      })
    };
  }

  getButtonItems() {
    const manageNameButton = <Dropdown.Item className="manageName-btn py-2 pl-3 pr-5"
                                            onClick={() => this.props.showManageSubUserName(this.props.user, this.menuRef)}>
      <Translate value="accountManagement.manageContactInfo"/>
    </Dropdown.Item>;

    const managePasswordButton = <Dropdown.Item className="changePassword-btn py-2 pl-3 pr-5"
                                                onClick={() => this.props.showManageSubUserPassword(this.props.user, this.menuRef)}>
      <Translate value="accountManagement.manageUpdatePassword"/>
    </Dropdown.Item>;

    const deleteButton = <Dropdown.Item className="delete-btn py-2 pl-3 pr-5"
                                        onClick={() => this.props.deleteSubUser(this.props.user)}>
      <Translate className="text-danger" value="accountManagement.delete"/>
    </Dropdown.Item>;

    const restoreButton = <Dropdown.Item className="restore-btn py-2 pl-3 pr-5"
                                         onClick={() => this.props.restoreSubUser(this.props.user)}>
      <Translate value="accountManagement.restore"/>
    </Dropdown.Item>;

    if (this.props.user.status === "Pending Delete") {
      return restoreButton;
    }

    if (this.props.user.status === "Disabled") {
      return deleteButton;
    }

    return <>
      {manageNameButton}
      {managePasswordButton}
      {deleteButton}
    </>;
  }

  render() {
    if (this.props.user.isHoh) {
      return "";
    }

    // eslint-disable-next-line react/display-name,react/prop-types
    const CleanToggle = React.forwardRef(({children, onClick}, ref) => <button
        ref={ref}
        className="dropdown-button border-0"
        aria-expanded={this.isOpen}
        onClick={event => {
          event.preventDefault();
          onClick(event);
        }}>
        {children}
      </button>
    );

    return <>
      <Dropdown
        className={this.props.className}
        focusFirstItemOnShow="keyboard"
        alignRight
        onToggle={this.toggleMenu}>
        <Dropdown.Toggle as={CleanToggle} ref={this.menuRef}>
          <Icon
            name="ki-more-vert-f"
            className="kite-icon kebab"
            aria-label={I18n.t("accountManagement.options", {
              "first": this.props.user.firstName,
              "last": this.props.user.lastName
            })}/>
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0">
          {this.getButtonItems()}
        </Dropdown.Menu>
      </Dropdown>
    </>;
  }
}

const mapDispatchToProps = dispatch => ({
  "deleteSubUser": user => {
    dispatch(deleteSubUserActionCreator(user));
  },
  "restoreSubUser": user => {
    dispatch(restoreSubUserActionCreator(user));
  },
  "showManageSubUserPassword": (user, returnFocusRef) => {
    dispatch(showManageSubUserPasswordModalAction(user, returnFocusRef));
  },
  "showManageSubUserName": (user, returnFocusRef) => {
    dispatch(showManageSubUserNameModalAction(user, returnFocusRef));
  }
});

export default connect(null, mapDispatchToProps)(SubAccountRowMenu);
