export const SHOW_MANAGE_SUB_USER_NAME_MODAL = "sub-users/SHOW_MANAGE_SUB_USER_NAME_MODAL";

export default function showManageSubUserNameModalAction(managedUser, returnFocusRef) {
  return {
    "payload": {
      managedUser,
      returnFocusRef
    },
    "type": SHOW_MANAGE_SUB_USER_NAME_MODAL
  };
}
