export const SHOW_MANAGE_SUB_USER_PASSWORD_MODAL = "sub-users/SHOW_MANAGE_SUB_USER_PASSWORD_MODAL";

export default function showManageSubUserPasswordModalAction(managedUser, returnFocusRef) {
  return {
    "payload": {
      managedUser,
      returnFocusRef
    },
    "type": SHOW_MANAGE_SUB_USER_PASSWORD_MODAL
  };
}
