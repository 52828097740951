import React from "react";
import Icon from "@internet-features/icon";
import "./dynamic-hints.scss";
import PropTypes from "prop-types";

class DynamicHint extends React.Component {
  static get propTypes() {
    return {
      "isValid": PropTypes.bool,
      "id": PropTypes.string
    };
  }

  render() {

    // IE11 has a bug with SVG caching, so both icons must be present and shown/hidden only through CSS
    let checkDisplayClass = "d-inline";
    let cautionDisplayClass = "d-none";

    if (!this.props.isValid) {
      checkDisplayClass = "d-none";
      cautionDisplayClass = "d-inline";
    }

    return (
      <div className="dynamic-hint row" id={this.props.id}>
        <div className="dynamic-hint-icon col-1">
          <Icon name="ki-checkmark-circle-f" className={checkDisplayClass} style={
            {
              "fill": "#008516",
              "height": "14px",
              "width": "14px"
            }
          }/>
          <Icon name="ki-caution-circle-f" className={cautionDisplayClass} style={
            {
              "fill": "#d6312b",
              "height": "14px",
              "width": "14px"
            }
          }/>
        </div>
        <div className="dynamic-hint-message col-10">
          {/* eslint-disable-next-line react/prop-types */}
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default DynamicHint;
