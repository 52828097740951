import axios from "axios";
import "core-js";
import queryString from "querystring";
import {get} from "lodash";
import stripHex from "../../formatters/strip-hexadecimals";
import {I18n} from "react-i18nify";
import isNotEmpty from "../../validators/empty-field";
import hasLeastThreeCharacters from "../../validators/string-length";
import hasValidCharacters from "../../validators/string-has-security-question-allowed-chars";
import securityQuestionAnswerErrorAction from "../../actions/password-reset/security-question-answer-error-action";
import securityQuestionErrorAction from "../../actions/password-reset/security-question-error-action";
import setSecurityQuestionErrorAction from "../../actions/password-reset/set-security-question-error-action";
import preSetSecurityQuestionAction from "../../actions/password-reset/pre-set-security-question-action";
import postSetSecurityQuestionAction from "../../actions/password-reset/post-set-security-question-action";

// eslint-disable-next-line max-lines-per-function
export default function setSecurityQuestionActionCreator(question, answer) {
  // eslint-disable-next-line max-statements
  return (dispatch, getState) => {
    const state = getState();

    const formData = {
      "email": get(state, "emailAddress.emailAddress"),
      "userId": get(state, "emailAddress.emailAddress").split("@")[0],
      "macAddress": stripHex(get(state, "macAddress.macAddress")),
      "securityQuestion": question,
      "securityAnswer": answer.trim()
    };

    if (formData.securityQuestion === I18n.t("passwordReset.selectQuestionDefault")) {
      dispatch(securityQuestionErrorAction("passwordReset.error.emptyQuestion"));

      return false;
    }

    if (!isNotEmpty(formData.securityAnswer)) {
      dispatch(securityQuestionAnswerErrorAction("passwordReset.error.emptyAnswer"));

      return false;
    }

    if (!hasLeastThreeCharacters(formData.securityAnswer,3)) {
      dispatch(securityQuestionAnswerErrorAction("passwordReset.error.invalidLength"));

      return false;
    }

    if (!hasValidCharacters(formData.securityAnswer)) {
      dispatch(securityQuestionAnswerErrorAction("passwordReset.error.invalidCharacters"));

      return false;
    }

    const url = getState().config.get("api.urls.securityAnswer");

    if (!url) {
      dispatch(setSecurityQuestionErrorAction("URL Missing"));

      return false;
    }

    const payload = {
      "email": formData.email,
      "device_id": formData.macAddress,
      "question": formData.securityQuestion,
      "answer": formData.securityAnswer
    };

    dispatch(preSetSecurityQuestionAction());

    return axios({
        "headers": {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        "data": queryString.stringify(payload),
        "method": "PATCH",
        url
      }
    )
      .then(response => {
        if (!response.data || !response.data.question || !response.data.answer) {
          dispatch(setSecurityQuestionErrorAction(response));

          return;
        }
        dispatch(postSetSecurityQuestionAction(response.data.question, response.data.answer));
      })
      .catch(error => {
        dispatch(setSecurityQuestionErrorAction(error.response ? error.response.data : error.message));
      });
  };
}
