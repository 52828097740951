import produce from "immer";
import {SET_ACTIVE_USER} from "../actions/application/set-active-user";
import {POST_CREATE_SUB_USER_ACTION} from "../actions/sub-users/post-create-sub-user-action";
import {POST_MANAGE_SUB_USER} from "../actions/sub-users/post-manage-sub-user-action";
import {POST_MANAGE_SUB_USER_PASSWORD} from "../actions/sub-users/post-manage-sub-user-password-action";

function initialState() {
  return {
    "userId": null,
    "toUserId": ""
  };
}

export default (state = initialState(), action = {}) => produce(state, draft => {
  switch (action.type) {
    case POST_MANAGE_SUB_USER:
    case POST_CREATE_SUB_USER_ACTION:
    case POST_MANAGE_SUB_USER_PASSWORD:
      draft.toUserId = action.payload.user.email;

      return draft;
    case SET_ACTIVE_USER:
      draft.userId = action.payload.userId;
      draft.toUserId = "";

      return draft;
    default:
      return state;
  }
});
