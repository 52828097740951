export const VALIDATE_CURRENT_PASSWORD = "users/VALIDATE_CURRENT_PASSWORD";

export default function validateCurrentPasswordAction(suppressError = false) {
  return {
    "payload": {
      suppressError
    },
    "type": VALIDATE_CURRENT_PASSWORD
  };
}
