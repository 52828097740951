import produce from "immer";
import isUserValid from "../validators/is-user-valid";
import {get} from "lodash";
import {SIGN_OUT} from "@internet-features/header/src/actions/sign-out-action";
import {POST_GET_USER_ACTION} from "../actions/users/post-get-user-action";
import {GET_USER_ACTION_ERROR} from "../actions/users/get-user-action-error";

function initialState() {
  const initValue = {
    "email": null,
    "firstName": null,
    "geoIpBlocked": true,
    "isHoh": null,
    "lastName": null,
    "maxUsers": 0,
    "status": null,
    "userId": null
  };

  if (!localStorage.getItem("user")) {
    return initValue;
  }

  const user = JSON.parse(localStorage.getItem("user"));

  if (isUserValid(user)) {
    return user;
  }

  return initValue;
}

const resetDraft = draft => {
  draft.email = null;
  draft.firstName = null;
  draft.geoIpBlocked = false;
  draft.isHoh = null;
  draft.lastName = null;
  draft.status = null;
  draft.maxUsers = 0;
  draft.userId = null;

  return draft;
};

const reset = draft => {
  if (localStorage.getItem("user")) {
    localStorage.removeItem("user");
  }

  return resetDraft(draft);
};

export default (state = initialState(), action = {}) => produce(state, draft => {
  const isoCountryCode = get(action, "payload.user._embedded.geo_ip.iso_code", "");
  const isoWhiteList = get(action, "payload.isoWhiteList", []);
  switch (action.type) {
    case POST_GET_USER_ACTION:
      draft.email = get(action, "payload.user.email");
      draft.firstName = get(action, "payload.user.first_name");
      draft.isHoh = get(action, "payload.user.is_hoh");
      draft.lastName = get(action, "payload.user.last_name");
      draft.status = get(action, "payload.user.status");
      draft.maxUsers = get(action, "payload.user.service_account.maxUsers", 0);
      draft.userId = get(action, "payload.user.user_id");

      draft.geoIpBlocked = true;
      if (isoWhiteList === "*" || isoWhiteList.indexOf(isoCountryCode) !== -1 || isoCountryCode === "") {
        draft.geoIpBlocked = false;
      }

      localStorage.setItem("user", JSON.stringify(draft));

      return draft;
    case GET_USER_ACTION_ERROR:
      return reset(draft);
    case SIGN_OUT:
      return reset(draft);
    default:
      return state;
  }
});
