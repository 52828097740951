export const UPDATE_PHONE_NUMBER_ACTION = "username-recovery/UPDATE_PHONE_NUMBER_ACTION";

export default function updatePhoneNumberAction(phoneNumber) {
  return {
    "payload": {
      phoneNumber
    },
    "type": UPDATE_PHONE_NUMBER_ACTION
  };
}
