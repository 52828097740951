export const SSO_REFRESH_ERROR_ACTION = "authentication/SSO_REFRESH_ERROR_ACTION";

export default function createSSORefreshErrorAction(error) {
  return {
    "payload": {
      error
    },
    "type": SSO_REFRESH_ERROR_ACTION
  };
}
