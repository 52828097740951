import React from "react";
import {Translate} from "react-i18nify";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Loader from "../loader";
import PhoneMacForm from "./phone-mac-form";
import EmailAddressModal from "./email-address-modal";
import FormError from "../error/form";

class UsernameRecovery extends React.Component {

  static get propTypes() {
    return {
      "emailAddress": PropTypes.string,
      "errorMessage": PropTypes.string,
      "loading": PropTypes.bool
    };
  }

  render() {
    let modalContent = "";
    let errorContent = "";
    if (this.props.emailAddress) {
      modalContent = <EmailAddressModal emailAddress={this.props.emailAddress}/>;
    }

    if (this.props.errorMessage) {
      errorContent = <FormError error={this.props.errorMessage}/>;
    }

    return (
      <div className="container mb-4">
        <div className="card primary-card col-sm-12 col-md-10 offset-sm-0 offset-md-1">
          <div className="card-body">
          <Loader show={this.props.loading} overlay={true}/>
          {errorContent}
          <h2>
            <Translate className="username-recovery-header" value="usernameReset.header"/>
          </h2>
          {modalContent}
          <PhoneMacForm/>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  "emailAddress": state.usernameRecovery.emailAddress,
  "errorMessage": state.apiError.errorMessage,
  "loading": state.loader.loading
});

export default connect(mapStateToProps, null)(UsernameRecovery);
