import React from "react";
import PropTypes from "prop-types";
import {I18n, Translate} from "react-i18nify";
import Modal from "../modal";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import trackData from "../../actions/helix/track-data-action";

class EmailAddressModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  static get propTypes() {
    return {
      "emailAddress": PropTypes.string,
      "history": PropTypes.object,
      "trackPageView": PropTypes.func,
      "trackClose": PropTypes.func,
      "trackPasswordReset": PropTypes.func
    };
  }

  handleResetPassword(event) {
    event.preventDefault();
    this.props.history.push("/password-reset");
    this.props.trackPasswordReset();
  }

  handleClose(event) {
    if (event) {
      event.preventDefault();
    }
    this.props.history.push("/login");
    this.props.trackClose("SELFCARE_modalClose");
  }

  handleSignIn(event) {
    event.preventDefault();
    this.props.history.push("/login");
    this.props.trackClose("SELFCARE_selectAction_URTSignIn");
  }

  componentDidMount() {
    this.props.trackPageView();
  }

  render() {
    return <Modal
      show={true}
      size="lg"
      title={I18n.t("usernameReset.successHeader")}
      className="username-recover-modal"
      onHide={this.handleClose}>
      <div className="form-row">
        <div className="col-12 pt-3 pb-3">
          <span className="username-recovery-result">{this.props.emailAddress}</span>
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-12 col-md-3 col-lg-3 order-1 order-md-0">
          <button onClick={this.handleResetPassword} className="btn col-sm-12 col-md-12 btn-secondary focus-outline-primary mr-4">
            <Translate value="usernameReset.resetPassword"/>
          </button>
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3 ml-md-4 ml-lg-5 ml-xl-3 order-0 order-md-1">
          <button onClick={this.handleSignIn} className="btn btn-primary col-sm-12 col-md-12 focus-outline-primary">
            <Translate value="signIn"/>
          </button>
        </div>
      </div>
    </Modal>;
  }
}

const mapDispatchToProps = dispatch => ({
  "trackPageView": () => dispatch(trackData("SELFCARE_modalView_URT", {
    "modalText": "Email Address Found"
  })),
  "trackClose": id => dispatch(trackData(id)),
  "trackPasswordReset": () => dispatch(trackData("SELFCARE_selectAction_URTPRT"))
});

export default withRouter(connect(null, mapDispatchToProps)(EmailAddressModal));
