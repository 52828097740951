export const GET_SUB_USER_ACTION_ERROR = "sub-users/GET_SUB_USER_ACTION_ERROR";

export default function getSubUserActionErrorAction(error) {
  return {
    "payload": {
      error
    },
    "type": GET_SUB_USER_ACTION_ERROR
  };
}
