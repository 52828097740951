import {I18n} from "react-i18nify";

export const SET_LOCALE = "client/translate/SET_LOCALE";

export default function setLocale(locale) {
  return dispatch => {
    dispatch({
      "payload": locale,
      "type": SET_LOCALE
    });
    I18n.forceComponentsUpdate();
  };
}
