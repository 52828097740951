import produce from "immer";
import {LOAD_TRANSLATIONS} from "../actions/load-translations-action";
import {SET_LOCALE} from "../actions/set-locale-action";

export const initialState = () => ({
  "locale": "en_US",
  "translations": {}
});

export default (state = initialState(), action = {}) => produce(state, draft => {
  switch (action.type) {
    case LOAD_TRANSLATIONS:
      draft.translations = action.payload;

      return draft;
    case SET_LOCALE:
      draft.locale = action.payload;

      if (!state.translations[action.payload]) {
        draft.locale = "en_US";
      }

      return draft;
    default:
      return state;
  }
});
