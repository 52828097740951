import React, {Component} from "react";
import {connect} from "react-redux";
import {I18n, Translate} from "react-i18nify";
import Switch from "../switch";
import PropTypes from "prop-types";
import enableDisableSubUserActionCreator from "../../action-creators/users/enable-disable-sub-user-action-creator";

class SubAccountRowSwitch extends Component {
  constructor(props) {
    super(props);

    this.switchUser = this.switchUser.bind(this);
  }

  static get propTypes() {
    return {
      "user": PropTypes.shape({
        "email": PropTypes.string,
        "firstName": PropTypes.string,
        "isHoh": PropTypes.bool,
        "lastName": PropTypes.string,
        "status": PropTypes.oneOf([
          "Enabled",
          "Disabled",
          "Pending Delete"
        ])
      }),
      "toggleSubUserStatus": PropTypes.func,
      "userRefs": PropTypes.array
    };
  }

  switchUser() {
    this.props.toggleSubUserStatus(this.props.user, this.props.user.status !== "Enabled");
  }

  render() {
    const userStatusMessages = {
      "Disabled": "accountManagement.statusDisabled",
      "Enabled": "accountManagement.statusEnabled",
      "Pending Delete": "accountManagement.statusPendingDelete"
    };

    if (this.props.user.isHoh || this.props.user.status === "Pending Delete") {

      return <Translate value={userStatusMessages[this.props.user.status]}/>;
    }

    return <Switch switchCallback={this.switchUser}
                   active={this.props.user.status === "Enabled"}
                   addClasses="float-right mr-0 pr-0"
                   ariaLabel={I18n.t("accountManagement.statusTitleWithName", {
                     "firstName": this.props.user.firstName,
                     "lastName": this.props.user.lastName
                   })}/>;
  }
}

const mapDispatchToProps = dispatch => ({
  "toggleSubUserStatus": (user, statusBool) => dispatch(enableDisableSubUserActionCreator(user, statusBool))
});

export default connect(null, mapDispatchToProps)(SubAccountRowSwitch);
