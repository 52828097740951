export const UPDATE_SECURITY_QUESTION_ANSWER_ACTION = "password-reset/UPDATE_SECURITY_QUESTION_ANSWER_ACTION";

export default function updateSecurityQuestionAnswerAction(answer) {
  return {
    "payload": {
      answer
    },
    "type": UPDATE_SECURITY_QUESTION_ANSWER_ACTION
  };
}
