import axios from "axios";
import "core-js";
import queryString from "querystring";
import preUsernameRecoveryAction from "../../actions/username-recovery/pre-username-recovery-action";
import stripDecimals from "../../formatters/strip-decimals";
import stripHex from "../../formatters/strip-hexadecimals";
import usernameRecoveryErrorAction from "../../actions/username-recovery/username-recovery-error-action";
import postUsernameRecoveryAction from "../../actions/username-recovery/post-username-recovery-action";
import {get} from "lodash";
import isUsernameRecoveryValid from "../../validators/is-username-recovery-valid-aggregator";

export default function usernameRecoveryActionCreator() {
  /* eslint-disable max-statements */
  return (dispatch, getState) => {
    const state = getState();

    const formData = {
      "phoneNumber": get(state, "phoneNumber.phoneNumber"),
      "macAddress": get(state, "macAddress.macAddress"),
      "recaptcha": get(state, "captcha.captchaToken")
    };

    const url = getState().config.get("api.urls.usernameRecovery");

    if (!url) {
      dispatch(usernameRecoveryErrorAction("URT URL Missing"));

      return false;
    }

    if (!isUsernameRecoveryValid(formData.phoneNumber, formData.macAddress, formData.recaptcha)) {
      dispatch(usernameRecoveryErrorAction("application.errors.genericError"));

      return false;
    }

    const payload = {
      "phone_number": stripDecimals(formData.phoneNumber),
      "device_id": stripHex(formData.macAddress),
      "recaptcha": formData.recaptcha
    };

    dispatch(preUsernameRecoveryAction());

    return axios({
        "headers": {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        "data": queryString.stringify(payload),
        "method": "POST",
        url
      }
    )
      .then(response => {
        const email = get(response, "data.email", false);
        if (!email) {
          dispatch(usernameRecoveryErrorAction("application.errors.default"));

          return;
        }

        dispatch(postUsernameRecoveryAction(email));
      })
      .catch(error => {
        dispatch(usernameRecoveryErrorAction(error.response ? error.response.data : error.message));
      });
  };
}
