import produce from "immer";
import {get} from "lodash";
import formatPhoneNumber from "../formatters/phone-number-formatter";
import validPhoneNumber from "../validators/phone-number-is-valid";
import {UPDATE_PHONE_NUMBER_ACTION} from "../actions/phone-number/update-phone-number-action";
import {BLUR_PHONE_NUMBER_ACTION} from "../actions/phone-number/blur-phone-number-action";
import {START_URT_ACTION} from "../actions/username-recovery/start-username-recovery-action";
import {URT_ERROR_ACTION} from "../actions/username-recovery/username-recovery-error-action";

export const initialState = () => ({
  "phoneNumber": "",
  "phoneNumberError": ""
});

export default (state = initialState(), action = {}) => produce(state, draft => {
  switch (action.type) {
    case URT_ERROR_ACTION:
    case BLUR_PHONE_NUMBER_ACTION:
      if (!validPhoneNumber(draft.phoneNumber)) {
        draft.phoneNumberError = "error.invalidNumber";

        return draft;
      }
      draft.phoneNumberError = "";

      return draft;
    case UPDATE_PHONE_NUMBER_ACTION:
      if (validPhoneNumber(get(action, "payload.phoneNumber"))) {
        draft.phoneNumberError = "";
      }

      draft.phoneNumber = formatPhoneNumber(get(action, "payload.phoneNumber"));

      return draft;
    case START_URT_ACTION:
      return initialState();
    default:
      return state;
  }
});
