import React from "react";
import PropTypes from "prop-types";
import PasswordField from "../../components/password-fields/password-field";
import PasswordConfirmField from "../../components/password-fields/password-confirm-field";

class PasswordFields extends React.Component {
  static get propTypes() {
    return {
      "inputPasswordRef": PropTypes.object,
      "inputPasswordConfirmRef": PropTypes.object
    };
  }

  render() {
    return (
      <>
        <PasswordField inputRef={this.props.inputPasswordRef} />
        <PasswordConfirmField inputRef={this.props.inputPasswordConfirmRef} />
      </>
    );
  }
}

export default PasswordFields;
