import React, {Component} from "react";
import PropTypes from "prop-types";
import {Translate, I18n} from "react-i18nify";
import "./loader.scss";
import Icon from "@internet-features/icon";
import FocusLock from "react-focus-lock";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class Loader extends Component {
  constructor(props) {
    super(props);
    this.loaderRef = React.createRef();
    this.handleConfirmationClick = this.handleConfirmationClick.bind(this);
  }

  static get propTypes() {
    return {
      "confirmation": PropTypes.shape({
        "action": PropTypes.func,
        "label": PropTypes.string,
        "route": PropTypes.string,
        "routeState": PropTypes.object
      }),
      "confirmationAction": PropTypes.func,
      "failureMessage": PropTypes.string,
      "fullscreen": PropTypes.bool,
      "history": PropTypes.object,
      "overlay": PropTypes.bool,
      "show": PropTypes.bool,
      "status": PropTypes.string,
      "successMessage": PropTypes.string,
      "secondaryMessage": PropTypes.string,
      "secondaryMessagePayload": PropTypes.object
    };
  }

  handleConfirmationClick() {
    if (this.props.confirmation.action) {
      this.props.confirmationAction(this.props.confirmation.action);
    }

    if (this.props.confirmation.route) {
      this.props.history.push(this.props.confirmation.route, this.props.confirmation.routeState);
    }
  }

  // eslint-disable-next-line max-statements,max-lines-per-function
  render() {
    let focusLocked = false;
    let fullScreenClass = "";
    let overlayClass = "";
    let showClass = "";
    let message = "";
    let checkmarkClass = "";
    let cautionClass = "";
    let spinnerClass = "svg-stroke-primary";
    let loadingMessage = "";
    let animationClass = "";
    let confirmationButton = "";

    if (!this.props.fullscreen) {
      fullScreenClass = "position-absolute";
    }

    if (!this.props.overlay) {
      overlayClass = "d-none";
    }

    if (!this.props.show) {
      animationClass = "stopped";
    }

    if (!this.props.show && (typeof this.props.status === "undefined" || this.props.status === "")) {
      showClass = "hidden";
    }

    if (!this.props.show && typeof this.props.status !== "undefined" && this.props.status !== "") {
      showClass = "statusFadeOut";
    }

    if (this.props.status && this.props.confirmation && this.props.confirmation.label) {
      confirmationButton = <button className="btn btn-primary btn-confirm-action m-0" type="button"
                                   onClick={this.handleConfirmationClick}>
        <Translate value={this.props.confirmation.label}/>
      </button>;
      showClass = "";
      focusLocked = true;
    }

    switch (this.props.status) {
      case "success":
        message = this.props.successMessage;
        checkmarkClass = "fadeIn";
        spinnerClass = "svg-stroke-success spinner-fade-out";
        break;
      case "failure":
        message = this.props.failureMessage;
        cautionClass = "fadeIn";
        spinnerClass = "svg-stroke-danger spinner-fade-out";
        break;
      default:
        message = "";
    }

    if (this.props.show && this.loaderRef.current) {
      this.loaderRef.current.focus();
      loadingMessage = I18n.t("loading");
    }

    return (
      <div ref={this.loaderRef}
           className={`spinner-wrapper align-middle text-center ${fullScreenClass} ${showClass}`}
           aria-live="assertive">
        <FocusLock className="h-100" disabled={!focusLocked} autoFocus={true} >
          <span className="sr-only sr-only-focusable">{loadingMessage}</span>
          <div className={`spinner-overlay bg-light ${overlayClass}`}/>
          <div className="loader-icons">
            <Icon name="ki-checkmark-circle-f"
                  className={`svg-fill-success status-icon ${checkmarkClass}`}
            />
            <Icon name="ki-caution-circle-f"
                  className={`svg-fill-danger status-icon ${cautionClass}`}
            />
            <svg
              className={`spinner ${animationClass}`}
              width="80px"
              height="80px"
              viewBox="0 0 66 66"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                className={`path ${spinnerClass}`}
                fill="none"
                stroke=""
                strokeWidth="4"
                strokeLinecap="round"
                cx="33"
                cy="33"
                r="30"
              />
            </svg>
          </div>
          <h1 className="messages"><Translate value={message}/></h1>
          <h2 className="secondary-messages"><Translate
            value={this.props.secondaryMessage} {...this.props.secondaryMessagePayload}/></h2>
          <h3 className="confirmationButton">{confirmationButton}</h3>
        </FocusLock>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  "show": state.loader.loading,
  "status": state.loader.status,
  "successMessage": state.loader.successMessage,
  "secondaryMessage": state.loader.secondaryMessage,
  "secondaryMessagePayload": state.loader.secondaryMessagePayload,
  "failureMessage": state.apiError.errorMessage,
  "confirmation": state.loader.confirmation
});

const mapDispatchToProps = dispatch => ({
  "confirmationAction": action => {
    dispatch(action());
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Loader));
