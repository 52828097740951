import React from "react";
import PropTypes from "prop-types";
import {Translate} from "react-i18nify";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import updateSecurityQuestionAction from "../../actions/password-reset/update-question-action";
import updateSecurityQuestionAnswerAction from "../../actions/password-reset/update-answer-action";
import setSecurityQuestionActionCreator
  from "../../action-creators/password-reset/set-security-question-action-creator";
import verifySecurityQuestionActionCreator
  from "../../action-creators/password-reset/verify-security-question-action-creator";
import trackPageView from "../../actions/helix/track-pageview-action";
import trackData from "../../actions/helix/track-data-action";
import checkForError from "../../utilities/track-ui-error";

class SecurityQuestionForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static get propTypes() {
    return {
      "answer": PropTypes.string,
      "answerError": PropTypes.string,
      "history": PropTypes.object,
      "question": PropTypes.string,
      "questionError": PropTypes.string,
      "questionList": PropTypes.arrayOf(PropTypes.string),
      "updateSecurityAnswer": PropTypes.func,
      "updateSecurityQuestion": PropTypes.func,
      "submitSetSecurityQuestion": PropTypes.func,
      "submitVerifySecurityQuestion": PropTypes.func,
      "trackPageView": PropTypes.func,
      "trackCancelAction": PropTypes.func,
      "trackError": PropTypes.func
    };
  }

  handleCancel(event) {
    event.preventDefault();
    this.props.trackCancelAction();
    this.props.history.push("/login");
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.props.question) {
      this.props.submitVerifySecurityQuestion(event.target.elements);

      return false;
    }

    this.props.submitSetSecurityQuestion(event.target.elements);

    return false;
  }

  componentDidMount() {
    this.props.trackPageView();
  }

  componentDidUpdate(prevProps) {
    checkForError(prevProps, this.props, "passwordReset", "questionError");
    checkForError(prevProps, this.props, "passwordReset", "answerError");
  }

  // eslint-disable-next-line max-lines-per-function,max-statements
  render() {
    let titleKey = "";
    let guidanceKey = "";
    let question = "";
    let questionSelectClass = "";
    let questionFontWeight = "";

    if (this.props.questionError !== "") {
      questionSelectClass = "text-danger border-danger";
    }

    if (this.props.questionList && this.props.questionList.length) {
      titleKey = "passwordReset.selectQuestion";
      guidanceKey = "passwordReset.selectQuestionGuidance";
      question = <select
          name="security-question-list"
          className={`form-control security-question-list ${questionSelectClass}`}
          onChange={this.props.updateSecurityQuestion}>
          <Translate tag="option" value="passwordReset.selectQuestionDefault"/>
          {this.props.questionList.map(
            (element, key) => <option value={element} key={key}>{element}</option>
          )}
        </select>;
    }

    if (this.props.question) {
      titleKey = "passwordReset.answerQuestion";
      guidanceKey = "passwordReset.answerQuestionGuidance";
      question = <p className="security-question">{this.props.question}</p>;
      questionFontWeight = "font-weight-bold";
    }

    let questionInputClass = "";
    let questionText = this.props.questionError;

    const showQuestionError = this.props.questionError !== "";

    if (showQuestionError) {
      questionInputClass = "text-danger";
      questionText = this.props.questionError;
    }

    let answerInputClass = "";
    let answerText = "passwordReset.caseSensitive";

    const showError = this.props.answerError !== "";

    if (showError) {
      answerInputClass = "text-danger";
      answerText = this.props.answerError;
    }

    return <form className="security-question-form w-100" onSubmit={this.handleSubmit}>
      <h1 className="h3"><Translate value={titleKey}/></h1>
      <p className="security-question-guidance"><Translate value={guidanceKey}/></p>
      <div className="form-section">
        <div className="form-group">
          <label className="d-block w-100">
            <Translate className={questionFontWeight} value="passwordReset.securityQuestion"/>
            {question}
          </label>
          <small>
            <Translate className={questionInputClass} value={questionText}/>
          </small>
        </div>
        <div className="form-group">
          <label className="d-block">
            <Translate value="passwordReset.answer"/>
            <input
              aria-required
              type="text"
              name="security-question-answer-input"
              className="security-question-answer-input form-control"
              onChange={this.props.updateSecurityAnswer}
              value={this.props.answer}/>
          </label>
          <small>
            <Translate className={answerInputClass} value={answerText}/>
          </small>
        </div>
        <div className="form-row m-0">
          <div className="col-12 col-lg-3 order-1 order-lg-0 p-0 m-0 mr-lg-4">
            <button
              type="reset"
              className="btn btn-secondary btn-block m-0 security-question-cancel-btn"
              onClick={this.handleCancel}>
              <Translate value="passwordReset.cancel"/>
            </button>
          </div>
          <div className="col-12 col-lg-3 order-0 order-lg-1 p-0 m-0 mb-3 mb-lg-0">
            <button
              className="btn btn-primary btn-block m-0 security-question-submit-btn"
              type="submit">
              <Translate value="passwordReset.saveAndContinue"/>
            </button>
          </div>
        </div>
      </div>
    </form>;
  }
}

const mapStateToProps = state => ({
  "question": state.passwordReset.securityQuestion,
  "questionList": state.passwordReset.securityQuestionList,
  "answer": state.securityQuestion.answer,
  "answerError": state.securityQuestion.answerError,
  "questionError": state.securityQuestion.questionError
});

const mapDispatchToProps = dispatch => ({
  "updateSecurityQuestion": () => {
    dispatch(updateSecurityQuestionAction());
  },
  "updateSecurityAnswer": event => {
    dispatch(updateSecurityQuestionAnswerAction(event.target.value));
  },
  "submitSetSecurityQuestion": formElements => {
    dispatch(setSecurityQuestionActionCreator(
      formElements["security-question-list"].value,
      formElements["security-question-answer-input"].value));
  },
  "submitVerifySecurityQuestion": formElements => {
    dispatch(verifySecurityQuestionActionCreator(formElements["security-question-answer-input"].value));
  },
  "trackPageView": () => dispatch(trackPageView(
      "SELFCARE_pageView_passwordReset",
      "PRT",
      {"currPageTitle": "Answer Your Security Question"})),
  "trackCancelAction": () => dispatch(trackData("SELFCARE_selectAction_cancel")),
  "trackError": errorMessage => dispatch(trackData(
      "SELFCARE_error_URT",
      {
        "appErrorType": "generic",
        "appErrorMessage": errorMessage
      }
  ))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SecurityQuestionForm));
