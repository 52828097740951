export const UPDATE_PASSWORD = "sub-users/UPDATE_PASSWORD";

export default function updatePasswordAction(password) {
  return {
    "payload": {
      password
    },
    "type": UPDATE_PASSWORD
  };
}
