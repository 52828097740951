import React from "react";
import {connect} from "react-redux";
import getId from "react-id-generator";
import PropTypes from "prop-types";
import {Translate} from "react-i18nify";
import DynamicHints from "../dynamic-hint";
import DynamicHint from "../dynamic-hint/dynamic-hint";
import {Overlay, Popover} from "react-bootstrap";
import blurPasswordAction from "../../actions/sub-users/blur-password-action";
import focusPasswordAction from "../../actions/sub-users/focus-password-action";
import togglePasswordShowHideAction from "../../actions/sub-users/toggle-password-show-hide-action";
import updatePasswordAction from "../../actions/sub-users/update-password-action";

class PasswordField extends React.Component {
  static get propTypes() {
    return {
      "password": PropTypes.string,
      "hasFocus": PropTypes.bool,
      "isPasswordCharacters": PropTypes.bool,
      "isPasswordLengthValid": PropTypes.bool,
      "isPasswordNoRepeatChars": PropTypes.bool,
      "isPasswordPhrase": PropTypes.bool,
      "passwordError": PropTypes.string,
      "blurPassword": PropTypes.func,
      "focusPassword": PropTypes.func,
      "inputRef": PropTypes.object,
      "showPasswordChars": PropTypes.bool,
      "updatePassword": PropTypes.func,
      "togglePasswordShowHide": PropTypes.func
    };
  }

  // eslint-disable-next-line max-lines-per-function,max-statements
  render() {
    let hintClass = "d-none";

    if (this.props.hasFocus) {
      hintClass = "d-block d-lg-none";
    }

    let passwordInputClass = "";
    let passwordError = "";

    const id = getId();
    const showError = this.props.passwordError !== "";

    if (showError) {
      passwordError = <Translate
        className="password-error d-block"
        value={`accountManagement.${this.props.passwordError}`}/>;
      passwordInputClass = "border-danger";
    }

    let showHideTextKey = "password.show";
    let elementType = "password";
    const hints = <DynamicHints>
      <Translate value="accountManagement.passwordGuideMessage" className="font-weight-bold" />
      <DynamicHint isValid={this.props.isPasswordLengthValid}><Translate value="accountManagement.passwordLengthMessage"/></DynamicHint>
      <DynamicHint isValid={this.props.isPasswordNoRepeatChars}><Translate value="accountManagement.noRepeatCharacter"/></DynamicHint>
      <DynamicHint isValid={this.props.isPasswordCharacters}><Translate value="accountManagement.passwordCharacterMessage"/></DynamicHint>
      <DynamicHint isValid={this.props.isPasswordPhrase}><Translate value="accountManagement.passwordDisallowedWordsMessage"/></DynamicHint>
    </DynamicHints>;

    if (this.props.showPasswordChars) {
      showHideTextKey = "password.hide";
      elementType = "text";
    }

    return (
      <div className="form-group">
        <label>
          <Translate value="accountManagement.managePasswordLabel"/>
          <input
            aria-invalid={showError}
            aria-required
            aria-describedby={id}
            type={elementType}
            className={`password-input form-control ${passwordInputClass}`}
            onChange={this.props.updatePassword}
            onBlur={this.props.blurPassword}
            onFocus={this.props.focusPassword}
            ref={this.props.inputRef}
            name="password-input"
            value={this.props.password}/>
        </label>
        <button
          onClick={this.props.togglePasswordShowHide}
          className="password-show-hide d-block d-lg-inline p-0 mx-0 mt-2 mb-3 mx-lg-3 my-lg-0 b-none bg-transparent"
          type="button">
          <small>
            <Translate className="text-primary" value={showHideTextKey}/>
          </small>
        </button>
        <Overlay
          placement="right"
          show={this.props.hasFocus}
          target={this.props.inputRef.current}>
          <Popover className="d-none d-lg-block" id={id}>
            <Popover.Content>
              {hints}
            </Popover.Content>
          </Popover>
        </Overlay>
        <small
          className="text-danger"
          aria-live="polite">
          {passwordError}
        </small>
        <div className={`password-hints ${hintClass}`}>
          {hints}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  "password": state.passwordFields.password,
  "isPasswordCharacters": state.passwordFields.isPasswordCharacters,
  "hasFocus": state.passwordFields.focusPassword,
  "isPasswordLengthValid": state.passwordFields.isPasswordLengthValid,
  "isPasswordNoRepeatChars": state.passwordFields.isPasswordNoRepeatChars,
  "isPasswordPhrase": state.passwordFields.isPasswordPhrase,
  "passwordError": state.passwordFields.passwordError,
  "showPasswordChars": state.passwordFields.showPasswordChars
});

const mapDispatchToProps = dispatch => ({
  "blurPassword": () => {
    dispatch(blurPasswordAction());
  },
  "focusPassword": () => {
    dispatch(focusPasswordAction());
  },
  "togglePasswordShowHide": () => {
    dispatch(togglePasswordShowHideAction());
  },
  "updatePassword": event => {
    dispatch(updatePasswordAction(event.target.value));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordField);
