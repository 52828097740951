import React from "react";
import PropTypes from "prop-types";
import {Translate} from "react-i18nify";
import PhoneNumberField from "../phone-number-field";
import {withRouter} from "react-router-dom";
import MacAddressField from "../mac-address-field";
import {connect} from "react-redux";
import usernameRecoveryActionCreator from "../../action-creators/username-recovery/username-recovery-action-creator";
import Captcha from "../captcha";
import updateMacAddressAction from "../../actions/mac-address/update-mac-address-action";
import updatePhoneNumberAction from "../../actions/phone-number/update-phone-number-action";
import trackPageView from "../../actions/helix/track-pageview-action";
import trackData from "../../actions/helix/track-data-action";
import checkForError from "../../utilities/track-ui-error";

class PhoneMacForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setFocusToError = this.setFocusToError.bind(this);
    this.phoneNumberRef = React.createRef();
    this.macAddressRef = React.createRef();
    this.captchaRef = React.createRef();
  }

  static get propTypes() {
    return {
      "history": PropTypes.object,
      "submitUrt": PropTypes.func,
      "phoneNumberError": PropTypes.string,
      "macAddressError": PropTypes.string,
      "updateMacAddress": PropTypes.func,
      "updatePhoneNumber": PropTypes.func,
      "trackPageView": PropTypes.func,
      "trackCancelAction": PropTypes.func,
      "trackError": PropTypes.func
    };
  }

  handleCancel(event) {
    event.preventDefault();
    this.props.trackCancelAction();
    this.props.history.push("/login");
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.props.updatePhoneNumber(this.phoneNumberRef.current.value);
    this.props.updateMacAddress(this.macAddressRef.current.value);

    await this.props.submitUrt();
    this.setFocusToError();

    if (this.captchaRef.current) {
      this.captchaRef.current.reset();
    }
  }

  setFocusToError() {
    if (this.props.phoneNumberError && this.phoneNumberRef.current) {
      this.phoneNumberRef.current.focus();

      return;
    }
    if (this.props.macAddressError && this.macAddressRef.current) {
      this.macAddressRef.current.focus();
    }
  }

  componentDidMount() {
    this.props.trackPageView();
  }

  componentDidUpdate(prevProps) {
    checkForError(prevProps, this.props, "usernameReset", "phoneNumberError");
    checkForError(prevProps, this.props, "usernameReset", "macAddressError");
  }

  render() {

    return <div className="">
      <Translate value="usernameReset.instruction"/>
      <form onSubmit={this.handleSubmit}>
        <PhoneNumberField inputRef={this.phoneNumberRef}/>
        <MacAddressField inputRef={this.macAddressRef}/>
        <div className={"form-group"}>
          <Captcha captchaRef={this.captchaRef} />
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-3 col-lg-2 order-1 order-md-0">
            <button onClick={this.handleCancel} type="reset" className="btn col-sm-12 col-md-12 btn-secondary mr-4">
              <Translate value="usernameReset.cancel"/>
            </button>
          </div>
          <div className="col-sm-12 col-md-3 col-lg-2 ml-md-4 ml-lg-5 ml-xl-3 order-0 order-md-1">
            <button type="submit" className="btn btn-primary col-sm-12 col-md-12">
              <Translate value="usernameReset.continue"/>
            </button>
          </div>
        </div>
      </form>
    </div>;
  }
}

const mapStateToProps = state => ({
  "phoneNumberError": state.phoneNumber.phoneNumberError,
  "macAddressError": state.macAddress.macAddressError
});

const mapDispatchToProps = dispatch => ({
  "updateMacAddress": value => {
    dispatch(updateMacAddressAction(value));
  },
  "updatePhoneNumber": value => {
    dispatch(updatePhoneNumberAction(value));
  },
  "submitUrt": () => dispatch(usernameRecoveryActionCreator()),
  "trackPageView": () => dispatch(trackPageView("SELFCARE_pageView_usernameRecovery")),
  "trackCancelAction": () => dispatch(trackData("SELFCARE_selectAction_cancel")),
  "trackError": errorMessage => dispatch(trackData(
      "SELFCARE_error_URT",
      {
        "appErrorType": "generic",
        "appErrorMessage": errorMessage
      }
  ))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PhoneMacForm));
