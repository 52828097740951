import axios from "axios";
import "core-js";
import queryString from "querystring";
import preEnableDisableSubUserAction from "../../actions/sub-users/pre-enable-disable-sub-user-action";
import postEnableDisableSubUserAction from "../../actions/sub-users/post-enable-disable-sub-user-action";
import createEnableDisableSubUserErrorAction from "../../actions/sub-users/enable-disable-sub-user-error-action";
import API from "../common/api-checks";
import UserCheck from "../common/api-user-checks";
import validateUser from "../../validators/is-user-valid";

// eslint-disable-next-line camelcase
export default function enableDisableSubUserActionCreator(user, statusBool) {
  /* eslint-disable max-statements */
  return (dispatch, getState) => {

    if (!validateUser(user)) {

      return false;
    }

    let status = "Disabled";
    if (statusBool) {
      status = "Enabled";
    }

    const state = getState();

    if (state.loader.loading || status === user.status || user.status === "Pending Delete") {

      return false;
    }

    let url = state.config.get("api.urls.manageSubUserStatus");
    if (!url) {
      dispatch(createEnableDisableSubUserErrorAction("application.errors.subUserURLMissingMessage"));

      return false;
    }

    url = url.replace(/{user_id}/, user.userId);

    const payloadData = {
      status
    };

    dispatch(preEnableDisableSubUserAction(statusBool));

    return axios({
      "headers": {
        ...API.getAuthorization(state),
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": queryString.stringify(payloadData),
      "method": "PATCH",
      url
    }).then(response => {
      if (API.hasData(response) && UserCheck.hasUserFields(response.data)) {
        dispatch(postEnableDisableSubUserAction(response.data));

        return true;
      }
      dispatch(createEnableDisableSubUserErrorAction("application.errors.systemErrorMessage"));

      return false;
    })
      .catch(error => {
        dispatch(createEnableDisableSubUserErrorAction(error));

        return false;
      });
  };
}
