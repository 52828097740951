import React from "react";
import PropTypes from "prop-types";
import {Translate} from "react-i18nify";
import EmailAddressField from "../email-address-field";
import {withRouter} from "react-router-dom";
import MacAddressField from "../mac-address-field";
import {connect} from "react-redux";
import accountValidationActionCreator from "../../action-creators/password-reset/account-validation-action-creator";
import Captcha from "../captcha";
import trackPageView from "../../actions/helix/track-pageview-action";
import trackData from "../../actions/helix/track-data-action";
import checkForError from "../../utilities/track-ui-error";

class EmailMacForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setFocusToError = this.setFocusToError.bind(this);
    this.emailAddressRef = React.createRef();
    this.macAddressRef = React.createRef();
    this.captchaRef = React.createRef();
  }

  static get propTypes() {
    return {
      "history": PropTypes.object,
      "errorMessage": PropTypes.string,
      "submitPrt": PropTypes.func,
      "emailAddressError": PropTypes.string,
      "macAddressError": PropTypes.string,
      "trackPageView": PropTypes.func,
      "trackCancelAction": PropTypes.func,
      "trackError": PropTypes.func
    };
  }

  handleCancel(event) {
    event.preventDefault();
    this.props.trackCancelAction();
    this.props.history.push("/login");
  }

  async handleSubmit(event) {
    event.preventDefault();

    await this.props.submitPrt();
    this.setFocusToError();

    if (this.captchaRef.current) {
      this.captchaRef.current.reset();
    }
  }

  setFocusToError() {
    if (this.props.emailAddressError && this.emailAddressRef.current) {
      this.emailAddressRef.current.focus();

      return;
    }
    if (this.props.macAddressError && this.macAddressRef.current) {
      this.macAddressRef.current.focus();
    }
  }

  componentDidMount() {
    this.props.trackPageView();
  }

  componentDidUpdate(prevProps) {
    checkForError(prevProps, this.props, "passwordReset", "emailAddressError");
    checkForError(prevProps, this.props, "usernameReset", "macAddressError");
  }

  render() {
    return <>
      <h2>
        <Translate value="passwordReset.accountValidationHeader"/>
      </h2>
      <div className="">
        <Translate value="passwordReset.accountValidationInstructions"/>
        <form onSubmit={this.handleSubmit}>
          <EmailAddressField inputRef={this.emailAddressRef}/>
          <MacAddressField inputRef={this.macAddressRef}/>
          <Captcha captchaRef={this.captchaRef} />
          <div className="row">
            <div className="col-sm-12 col-md-3 col-lg-2 order-1 order-md-0">
              <button
                onClick={this.handleCancel}
                type="reset"
                className="btn col-sm-12 col-md-12 btn-secondary mr-4">
                <Translate value="usernameReset.cancel"/>
              </button>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-2 ml-md-4 ml-lg-5 ml-xl-3 order-0 order-md-1">
              <button
                type="submit"
                className="btn btn-primary col-sm-12 col-md-12">
                <Translate value="usernameReset.continue"/>
              </button>
            </div>
          </div>
        </form>
      </div>
    </>;
  }
}

const mapStateToProps = state => ({
  "errorMessage": state.apiError.errorMessage,
  "emailAddressError": state.emailAddress.emailAddressError,
  "macAddressError": state.macAddress.macAddressError
});

const mapDispatchToProps = dispatch => ({
  "submitPrt": () => dispatch(accountValidationActionCreator()),
  "trackPageView": () => dispatch(trackPageView(
      "SELFCARE_pageView_passwordReset",
      "PRT",
      {"currPageTitle": "Confirm your identity"})),
  "trackCancelAction": () => dispatch(trackData("SELFCARE_selectAction_cancel")),
  "trackError": errorMessage => dispatch(trackData(
      "SELFCARE_error_PRT",
      {
        "appErrorType": "generic",
        "appErrorMessage": errorMessage
      }
  ))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailMacForm));
