export const DELETE_SUB_USER_ERROR = "sub-users/DELETE_SUB_USER_ERROR";

export default function deleteSubUserErrorAction(error) {
  return {
    "payload": {
      error
    },
    "type": DELETE_SUB_USER_ERROR
  };
}
