import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import getId from "react-id-generator";
import {Translate, I18n} from "react-i18nify";
import updateEmailAddressAction from "../../actions/email-address/update-email-address-action";
import blurEmailAddressAction from "../../actions/email-address/blur-email-address-action";

class EmailAddressField extends React.Component {
  static get propTypes() {
    return {
      "blurEmailAddress": PropTypes.func,
      "inputRef": PropTypes.object,
      "emailAddress": PropTypes.string,
      "emailAddressError": PropTypes.string,
      "emailAddressHelpLink": PropTypes.string,
      "updateEmailAddress": PropTypes.func
    };
  }

  render() {
    const id = getId();
    let emailAddressInputClass = "";
    let emailAddressError = "";

    const showError = this.props.emailAddressError !== "";

    if (showError) {
      emailAddressError = <Translate
        className="email-address-error d-block"
        value={`passwordReset.${this.props.emailAddressError}`}/>;
      emailAddressInputClass = "border-danger";
    }

      return <div className="form-group">
        <label className="w-100">
          <Translate value="passwordReset.email"/>
          <input
            aria-invalid={showError}
            aria-required
            aria-describedby={id}
            type="text"
            className={`email-address-input form-control focus-outline-primary col-sm-12 col-md-7 col-lg-5 col-xl-4 ${emailAddressInputClass}`}
            onChange={this.props.updateEmailAddress}
            onBlur={this.props.blurEmailAddress}
            placeholder={I18n.t("passwordReset.exampleEmailAddress")}
            ref={this.props.inputRef}
            name="emailAddress"
            value={this.props.emailAddress}/>
        </label>
        <small
          className="text-danger"
          aria-live="polite"
          id={id}>
          {emailAddressError}
        </small>
      </div>;

  }
}

const mapStateToProps = state => ({
  "emailAddress": state.emailAddress.emailAddress,
  "emailAddressError": state.emailAddress.emailAddressError
});

const mapDispatchToProps = dispatch => ({
  "updateEmailAddress": event => {
    dispatch(updateEmailAddressAction(event.target.value));
  },
  "blurEmailAddress": () => {
    dispatch(blurEmailAddressAction());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailAddressField);
