export const POST_SSO_LOGIN_ACTION = "authentication/POST_SSO_LOGIN_ACTION";

export default function postSSOLoginAction(token) {
  return {
    "payload": {
      token
    },
    "type": POST_SSO_LOGIN_ACTION
  };
}
