export default macAddress => {
  const formatted = macAddress.replace(/[^0-9a-f]/ig, "");

  if (formatted.length === 12) {
    return formatted.replace(/([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})/i,
      "$1:$2:$3:$4:$5:$6").toUpperCase();
  }

  return formatted;
};
