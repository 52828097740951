export const ENABLE_DISABLE_SUB_USER_ERROR = "sub-users/ENABLE_DISABLE_SUB_USER_ERROR";

export default function createEnableDisableSubUserErrorAction(error) {
  return {
    "payload": {
      error
    },
    "type": ENABLE_DISABLE_SUB_USER_ERROR
  };
}
