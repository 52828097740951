export const SET_ACTIVE_USER = "application/SET_ACTIVE_USER";

export default function setActiveUser(userId) {
  return {
    "payload": {
      userId
    },
    "type": SET_ACTIVE_USER
  };
}
