export const UPDATE_LOGIN_USERNAME = "login_form/UPDATE_LOGIN_USERNAME";

export default function updateLoginUsername(username) {
  return {
    "payload": {
      username
    },
    "type": UPDATE_LOGIN_USERNAME
  };
}
