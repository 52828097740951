import axios from "axios";
import "core-js";
import {get} from "lodash";
import queryString from "querystring";
import preManageSubUserAction from "../../actions/sub-users/pre-manage-sub-user-action";
import manageSubUserErrorAction from "../../actions/sub-users/manage-sub-user-error-action";
import postManageSubUserAction from "../../actions/sub-users/post-manage-sub-user-action";
import isManageSubUserNameValid from "../../validators/is-manage-sub-user-name-valid-aggregator";
import API from "../../action-creators/common/api-checks";
import UserCheck from "../../action-creators/common/api-user-checks";

// eslint-disable-next-line camelcase
export default function manageSubUserPasswordActionCreator() {
  /* eslint-disable max-statements */
  return (dispatch, getState) => {

    const state = getState();

    const formData = {
      "userId": get(state, "usernameField.username"),
      "firstName": get(state, "nameFields.firstName"),
      "lastName": get(state, "nameFields.lastName")
    };


    if (!isManageSubUserNameValid(formData)) {
      dispatch(manageSubUserErrorAction("application.errors.genericError", formData));

      return false;
    }

    let url = state.config.get("api.urls.manageSubUser");
    if (!url) {
      dispatch(manageSubUserErrorAction("application.errors.subUserURLMissingMessage", formData));

      return false;
    }

    url = url.replace(/{user_id}/, formData.userId);

    const payloadData = {
      "first_name": formData.firstName,
      "last_name": formData.lastName
    };

    dispatch(preManageSubUserAction());

    return axios({
      "headers": {
        ...API.getAuthorization(state),
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": queryString.stringify(payloadData),
      "method": "PUT",
      url
    })
    .then(response => {
      if (API.hasData(response) && UserCheck.hasUserFields(response.data)) {
        dispatch(postManageSubUserAction(response.data));

        return true;
      }
      dispatch(manageSubUserErrorAction("application.errors.systemErrorMessage", formData));

      return false;
    })
    .catch(error => {
      dispatch(manageSubUserErrorAction(error, formData));

      return false;
    });
  };
}
