import React from "react";
import PropTypes from "prop-types";
import {Translate} from "react-i18nify";
import SubAccountRowMenu from "./sub-account-row-menu";
import SubAccountRowSwitch from "./sub-account-row-switch";

export default class SubAccountRow extends React.Component {
  static get propTypes() {
    return {
      "index": PropTypes.number,
      "user": PropTypes.shape({
        "email": PropTypes.string,
        "firstName": PropTypes.string,
        "isHoh": PropTypes.bool,
        "lastName": PropTypes.string,
        "status": PropTypes.oneOf([
          "Enabled",
          "Disabled",
          "Pending Delete"
        ])
      }),
      "userRefs": PropTypes.array
    };
  }

  render() {
    const userRef = React.createRef();
    const id = this.props.user.email;
    this.props.userRefs[id] = userRef;

    let accountType = "accountManagement.PrimaryAccountLabel";
    let className = "card account-card mx-0 mb-5 mt-0";
    if (!this.props.user.isHoh) {
      accountType = "accountManagement.StandardAccountLabel";
      className += " mt-0";
    }

    return (
      <div className={className} id={this.props.user.email} ref={userRef}>
        <div className="card-body pb-0">
          <div className="row">
            <h3 className="card-title mb-4 col-9">{this.props.user.firstName} {this.props.user.lastName} </h3>
            <SubAccountRowMenu className="col-3 text-right" user={this.props.user}/>
          </div>
          <p className="card-subtitle mb-2 text-muted small font-italic">
            <Translate value={accountType}/>
          </p>
          <div className="row border-bottom px-0 pt-1 pb-3 mx-0 my-2">
            <div className="font-weight-bold col-12 col-sm-4 p-0" aria-hidden>
              <Translate value="accountManagement.emailAddress"/>
            </div>
            <div className="text-sm-right col-12 col-sm-8 p-0">
              <Translate className="sr-only" value="accountManagement.emailAddress"/>
              &nbsp;
              {this.props.user.email}
            </div>
          </div>
          <div className="row border-bottom px-0 pt-1 pb-3 mx-0 my-2">
            <div className="font-weight-bold col-12 col-sm-4 p-0" aria-hidden>
              <Translate value="accountManagement.firstNameLabel"/>
            </div>
            <div className="text-sm-right col-12 col-sm-8 p-0">
              <Translate className="sr-only" value="accountManagement.firstNameLabel"/>
              &nbsp;
              {this.props.user.firstName}
            </div>
          </div>
          <div className="row border-bottom px-0 pt-1 pb-3 mx-0 my-2">
            <div className="font-weight-bold col-12 col-sm-4 p-0" aria-hidden>
              <Translate value="accountManagement.lastNameLabel"/>
            </div>
            <div className="text-sm-right col-12 col-sm-8 p-0">
              <Translate className="sr-only" value="accountManagement.lastNameLabel"/>
              &nbsp;
              {this.props.user.lastName}
            </div>
          </div>
          <div className="row px-0 pt-1 pb-2 mx-0 my-2">
            <div className="font-weight-bold col-6 col-sm-4 p-0" aria-hidden>
              <Translate value="accountManagement.statusTitle"/>
            </div>
            <div className="text-right col-6 col-sm-8 mr-0 p-0">
              <SubAccountRowSwitch user={this.props.user} userRefs={this.props.userRefs} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
