import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Modal from "../modal";
import {Translate, I18n} from "react-i18nify";
import Captcha from "../captcha";
import UsernameField from "../username-field";
import NameFields from "../name-fields";
import PasswordFields from "../password-fields";
import hideCreateSubUserModel from "../../actions/sub-users/hide-create-sub-user-modal-action";
import createSubUserActionCreator from "../../action-creators/users/create-sub-user-action-creator";
import trackData from "../../actions/helix/track-data-action";
import checkForError from "../../utilities/track-ui-error";

class CreateSubUser extends React.Component {
  constructor(props) {
    super(props);

    this.setFocusToError = this.setFocusToError.bind(this);
    this.createSubUserSubmit = this.createSubUserSubmit.bind(this);

    this.firstNameRef = React.createRef();
    this.lastNameRef = React.createRef();
    this.usernameRef = React.createRef();
    this.passwordRef = React.createRef();
    this.passwordConfirmRef = React.createRef();
    this.captchaRef = React.createRef();
  }

  static get propTypes() {
    return {
      "contactUsLink": PropTypes.string,
      "createSubUserSubmit": PropTypes.func,
      "formErrorMessage": PropTypes.string,
      "hideForm": PropTypes.func,
      "geoIpBlocked": PropTypes.bool,
      "isCreateFormOpen": PropTypes.bool,
      "usernameError": PropTypes.string,
      "firstNameError": PropTypes.string,
      "lastNameError": PropTypes.string,
      "passwordError": PropTypes.string,
      "passwordConfirmError": PropTypes.string,
      "trackError": PropTypes.func
    };
  }

  setFocusToError() {
    if (this.props.usernameError && this.usernameRef.current) {
      this.usernameRef.current.focus();

      return;
    }
    if (this.props.firstNameError && this.firstNameRef.current) {
      this.firstNameRef.current.focus();

      return;
    }
    if (this.props.lastNameError && this.lastNameRef.current) {
      this.lastNameRef.current.focus();

      return;
    }
    if (this.props.passwordError && this.passwordRef.current) {
      this.passwordRef.current.focus();

      return;
    }
    if (this.props.passwordConfirmError && this.passwordConfirmRef.current) {
      this.passwordConfirmRef.current.focus();
    }
  }

  async createSubUserSubmit(event) {
    event.preventDefault();
    await this.props.createSubUserSubmit();
    this.setFocusToError();

    if (this.captchaRef.current) {
      this.captchaRef.current.reset();
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    checkForError(prevProps, this.props, "accountManagement", "usernameError", this.usernameRef);
    checkForError(prevProps, this.props, "accountManagement", "firstNameError", this.firstNameRef);
    checkForError(prevProps, this.props, "accountManagement", "lastNameError", this.lastNameRef);
    checkForError(prevProps, this.props, "accountManagement", "passwordError", this.passwordRef);
    checkForError(prevProps, this.props, "accountManagement", "passwordConfirmError", this.passwordConfirmRef);
  }

  render() {
    if (this.props.geoIpBlocked) {
      return <Modal
        show={this.props.isCreateFormOpen}
        size="lg"
        onHide={this.props.hideForm}>
        <div className="form-row create-sub-user-geoip-error">
          <div className="text-center col-12">
            <Translate value="application.errors.createSubUserGeoIPDenied" />
            <a href={this.props.contactUsLink}><Translate value="footer.contact" /></a>.
          </div>
        </div>
      </Modal>;
    }

    return <Modal
      show={this.props.isCreateFormOpen}
      size="lg"
      title={I18n.t("accountManagement.addPerson")}
      error={this.props.formErrorMessage}
      onHide={this.props.hideForm}>
      <form id="create-sub-user-form" onSubmit={this.createSubUserSubmit}>
        <div className="form-section">
          <UsernameField inputRef={this.usernameRef} isEditable={true} />
          <NameFields inputFirstNameRef={this.firstNameRef}
                      inputLastNameRef={this.lastNameRef}
          />
        </div>
        <div className="form-section">
          <PasswordFields inputPasswordRef={this.passwordRef}
                          inputPasswordConfirmRef={this.passwordConfirmRef}
          />
        </div>
        <div className="form-section">
          <Captcha captchaRef={this.captchaRef} />
        </div>
        <div className="form-row m-0">
          <div className="col-12 col-lg-3 order-1 order-lg-0 p-0 m-0 mr-lg-4">
            <button
              type="reset"
              className="btn btn-secondary btn-block m-0 create-sub-user-cancel-btn"
              onClick={this.props.hideForm}>
              <Translate value="accountManagement.cancel"/>
            </button>
          </div>
          <div className="col-12 col-lg-3 order-0 order-lg-1 p-0 m-0 mb-3 mb-lg-0">
            <button
              className="btn btn-primary btn-block m-0 create-sub-user-submit-btn"
              type="submit">
              <Translate value="accountManagement.add"/>
            </button>
          </div>
        </div>
      </form>
    </Modal>;
  }
}

const mapStateToProps = state => ({
  "contactUsLink": state.config.get("footer.contact"),
  "geoIpBlocked": state.user.geoIpBlocked,
  "isCreateFormOpen": state.forms.isCreateSubUserFormOpen,
  "usernameError": state.usernameField.usernameError,
  "firstNameError": state.nameFields.firstNameError,
  "lastNameError": state.nameFields.lastNameError,
  "passwordError": state.passwordFields.passwordError,
  "passwordConfirmError": state.passwordFields.passwordConfirmError,
  "formErrorMessage": state.apiError.errorMessage
});

const mapDispatchToProps = dispatch => ({
  "hideForm": () => dispatch(hideCreateSubUserModel()),
  "createSubUserSubmit": () => dispatch(createSubUserActionCreator()),
  "trackError": errorMessage => dispatch(trackData(
      "SELFCARE_error_addUserError",
      {
        "appErrorType": "generic",
        "appErrorMessage": errorMessage
      }
  ))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateSubUser);
