export const POST_ACCOUNT_VALIDATION_ACTION = "password-reset/POST_ACCOUNT_VALIDATION_ACTION";

export default function postAccountValidationAction(securityQuestion, securityQuestionList) {
  return {
    "payload": {
      securityQuestion,
      securityQuestionList
    },
    "type": POST_ACCOUNT_VALIDATION_ACTION
  };
}
