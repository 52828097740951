import produce from "immer";
import {get} from "lodash";
import {UPDATE_SECURITY_QUESTION_ANSWER_ACTION} from "../actions/password-reset/update-answer-action";
import {SECURITY_QUESTION_ANSWER_ERROR_ACTION} from "../actions/password-reset/security-question-answer-error-action";
import {UPDATE_SECURITY_QUESTION_ACTION} from "../actions/password-reset/update-question-action";
import {SECURITY_QUESTION_ERROR_ACTION} from "../actions/password-reset/security-question-error-action";

const initialState = () => ({
  "answer": "",
  "answerError": "",
  "questionError": ""
});

// eslint-disable-next-line max-lines-per-function,complexity,max-statements
export default (state = initialState(), action = {}) => produce(state, draft => {
  switch (action.type) {
    case UPDATE_SECURITY_QUESTION_ANSWER_ACTION:
      draft.answerError = "";
      draft.answer = get(action.payload, "answer");

      return draft;
    case SECURITY_QUESTION_ANSWER_ERROR_ACTION:
      draft.answerError = get(action.payload, "error");

      return draft;
    case UPDATE_SECURITY_QUESTION_ACTION:
      draft.questionError = "";

      return draft;
    case SECURITY_QUESTION_ERROR_ACTION:
      draft.questionError = get(action.payload, "error");

      return draft;
    default:
      return state;
  }
});
