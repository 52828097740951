import React from "react";
import PropTypes from "prop-types";
import FirstNameField from "./first-name-field";
import LastNameField from "./last-name-field";

class NameFields extends React.Component {
  static get propTypes() {
    return {
      "inputFirstNameRef": PropTypes.object,
      "inputLastNameRef": PropTypes.object
    };
  }

  render() {
    return (
      <>
        <FirstNameField inputRef={this.props.inputFirstNameRef} />
        <LastNameField inputRef={this.props.inputLastNameRef} />
      </>
    );
  }
}

export default NameFields;
