export const POST_VERIFY_SECURITY_QUESTION_ACTION = "password-reset/POST_VERIFY_SECURITY_QUESTION_ACTION";

export default function postVerifySecurityQuestionAction(answer) {
  return {
    "payload": {
      answer
    },
    "type": POST_VERIFY_SECURITY_QUESTION_ACTION
  };
}
