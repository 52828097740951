/* eslint-disable react/react-in-jsx-scope */
import React, {Component} from "react";
import {I18n, Translate} from "react-i18nify";
import PropTypes from "prop-types";
import "./switch.scss";

export default class Switch extends Component {
  static get propTypes() {
    return {
      "active": PropTypes.bool.isRequired,
      "addClasses": PropTypes.string,
      "ariaLabel": PropTypes.string,
      "switchCallback": PropTypes.func
    };
  }

  render() {
    const ariaLabel = this.props.ariaLabel || "toggle switch";
    let switchText = I18n.t("application.off");
    let displayClass = "kite-switch p-0";

    if (this.props.active) {
      switchText = I18n.t("application.on");
      displayClass = `${displayClass} kite-switch--on`;
    }

    if (this.props.addClasses) {
      displayClass = `${displayClass} ${this.props.addClasses}`;
    }

    return <button className={displayClass}
                   aria-label={`${ariaLabel} ${switchText}`}
                   aria-pressed={this.props.active}
                   type="button"
                   onClick={this.props.switchCallback}>
      <span className="kite-switch__inner">
        <span className="kite-switch__outer-track">
          <span className="kite-switch__track">
            <span className="kite-switch__toggle"></span>
            <span className="kite-switch__track-label kite-switch__track-label--off align-right" aria-hidden>
              <Translate value="application.off"/>
            </span>
            <span className="kite-switch__track-label kite-switch__track-label--on" aria-hidden>
              <Translate value="application.on"/>
            </span>
          </span>
        </span>
      </span>
    </button>;
  }
}
