import isCurrentPasswordValid from "./empty-field";
import isPasswordValid from "./is-password-valid-aggregator";
import isPasswordConfirmValid from "./is-password-confirm-valid-aggregator";

export default function isManageUserPasswordValid(data) {
  return isCurrentPasswordValid(data.currentPassword) &&
    isPasswordValid(data.password, data.userId) &&
    isPasswordConfirmValid(data.password, data.passwordConfirm) &&
    data.currentPassword !== data.password;
}
