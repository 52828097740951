import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import getId from "react-id-generator";
import {Translate, I18n} from "react-i18nify";
import updatePhoneNumberAction from "../../actions/phone-number/update-phone-number-action";
import blurPhoneNumberAction from "../../actions/phone-number/blur-phone-number-action";

class PhoneNumberField extends React.Component {
  static get propTypes() {
    return {
      "blurPhoneNumber": PropTypes.func,
      "inputRef": PropTypes.object,
      "phoneNumber": PropTypes.string,
      "phoneNumberError": PropTypes.string,
      "updatePhoneNumber": PropTypes.func
    };
  }

  render() {
    let phoneNumberInputClass = "";
    let phoneNumberError = "";

    const id = getId();
    const showError = this.props.phoneNumberError !== "";

    if (showError) {
      phoneNumberError = <Translate
        className="phone-number-error d-block"
        value={`usernameReset.${this.props.phoneNumberError}`}/>;
      phoneNumberInputClass = "border-danger";
    }

      return <div className="form-group">
        <label className="w-100">
          <Translate value="usernameReset.phone"/>
          <input
            aria-invalid={showError}
            aria-required
            aria-describedby={id}
            type="text"
            className={`phone-number-input form-control focus-outline-primary col-sm-12 col-md-7 col-lg-5 col-xl-4 ${phoneNumberInputClass}`}
            onChange={this.props.updatePhoneNumber}
            onBlur={this.props.blurPhoneNumber}
            placeholder={I18n.t("usernameReset.examplePhone")}
            ref={this.props.inputRef}
            name="phoneNumber"
            value={this.props.phoneNumber}/>
        </label>
        <small
          className="text-danger"
          aria-live="polite"
          id={id}>
          {phoneNumberError}
        </small>
      </div>;

  }
}

const mapStateToProps = state => ({
  "phoneNumber": state.phoneNumber.phoneNumber,
  "phoneNumberError": state.phoneNumber.phoneNumberError
});

const mapDispatchToProps = dispatch => ({
  "updatePhoneNumber": event => {
    dispatch(updatePhoneNumberAction(event.target.value));
  },
  "blurPhoneNumber": () => {
    dispatch(blurPhoneNumberAction());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumberField);
