/* eslint-disable max-lines */
import produce from "immer";
import {get} from "lodash";
import {LOAD_ANALYTICS_SERVICE} from "../actions/helix/load-analytics-service-action";
import {TRACK_DATA} from "../actions/helix/track-data-action";
import {SHOW_CREATE_SUB_USER_MODAL} from "../actions/sub-users/show-create-sub-user-modal-action";
import {SHOW_MANAGE_SUB_USER_NAME_MODAL} from "../actions/sub-users/show-manage-sub-user-name-modal-action";
import {SHOW_MANAGE_SUB_USER_PASSWORD_MODAL} from "../actions/sub-users/show-manage-sub-user-password-modal-action";
import {SHOW_MANAGE_USER_PASSWORD_MODAL} from "../actions/users/show-manage-user-password-modal-action";
import {HIDE_MANAGE_USER_PASSWORD_MODAL} from "../actions/users/hide-manage-user-password-modal-action";
import {PRE_MANAGE_SUB_USER} from "../actions/sub-users/pre-manage-sub-user-action";
import {PRE_MANAGE_USER} from "../actions/users/pre-manage-user-action";
import {PRE_DELETE_SUB_USER} from "../actions/sub-users/pre-delete-sub-user-action";
import {PRE_RESTORE_SUB_USER} from "../actions/sub-users/pre-restore-sub-user-action";
import {PRE_ENABLE_DISABLE_SUB_USER} from "../actions/sub-users/pre-enable-disable-sub-user-action";
import {PRE_MANAGE_SUB_USER_PASSWORD} from "../actions/sub-users/pre-manage-sub-user-password-action";
import {HIDE_CREATE_SUB_USER_MODAL} from "../actions/sub-users/hide-create-sub-user-modal-action";
import {HIDE_MANAGE_SUB_USER_NAME_MODAL} from "../actions/sub-users/hide-manage-sub-user-name-modal-action";
import {HIDE_MANAGE_SUB_USER_PASSWORD_MODAL} from "../actions/sub-users/hide-manage-sub-user-password-modal-action";
import {PRE_CREATE_SUB_USER_ACTION} from "../actions/sub-users/pre-create-sub-user-action";
import {PRE_SSO_LOGIN_ACTION} from "../actions/authentication/pre-sso-login-action";
import {SSO_LOGIN_ERROR_ACTION} from "../actions/authentication/sso-login-error-action";
import {LOGIN_USER_ERROR} from "../actions/login-form/login-user-error-action";
import {TRACK_PAGE_VIEW} from "../actions/helix/track-pageview-action";
import {POST_MANAGE_SUB_USER} from "../actions/sub-users/post-manage-sub-user-action";
import {POST_MANAGE_SUB_USER_PASSWORD} from "../actions/sub-users/post-manage-sub-user-password-action";
import {POST_MANAGE_USER} from "../actions/users/post-manage-user-action";
import {POST_DELETE_SUB_USER_ACTION} from "../actions/sub-users/post-delete-sub-user-action";
import {POST_RESTORE_SUB_USER_ACTION} from "../actions/sub-users/post-restore-sub-user-action";
import {POST_CREATE_SUB_USER_ACTION} from "../actions/sub-users/post-create-sub-user-action";
import {CREATE_SUB_USER_ERROR_ACTION} from "../actions/sub-users/create-sub-user-error-action";
import {MANAGE_SUB_USER_ERROR} from "../actions/sub-users/manage-sub-user-error-action";
import {MANAGE_SUB_USER_PASSWORD_ERROR} from "../actions/sub-users/manage-sub-user-password-error-action";
import {DELETE_SUB_USER_ERROR} from "../actions/sub-users/delete-sub-user-error-action";
import {RESTORE_SUB_USER_ERROR} from "../actions/sub-users/restore-sub-user-error-action";
import {ENABLE_DISABLE_SUB_USER_ERROR} from "../actions/sub-users/enable-disable-sub-user-error-action";
import {SET_REMEMBER_ME} from "../actions/login-form/remember-me-action";
import {PRE_ACCOUNT_VALIDATION_ACTION} from "../actions/password-reset/pre-account-validation-action";
import {PRE_VERIFY_SECURITY_QUESTION_ACTION} from "../actions/password-reset/pre-verify-security-question-action";
import {ACCOUNT_VALIDATION_ERROR_ACTION} from "../actions/password-reset/account-validation-error-action";
import {SECURITY_QUESTION_ANSWER_ERROR_ACTION} from "../actions/password-reset/security-question-answer-error-action";
import {VERIFY_SECURITY_QUESTION_ERROR_ACTION} from "../actions/password-reset/verify-security-question-error-action";
import {SECURITY_QUESTION_ERROR_ACTION} from "../actions/password-reset/security-question-error-action";
import {SET_SECURITY_QUESTION_ERROR_ACTION} from "../actions/password-reset/set-security-question-error-action";
import {PASSWORD_RESET_ERROR_ACTION} from "../actions/password-reset/password-reset-error-action";
import {DISMISS_LOADER_ACTION} from "../actions/loader/dismiss-loader-action";
import {POST_PASSWORD_RESET_ACTION} from "../actions/password-reset/post-password-reset-action";
import {PRE_URT_ACTION} from "../actions/username-recovery/pre-username-recovery-action";
import {URT_ERROR_ACTION} from "../actions/username-recovery/username-recovery-error-action";
import {LOAD_TRANSLATIONS} from "../components/translate/actions/load-translations-action";
import {SET_LOCALE} from "../components/translate/actions/set-locale-action";
import {PRE_PASSWORD_RESET_ACTION} from "../actions/password-reset/pre-password-reset-action";

function getActionType(action) {
    switch (action.type) {
        case SHOW_MANAGE_USER_PASSWORD_MODAL:
        case PRE_MANAGE_USER:
        case POST_MANAGE_USER:
        case HIDE_MANAGE_USER_PASSWORD_MODAL:
            return "primary";
        default:
            return "secondary";
    }
}

function getErrorType(action) {
    switch (action.payload.error) {
        case "application.errors.genericError":
        case "application.errors.nothingWrongMessage":
        case "passwordReset.error.emptyAnswer":
        case "passwordReset.error.emptyQuestion":
        case "passwordReset.error.invalidLength":
        case "passwordReset.error.invalidCharacters":
            return "generic";
        default:
            return "api";
    }
}

function getErrorMessage(action, draft) {
    if (action.payload.error && typeof action.payload.error === "string") {
        return translateMessage(draft, action.payload.error);
    } else if (action.payload.error.message) {
        return action.payload.error.message;
    } else if (action.payload.error.detail) {
        return action.payload.error.detail;
    }

    /* eslint-disable-next-line no-undefined */
    return undefined;
}

function translateMessage(draft, error) {
    const translation = get(draft.translations[draft.locale], error);

    return translation ? translation : error;
}

function getErrorStatus(action) {
    let status = get(action, "payload.error.response.status");
    if (!status) {
        status = get(action, "payload.error.status");
    }

    return status;
}

function getErrorTrackingPayload(action, draft) {
    return {
        "appErrorType": getErrorType(action),
        "appErrorMessage": getErrorMessage(action, draft),
        "appApiResponseCode": getErrorStatus(action)
    };
}

function trackModalClose(action, draft) {
    draft.analyticsService.track(
        "SELFCARE_modalClose",
        {
            "currPageElemStrValue": getActionType(action)
        });
}

function trackDataModal(action, draft) {
    switch (action.payload.id) {
        case "SELFCARE_selectAction_URTSignIn":
        case "SELFCARE_selectAction_URTPRT":
            trackModalClose(action, draft);
            break;
        default:
    }
}

export const initialState = () => ({
    "analyticsService": {},
    "rememberMe": false,
    "translations": {},
    "locale": "en_US"
});

/* eslint-disable-next-line max-lines-per-function, complexity */
export default (state = initialState(), action = {}) => produce(state, draft => {
    switch (action.type) {
        case LOAD_ANALYTICS_SERVICE:
            draft.analyticsService = action.payload;

            return draft;
        case LOAD_TRANSLATIONS:
            draft.translations = action.payload;

            return draft;
        case SET_LOCALE:
            draft.locale = state.translations[action.payload] ? action.payload : "en_US";

            return draft;
        case SET_REMEMBER_ME:
            draft.rememberMe = action.payload;

            return draft;
        case TRACK_DATA:
            draft.analyticsService.track(action.payload.id, action.payload.adHocData, action.payload.options);
            trackDataModal(action, draft);

            return draft;
        case TRACK_PAGE_VIEW:
            draft.analyticsService.trackPageView(action.payload.id, action.payload.currPageName, action.payload.adHocData);

            return draft;
        case SHOW_CREATE_SUB_USER_MODAL:
            draft.analyticsService.track("SELFCARE_selectAction_addUser");
            draft.analyticsService.track("SELFCARE_modalView_addUser");

            return draft;
        case PRE_CREATE_SUB_USER_ACTION:
            draft.analyticsService.track("SELFCARE_selectAction_addUser_add");

            return draft;
        case SHOW_MANAGE_SUB_USER_NAME_MODAL:
            draft.analyticsService.track("SELFCARE_selectAction_manageContactInfo");
            draft.analyticsService.track("SELFCARE_modalView_manageContactInfo");

            return draft;
        case SHOW_MANAGE_SUB_USER_PASSWORD_MODAL:
        case SHOW_MANAGE_USER_PASSWORD_MODAL:
            draft.analyticsService.track(
                "SELFCARE_selectAction_updatePassword",
                {
                    "currPageElemStrValue": getActionType(action)
                }
            );
            draft.analyticsService.track(
                "SELFCARE_modalView_updatePassword",
                {
                    "currPageElemStrValue": getActionType(action)
                });

            return draft;
        case HIDE_CREATE_SUB_USER_MODAL:
        case HIDE_MANAGE_SUB_USER_NAME_MODAL:
        case HIDE_MANAGE_SUB_USER_PASSWORD_MODAL:
        case HIDE_MANAGE_USER_PASSWORD_MODAL:
            trackModalClose(action, draft);

            return draft;
        case PRE_MANAGE_USER:
        case PRE_MANAGE_SUB_USER_PASSWORD:
            draft.analyticsService.track(
                "SELFCARE_selectAction_updatePassword_save",
                {
                    "currPageElemStrValue": getActionType(action)
                }
            );

            return draft;
        case PRE_MANAGE_SUB_USER:
            draft.analyticsService.track(
                "SELFCARE_selectAction_manageContactInfo_save"
            );

            return draft;
        case PRE_DELETE_SUB_USER:
            draft.analyticsService.track("SELFCARE_selectAction_deleteUser");

            return draft;
        case PRE_RESTORE_SUB_USER:
            draft.analyticsService.track("SELFCARE_selectAction_restoreUser");

            return draft;
        case PRE_ENABLE_DISABLE_SUB_USER:
            draft.analyticsService.track(
                "SELFCARE_selectAction_emailServicesToggle",
                {
                    "opToggleState": action.payload.status
                }
            );

            return draft;
        case PRE_SSO_LOGIN_ACTION:
            draft.analyticsService.track(
                "SELFCARE_selectAction_signIn",
                {
                    "currPageElemSelOptions": draft.rememberMe ? "Remember Me" : "Do not Remember Me"
                }
            );

            return draft;
        case POST_MANAGE_SUB_USER:
            draft.analyticsService.track("SELFCARE_assetDisplayed_manageContactInfoSuccess");
            trackModalClose(action, draft);

            return draft;
        case POST_MANAGE_USER:
        case POST_MANAGE_SUB_USER_PASSWORD:
            draft.analyticsService.track(
                "SELFCARE_assetDisplayed_updatePasswordSuccess",
                {
                    "currPageElemStrValue": getActionType(action)
                }
            );
            trackModalClose(action, draft);

            return draft;
        case POST_DELETE_SUB_USER_ACTION:
            draft.analyticsService.track("SELFCARE_assetDisplayed_deleteUserSuccess");

            return draft;
        case POST_RESTORE_SUB_USER_ACTION:
            draft.analyticsService.track("SELFCARE_assetDisplayed_restoreUserSuccess");

            return draft;
        case POST_CREATE_SUB_USER_ACTION:
            draft.analyticsService.track("SELFCARE_assetDisplayed_addUserSuccess");
            trackModalClose(action, draft);

            return draft;
        case MANAGE_SUB_USER_ERROR:
            draft.analyticsService.track(
                "SELFCARE_error_manageContactInfoError",
                getErrorTrackingPayload(action, draft)
            );

            return draft;
        case MANAGE_SUB_USER_PASSWORD_ERROR:
            draft.analyticsService.track(
                "SELFCARE_error_updatePasswordError",
                getErrorTrackingPayload(action, draft)
            );

            return draft;
        case DELETE_SUB_USER_ERROR:
            draft.analyticsService.track(
                "SELFCARE_error_deleteUserError",
                getErrorTrackingPayload(action, draft)
            );

            return draft;
        case RESTORE_SUB_USER_ERROR:
            draft.analyticsService.track(
                "SELFCARE_error_restoreUserError",
                getErrorTrackingPayload(action, draft)
            );

            return draft;
        case ENABLE_DISABLE_SUB_USER_ERROR:
            draft.analyticsService.track(
                "SELFCARE_error_emailToggleError",
                getErrorTrackingPayload(action, draft)
            );

            return draft;
        case CREATE_SUB_USER_ERROR_ACTION:
            draft.analyticsService.track(
                "SELFCARE_error_addUserError",
                getErrorTrackingPayload(action, draft)
            );

            return draft;
        case LOGIN_USER_ERROR:
        case SSO_LOGIN_ERROR_ACTION:
            draft.analyticsService.track(
                "SELFCARE_error_login",
                getErrorTrackingPayload(action, draft)
            );

            return draft;
        case ACCOUNT_VALIDATION_ERROR_ACTION:
        case SECURITY_QUESTION_ANSWER_ERROR_ACTION:
        case VERIFY_SECURITY_QUESTION_ERROR_ACTION:
        case SECURITY_QUESTION_ERROR_ACTION:
        case SET_SECURITY_QUESTION_ERROR_ACTION:
        case PASSWORD_RESET_ERROR_ACTION:
            draft.analyticsService.track(
                "SELFCARE_error_PRT",
                getErrorTrackingPayload(action, draft)
            );

            return draft;
        case PRE_ACCOUNT_VALIDATION_ACTION:
        case PRE_VERIFY_SECURITY_QUESTION_ACTION:
        case PRE_PASSWORD_RESET_ACTION:
            draft.analyticsService.track("SELFCARE_selectAction_PRTContinue");

            return draft;
        case POST_PASSWORD_RESET_ACTION:
            draft.analyticsService.track("SELFCARE_assetDisplayed_PRTSuccess");

            return draft;
        case DISMISS_LOADER_ACTION:
            draft.analyticsService.track("SELFCARE_selectAction_PRTSignIn");

            return draft;
        case PRE_URT_ACTION:
            draft.analyticsService.track("SELFCARE_selectAction_URTContinue");

            return draft;
        case URT_ERROR_ACTION:
            draft.analyticsService.track(
                "SELFCARE_error_URT",
                getErrorTrackingPayload(action, draft));

            return draft;
        default:
            return state;
    }
});
