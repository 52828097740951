import "core-js";
import tokenRefreshActionCreator from "./token-refresh-action-creator";
import tokenRefreshInitializedAction from "../../actions/authentication/token-refresh-initialized-action";
import signOut from "@internet-features/header/src/actions/sign-out-action";
import {get} from "lodash";

export default function setupTokenRefreshActionCreator() {
  return (dispatch, getState) => {
    const state = getState();
    if (get(state, "token.refreshHandle")) {
      clearTimeout(get(state, "token.refreshHandle"));
    }
    if (get(state, "token.refreshToken")) {
      const now = new Date().getTime();
      let expiresIn = get(state, "token.expiresIn") - now;
      if (expiresIn < 0) {
        expiresIn = state.config.get("api.oauth.retry.retryDelay") * 1000;
      }
      const refreshHandle = setTimeout(() => dispatch(tokenRefreshActionCreator()), expiresIn);
      dispatch(tokenRefreshInitializedAction(refreshHandle));

      return true;
    }
    dispatch(signOut());

    return false;
  };
}
