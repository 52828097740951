export const LOGIN_USER_ERROR = "login_form/LOGIN_USER_ERROR";

export default function loginUserErrorAction(error = "", data) {
  return {
    "payload": {
      error,
      data
    },
    "type": LOGIN_USER_ERROR
  };
}
