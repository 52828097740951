import React from "react";
import SecurityQuestionForm from "./security-question-form";
import Loader from "../loader";
import EmailMacForm from "./email-mac-form";
import NewPasswordForm from "./new-password-form";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import FormError from "../error/form";

class PasswordReset extends React.Component {

  static get propTypes() {
    return {
      "loading": PropTypes.bool,
      "emailAddress": PropTypes.string,
      "errorMessage": PropTypes.string,
      "macAddress": PropTypes.string,
      "securityQuestionList": PropTypes.array,
      "securityQuestion": PropTypes.string,
      "securityAnswer": PropTypes.string
    };
  }

  render() {
    let error = "";

    if (this.props.errorMessage !== "") {
      error = <FormError error={this.props.errorMessage}/>;
    }

    let content = <EmailMacForm/>;

    if (this.props.securityQuestionList.length > 0) {
      content = <SecurityQuestionForm />;
    }

    if (this.props.securityQuestionList.length > 0 && this.props.securityQuestion && this.props.securityAnswer) {
      content = <NewPasswordForm />;
    }

    return (
      <div className="container mb-4">
        <div className="card primary-card col-sm-12 col-md-10 offset-sm-0 offset-md-1">
          <div className="card-body">
          {error}
          <Loader show={this.props.loading} overlay={true}/>
          {content}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  "loading": state.loader.loading,
  "errorMessage": state.apiError.errorMessage,
  "securityQuestion": state.passwordReset.securityQuestion,
  "securityQuestionList": state.passwordReset.securityQuestionList,
  "securityAnswer": state.passwordReset.securityAnswer
});

export default connect(mapStateToProps, null)(PasswordReset);
