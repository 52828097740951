export const ACCOUNT_VALIDATION_ERROR_ACTION = "password-reset/ACCOUNT_VALIDATION_ERROR";

export default function accountValidationErrorAction(error) {
  return {
    "payload": {
      error
    },
    "type": ACCOUNT_VALIDATION_ERROR_ACTION
  };
}
