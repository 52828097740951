import axios from "axios";
import "core-js";
import preDeleteSubUserAction from "../../actions/sub-users/pre-delete-sub-user-action";
import deleteSubUserErrorAction from "../../actions/sub-users/delete-sub-user-error-action";
import postDeleteSubUserAction from "../../actions/sub-users/post-delete-sub-user-action";
import API from "../common/api-checks";
import validateUser from "../../validators/is-user-valid";

export default function deleteSubUserActionCreator(user) {
  /* eslint-disable max-statements */
  return (dispatch, getState) => {
    const state = getState();

    if (!validateUser(user)) {
      dispatch(preDeleteSubUserAction("accountManagement.subUserError.invalidUserMessage"));

      return false;
    }

    if (user.status === "Pending Delete") {

      return false;
    }

    let url = state.config.get("api.urls.deleteSubUser");

    if (!url) {
      dispatch(deleteSubUserErrorAction("application.errors.subUserURLMissingMessage"));

      return false;
    }

    url = url.replace(/{user_id}/, user.userId);

    dispatch(preDeleteSubUserAction());

    return axios({
      "headers": {
        ...API.getAuthorization(state),
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "method": "DELETE",
      url
    })
      .then(response => {
        // eslint-disable-next-line no-extra-parens
        if (API.hasData(response)) {
          dispatch(postDeleteSubUserAction(response.data, user));

          return true;
        }
        dispatch(deleteSubUserErrorAction("application.errors.systemErrorMessage"));

        return false;
      })
      .catch(error => {
        dispatch(deleteSubUserErrorAction(error));

        return false;
      });
  };
}
