import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Recaptcha from "react-recaptcha";
import verifyCaptcha from "../../actions/application/captcha/verify-captcha-action";
import expireCaptcha from "../../actions/application/captcha/expire-captcha-action";
import {Translate} from "react-i18nify";

class Captcha extends React.Component {
  static get propTypes() {
    return {
      "captchaExpireCallback": PropTypes.func,
      "captchaError": PropTypes.string,
      "captchaRef": PropTypes.object,
      "captchaToken": PropTypes.string,
      "recaptchaSiteKey": PropTypes.string,
      "verifyCaptchaCallback": PropTypes.func
    };
  }

  render() {
    let captchaError = "";
    const showError = this.props.captchaError !== "";

    if (showError) {
      captchaError = <Translate
        className="captcha-error d-block"
        value={this.props.captchaError}/>;
    }

    return (
      <div className={"captcha form-group"}>
        <Recaptcha
          ref={this.props.captchaRef}
          sitekey={this.props.recaptchaSiteKey}
          render="explicit"
          onloadCallback={() => ({})}
          expiredCallback={this.props.captchaExpireCallback}
          verifyCallback={token => this.props.verifyCaptchaCallback(token)}/>
        <small
          className="text-danger"
          aria-live="polite"
          aria-hidden={!showError}>
          {captchaError}
        </small>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  "captchaToken": state.captcha.captchaToken,
  "recaptchaSiteKey": state.config.get("recaptchaSiteKey"),
  "captchaError": state.captcha.captchaError
});

const mapDispatchToProps = dispatch => ({
  "captchaExpireCallback": () => {
    dispatch(expireCaptcha());
  },
  "verifyCaptchaCallback": token => {
    dispatch(verifyCaptcha(token));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Captcha);
