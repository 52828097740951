import produce from "immer";
import isUserValid from "../validators/is-user-valid";
import {get} from "lodash";
import {SIGN_OUT} from "@internet-features/header/src/actions/sign-out-action";
import {POST_GET_SUB_USER_ACTION} from "../actions/sub-users/post-get-sub-user-action";
import {GET_SUB_USER_ACTION_ERROR} from "../actions/sub-users/get-sub-user-action-error-action";
import {POST_CREATE_SUB_USER_ACTION} from "../actions/sub-users/post-create-sub-user-action";
import {POST_MANAGE_SUB_USER} from "../actions/sub-users/post-manage-sub-user-action";
import {POST_ENABLE_DISABLE_SUB_USER} from "../actions/sub-users/post-enable-disable-sub-user-action";
import {POST_RESTORE_SUB_USER_ACTION} from "../actions/sub-users/post-restore-sub-user-action";
import {POST_DELETE_SUB_USER_ACTION} from "../actions/sub-users/post-delete-sub-user-action";
import {POST_MANAGE_SUB_USER_PASSWORD} from "../actions/sub-users/post-manage-sub-user-password-action";

function initialState() {
  const initValue = {
    "users": []
  };

  if (!localStorage.getItem("sub-users")) {
    return initValue;
  }

  const subUsers = JSON.parse(localStorage.getItem("sub-users"));
  const users = get(subUsers, "users", []);

  if (users.length < 1) {
    return initValue;
  }

  for (let key = users.length - 1; key >= 0; key -= 1) {
    if (!isUserValid(users[key])) {
      return initValue;
    }
  }

  return subUsers;
}

const reset = draft => {

  draft.users = [];

  if (localStorage.getItem("sub-users")) {
    localStorage.removeItem("sub-users");
  }

  return draft;
};

// eslint-disable-next-line max-lines-per-function
export default (state = initialState(), action = {}) => produce(state, draft => {
  switch (action.type) {
    case POST_DELETE_SUB_USER_ACTION:
      if (!get(action, "payload.data.success")) {

        return state;
      }
      // eslint-disable-next-line no-case-declarations
      const deletedUser = {
        ...get(action, "payload.user"),
        "status": "Pending Delete"
      };
      if (!isUserValid(deletedUser)) {

        return state;
      }
      // eslint-disable-next-line no-case-declarations
      const deletedUserIndex = draft.users.findIndex(element => element.email === deletedUser.email);
      if (deletedUserIndex < 0) {

        return state;
      }
      draft.users.splice(deletedUserIndex, 1, deletedUser);
      localStorage.setItem("sub-users", JSON.stringify(draft));

      return draft;
    case POST_ENABLE_DISABLE_SUB_USER:
    case POST_RESTORE_SUB_USER_ACTION:
    case POST_MANAGE_SUB_USER:
    case POST_MANAGE_SUB_USER_PASSWORD:
    case POST_CREATE_SUB_USER_ACTION:
      // eslint-disable-next-line no-case-declarations
      const user = {
        "userId": get(action, "payload.user.user_id"),
        "email": get(action, "payload.user.email"),
        "firstName": get(action, "payload.user.first_name"),
        "lastName": get(action, "payload.user.last_name"),
        "isHoh": get(action, "payload.user.is_hoh"),
        "status": get(action, "payload.user.status")
      };
      if (!isUserValid(user)) {

        return state;
      }
      // eslint-disable-next-line no-case-declarations
      let userIndex = draft.users.findIndex(element => element.email === user.email);
      // eslint-disable-next-line no-case-declarations
      let userCount = 1;
      if (userIndex < 0) {
        userIndex = 0;
        userCount = 0;
      }
      draft.users.splice(userIndex, userCount, user);
      localStorage.setItem("sub-users", JSON.stringify(draft));

      return draft;
    case POST_GET_SUB_USER_ACTION:
      // eslint-disable-next-line no-shadow,no-case-declarations
      const users = get(action, "payload.users").map(user => ({
        "userId": get(user, "user_id"),
        "email": get(user, "email"),
        "firstName": get(user, "first_name"),
        "lastName": get(user, "last_name"),
        "isHoh": get(user, "is_hoh"),
        "status": get(user, "status")
      }));

      if (!users || users.length < 1) {
        return state;
      }
      for (let key = users.length - 1; key >= 0; key -= 1) {
        if (!isUserValid(users[key])) {
          return reset(draft);
        }
        draft.users = users;
      }

      localStorage.setItem("sub-users", JSON.stringify(draft));

      return draft;
    case GET_SUB_USER_ACTION_ERROR:
    case SIGN_OUT:

      return reset(draft);
    default:

      return state;
  }
});
