import {every, has, partial} from "lodash";

export const tokenKeys = [
  "token_type",
  "expires_in",
  "access_token",
  "refresh_token"
];

export default {
  "hasTokenFields": data => {
    if (!data || !every(tokenKeys, partial(has, data))) {

      return false;
    }

    return data;
  }
};
