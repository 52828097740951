import produce from "immer";
import {get} from "lodash";
import {isPasswordValidAggregation} from "../validators/is-password-valid-aggregator";
import {isPasswordConfirmValidAggregation} from "../validators/is-password-confirm-valid-aggregator";
import {UPDATE_PASSWORD} from "../actions/sub-users/update-password-action";
import {VALIDATE_PASSWORD} from "../actions/sub-users/validate-password-action";
import {TOGGLE_PASSWORD_SHOW_HIDE} from "../actions/sub-users/toggle-password-show-hide-action";
import {FOCUS_PASSWORD} from "../actions/sub-users/focus-password-action";
import {BLUR_PASSWORD} from "../actions/sub-users/blur-password-action";
import {UPDATE_PASSWORD_CONFIRM} from "../actions/sub-users/update-password-confirm-action";
import {VALIDATE_PASSWORD_CONFIRM} from "../actions/sub-users/validate-password-confirm-action";
import {TOGGLE_PASSWORD_CONFIRM_SHOW_HIDE} from "../actions/sub-users/toggle-password-confirm-show-hide-action";
import {HIDE_MANAGE_SUB_USER_PASSWORD_MODAL} from "../actions/sub-users/hide-manage-sub-user-password-modal-action";
import {SHOW_CREATE_SUB_USER_MODAL} from "../actions/sub-users/show-create-sub-user-modal-action";
import {SHOW_MANAGE_SUB_USER_PASSWORD_MODAL} from "../actions/sub-users/show-manage-sub-user-password-modal-action";
import {HIDE_CREATE_SUB_USER_MODAL} from "../actions/sub-users/hide-create-sub-user-modal-action";
import {CREATE_SUB_USER_ERROR_ACTION} from "../actions/sub-users/create-sub-user-error-action";
import {POST_CREATE_SUB_USER_ACTION} from "../actions/sub-users/post-create-sub-user-action";
import {POST_MANAGE_SUB_USER} from "../actions/sub-users/post-manage-sub-user-action";
import {SHOW_MANAGE_USER_PASSWORD_MODAL} from "../actions/users/show-manage-user-password-modal-action";
import {HIDE_MANAGE_USER_PASSWORD_MODAL} from "../actions/users/hide-manage-user-password-modal-action";
import {POST_MANAGE_USER} from "../actions/users/post-manage-user-action";
import {MANAGE_USER_ERROR} from "../actions/users/manage-user-error-action";
import {PASSWORD_RESET_ERROR_ACTION} from "../actions/password-reset/password-reset-error-action";
import {POST_MANAGE_SUB_USER_PASSWORD} from "../actions/sub-users/post-manage-sub-user-password-action";
import {MANAGE_SUB_USER_PASSWORD_ERROR} from "../actions/sub-users/manage-sub-user-password-error-action";

const initialState = () => ({
  "password": "",
  "isPasswordCharacters": false,
  "isPasswordLengthValid": false,
  "isPasswordNoRepeatChars": true,
  "isPasswordPhrase": true,
  "isPasswordEmpty": true,
  "isPasswordUnique": true,
  "isUserIdNotInPassword": true,
  "passwordError": "",
  "showPasswordChars": false,
  "focusPassword": false,
  "passwordConfirm": "",
  "passwordConfirmError": "",
  "showPasswordConfirmChars": false,
  "required": false
});

// eslint-disable-next-line max-statements,max-lines-per-function,complexity
export default (state = initialState(), action = {}) => produce(state, draft => {
  const currentPassword = get(action, "payload.data.currentPassword");

  switch (action.type) {
    case UPDATE_PASSWORD:
      draft.password = get(action, "payload.password");
      updatePasswordFieldsDraft(action, draft);
      draft.passwordError = "";
      draft.passwordConfirmError = "";

      return draft;
    case VALIDATE_PASSWORD:
      updatePasswordFieldsDraft(action, draft);
      draft.passwordError = "";
      if (!draft.passwordConfirm.length) {
        draft.passwordConfirmError = "";
      }

      return draft;
    case TOGGLE_PASSWORD_SHOW_HIDE:
      draft.showPasswordChars = !state.showPasswordChars;

      return draft;
    case FOCUS_PASSWORD:
      draft.focusPassword = true;

      return draft;
    case BLUR_PASSWORD:
      draft.focusPassword = false;
      updatePasswordFieldsDraft(action, draft);
      if (!draft.passwordConfirm.length) {
        draft.passwordConfirmError = "";
      }

      return draft;
    case UPDATE_PASSWORD_CONFIRM:
      draft.passwordConfirm = get(action, "payload.passwordConfirm");

      return draft;
    case VALIDATE_PASSWORD_CONFIRM:
      updatePasswordFieldsDraft(action, draft);

      return draft;
    case TOGGLE_PASSWORD_CONFIRM_SHOW_HIDE:
      draft.showPasswordConfirmChars = !state.showPasswordConfirmChars;

      return draft;
    case SHOW_MANAGE_SUB_USER_PASSWORD_MODAL:
    case SHOW_MANAGE_USER_PASSWORD_MODAL:
    case HIDE_CREATE_SUB_USER_MODAL:
    case HIDE_MANAGE_SUB_USER_PASSWORD_MODAL:
    case HIDE_MANAGE_USER_PASSWORD_MODAL:
    case POST_CREATE_SUB_USER_ACTION:
    case POST_MANAGE_SUB_USER:
    case POST_MANAGE_SUB_USER_PASSWORD:
    case POST_MANAGE_USER:

      return initialState();
    case MANAGE_SUB_USER_PASSWORD_ERROR:
    case CREATE_SUB_USER_ERROR_ACTION:
    case PASSWORD_RESET_ERROR_ACTION:

      return updatePasswordFieldsDraft(action, draft);
    case MANAGE_USER_ERROR:
      if (currentPassword && currentPassword === draft.password) {
        draft.passwordError = "passwordNotUnique";
        draft.isPasswordUnique = false;

        return draft;
      }

      return updatePasswordFieldsDraft(action, draft);
    case SHOW_CREATE_SUB_USER_MODAL:
      // eslint-disable-next-line no-param-reassign
      draft = initialState();
      draft.required = true;

      return draft;
    default:
      return state;
  }
});

// eslint-disable-next-line max-statements
export function updatePasswordFieldsDraft(action, draft) {
  const passwordAggregate = isPasswordValidAggregation(draft.password, get(action, "payload.data.userId"));
  const passwordConfirmAggregate = isPasswordConfirmValidAggregation(draft.password, draft.passwordConfirm);
  draft.isPasswordEmpty = !passwordAggregate.isNotEmpty;
  draft.isPasswordLengthValid = passwordAggregate.isLengthValid;
  draft.isPasswordNoRepeatChars = passwordAggregate.hasNoRepeatChars;
  draft.isPasswordCharacters = passwordAggregate.hasNeededChar;
  draft.isPasswordPhrase = passwordAggregate.isPhraseChecked;
  draft.isUserIdNotInPassword = passwordAggregate.isUserIdNotInPassword;
  draft.passwordError = "";
  draft.passwordConfirmError = "";

  if (!passwordConfirmAggregate.isValid) {
    draft.passwordConfirmError = "passwordConfirmInvalidMessage";
  }

  if (!passwordConfirmAggregate.isValid && !passwordConfirmAggregate.isNotEmpty) {
    draft.passwordConfirmError = "passwordConfirmEmptyMessage";
  }

  if (!passwordAggregate.isValid) {
    draft.passwordError = "passwordInvalidMessage";
  }

  if (!passwordAggregate.isValid && !draft.isUserIdNotInPassword) {
    draft.passwordError = "passwordContainsUsernameInvalidMessage";
  }

  if (!passwordAggregate.isValid && draft.isPasswordEmpty) {
    draft.passwordError = "passwordEmptyMessage";
  }

  return draft;
}
