import "core-js";
import applicationStartAction from "../actions/application/application-start-action";
import applicationLoadedAction from "../actions/application/application-loaded-action";
import getUserActionCreator from "./users/get-user-action-creator";
import getSubUsersActionCreator from "./users/get-sub-users-action-creator";
import setupTokenRefreshActionCreator from "./authentication/setup-token-refresh-action-creator";

export default function applicationStartActionCreator() {
  return dispatch => Promise.all([
      dispatch(applicationStartAction()),
      dispatch(getUserActionCreator()),
      dispatch(getSubUsersActionCreator())
    ]).then(() => {
      dispatch(applicationLoadedAction());
      dispatch(setupTokenRefreshActionCreator());
    });
}
