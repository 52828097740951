import {I18n} from "react-i18nify";
import loadTranslations from "./load-translations-action";
import setLocale from "./set-locale-action";
import translations from "../translations";
import {has} from "lodash";

export default function initialize(store) {
  I18n.setTranslationsGetter(() => store.getState().translate.translations || {});
  I18n.setLocaleGetter(() => store.getState().translate.locale || "");
  I18n.setHandleMissingTranslation(handleMissingTranslation);

  store.dispatch(loadTranslations(translations));

  const defaultLanguage = navigator.language.replace("-", "_");

  store.dispatch(setLocale(defaultLanguage));
}

export function handleMissingTranslation(key, replacements) {
  if (has(replacements, "default")) {
    return I18n.t(replacements.default);
  }

  return key.split(".").pop();
}
