import axios from "axios";
import "core-js";
import queryString from "querystring";
import preAccountValidationAction from "../../actions/password-reset/pre-account-validation-action";
import stripHex from "../../formatters/strip-hexadecimals";
import accountValidationErrorAction from "../../actions/password-reset/account-validation-error-action";
import postAccountValidationAction from "../../actions/password-reset/post-account-validation-action";
import {get} from "lodash";
import isAccountValidationValid from "../../validators/is-account-validation-valid-aggregator";

export default function accountValidationActionCreator() {
  /* eslint-disable max-statements */
  return (dispatch, getState) => {
    const state = getState();

    const formData = {
      "emailAddress": get(state, "emailAddress.emailAddress"),
      "macAddress": get(state, "macAddress.macAddress"),
      "recaptcha": get(state, "captcha.captchaToken")
    };

    const url = getState().config.get("api.urls.passwordReset");

    if (!url) {
      dispatch(accountValidationErrorAction("PRT validation URL Missing"));

      return false;
    }

    if (!isAccountValidationValid(formData.emailAddress, formData.macAddress, formData.recaptcha)) {
      dispatch(accountValidationErrorAction("application.errors.genericError"));

      return false;
    }

    const payload = {
      "email": formData.emailAddress,
      "device_id": stripHex(formData.macAddress),
      "recaptcha": formData.recaptcha
    };

    dispatch(preAccountValidationAction());

    return axios({
        "headers": {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        "data": queryString.stringify(payload),
        "method": "POST",
        url
      }
    )
      .then(response => {
        const securityQuestion = get(response, "data._embedded.selected_question.question", false);

        const securityQuestionList = get(response, "data._embedded.questions._embedded.questions", [])
          .map(questionEntity => questionEntity.question);

        if (!(securityQuestionList.length > 0)) {
          dispatch(accountValidationErrorAction("application.errors.default"));

          return;
        }
        dispatch(postAccountValidationAction(securityQuestion, securityQuestionList));
      })
      .catch(error => {
        dispatch(accountValidationErrorAction(error.response ? error.response.data : error.message));
      });
  };
}
