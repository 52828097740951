import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Translate} from "react-i18nify";
import getId from "react-id-generator";
import updateFirstNameAction from "../../actions/sub-users/update-first-name-action";
import validateFirstNameAction from "../../actions/sub-users/validate-first-name-action";

class FirstNameField extends React.Component {
  static get propTypes() {
    return {
      "firstName": PropTypes.string,
      "firstNameError": PropTypes.string,
      "inputRef": PropTypes.object,
      "updateFirstName": PropTypes.func,
      "validateFirstName": PropTypes.func
    };
  }

  render() {
    const id = getId();
    const showError = this.props.firstNameError !== "";
    let firstNameInputClass = "";
    let firstNameError = "";

    if (showError) {
      firstNameError = <Translate
        className="first-name-error d-block"
        value={`accountManagement.${this.props.firstNameError}`}/>;
      firstNameInputClass = "border-danger";
    }

    return (
      <div className="form-group">
        <label>
          <Translate value="accountManagement.firstNameLabel"/>
          <input
            aria-invalid={showError}
            aria-required
            aria-describedby={id}
            type="text"
            className={`first-name-input form-control ${firstNameInputClass}`}
            onChange={this.props.updateFirstName}
            onBlur={this.props.validateFirstName}
            ref={this.props.inputRef}
            name="first-name"
            value={this.props.firstName}
          />
        </label>
        <small
          className="text-danger"
          aria-live="polite"
          id={id}>
          {firstNameError}
        </small>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  "firstName": state.nameFields.firstName,
  "firstNameError": state.nameFields.firstNameError
});

const mapDispatchToProps = dispatch => ({
  "updateFirstName": event => {
    dispatch(updateFirstNameAction(event.target.value));
  },
  "validateFirstName": () => {
    dispatch(validateFirstNameAction());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FirstNameField);
