export const POST_SSO_REFRESH = "authentication/POST_SSO_REFRESH";

export default function postSSORefreshAction(token) {
  return {
    "payload": {
      token
    },
    "type": POST_SSO_REFRESH
  };
}
