import produce from "immer";
import {get} from "lodash";
import isMacAddressValid from "../validators/mac-address-is-valid";
import formatMacAddress from "../formatters/mac-address-formatter";
import {START_URT_ACTION} from "../actions/username-recovery/start-username-recovery-action";
import {BLUR_MAC_ADDRESS_ACTION} from "../actions/mac-address/blur-mac-address-action";
import {UPDATE_MAC_ADDRESS_ACTION} from "../actions/mac-address/update-mac-address-action";
import {URT_ERROR_ACTION} from "../actions/username-recovery/username-recovery-error-action";
import {ACCOUNT_VALIDATION_ERROR_ACTION} from "../actions/password-reset/account-validation-error-action";


export const initialState = () => ({
  "macAddress": "",
  "macAddressError": ""
});

export default (state = initialState(), action = {}) => produce(state, draft => {
  switch (action.type) {
    case URT_ERROR_ACTION:
    case ACCOUNT_VALIDATION_ERROR_ACTION:
    case BLUR_MAC_ADDRESS_ACTION:
      if (!isMacAddressValid(draft.macAddress)) {
        draft.macAddressError = "error.invalidMacAddress";

        return draft;
      }
      draft.macAddressError = "";

      return draft;
    case UPDATE_MAC_ADDRESS_ACTION:
      if (isMacAddressValid(get(action, "payload.macAddress"))) {
        draft.macAddressError = "";
      }

      draft.macAddress = formatMacAddress(get(action, "payload.macAddress"));

      return draft;
    case START_URT_ACTION:
      return initialState();
    default:
      return state;
  }
});
