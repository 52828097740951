import {every, has, partial} from "lodash";

const keys = [
  "first_name",
  "last_name",
  "is_hoh",
  "email",
  "status"
];

const keys2 = [
  "firstName",
  "lastName",
  "isHoh",
  "email",
  "status"
];

export default user => every(keys, partial(has, user)) || every(keys2, partial(has, user));
