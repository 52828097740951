export const VALIDATE_PASSWORD_CONFIRM = "sub-users/VALIDATE_PASSWORD_CONFIRM";

export default function validatePasswordConfirmAction(suppressError = false) {
  return {
    "payload": {
      suppressError
    },
    "type": VALIDATE_PASSWORD_CONFIRM
  };
}
