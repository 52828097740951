import produce from "immer";
import {get} from "lodash";
import isEmailValid from "../validators/email";
import {BLUR_EMAIL_ADDRESS_ACTION} from "../actions/email-address/blur-email-address-action";
import {UPDATE_EMAIL_ADDRESS_ACTION} from "../actions/email-address/update-email-address-action";
import {ACCOUNT_VALIDATION_ERROR_ACTION} from "../actions/password-reset/account-validation-error-action";


export const initialState = () => ({
  "emailAddress": "",
  "emailAddressError": ""
});

export default (state = initialState(), action = {}) => produce(state, draft => {
  switch (action.type) {
    case ACCOUNT_VALIDATION_ERROR_ACTION:
    case BLUR_EMAIL_ADDRESS_ACTION:
      if (!isEmailValid(draft.emailAddress)) {
        draft.emailAddressError = "error.invalidEmail";

        return draft;
      }
      draft.emailAddressError = "";

      return draft;
    case UPDATE_EMAIL_ADDRESS_ACTION:
      draft.emailAddress = get(action, "payload.emailAddress");

      return draft;
    default:
      return state;
  }
});
