export const POST_SET_SECURITY_QUESTION_ACTION = "password-reset/POST_SET_SECURITY_QUESTION_ACTION";

export default function postSetSecurityQuestionAction(question, answer) {
  return {
    "payload": {
      question,
      answer
    },
    "type": POST_SET_SECURITY_QUESTION_ACTION
  };
}
