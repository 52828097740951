import produce from "immer";
import {APPLICATION_START_ACTION} from "../actions/application/application-start-action";
import {APPLICATION_LOADED_ACTION} from "../actions/application/application-loaded-action";
import {POST_SSO_LOGIN_ACTION} from "../actions/authentication/post-sso-login-action";
import {PRE_SSO_LOGIN_ACTION} from "../actions/authentication/pre-sso-login-action";
import {SSO_LOGIN_ERROR_ACTION} from "../actions/authentication/sso-login-error-action";
import {APPLICATION_ERROR_ACTION} from "../actions/application/error/application-error-action";
import {PRE_URT_ACTION} from "../actions/username-recovery/pre-username-recovery-action";
import {URT_ERROR_ACTION} from "../actions/username-recovery/username-recovery-error-action";
import {PRE_CREATE_SUB_USER_ACTION} from "../actions/sub-users/pre-create-sub-user-action";
import {POST_CREATE_SUB_USER_ACTION} from "../actions/sub-users/post-create-sub-user-action";
import {CREATE_SUB_USER_ERROR_ACTION} from "../actions/sub-users/create-sub-user-error-action";
import {PRE_MANAGE_SUB_USER} from "../actions/sub-users/pre-manage-sub-user-action";
import {MANAGE_SUB_USER_ERROR} from "../actions/sub-users/manage-sub-user-error-action";
import {POST_MANAGE_SUB_USER} from "../actions/sub-users/post-manage-sub-user-action";
import {POST_ENABLE_DISABLE_SUB_USER} from "../actions/sub-users/post-enable-disable-sub-user-action";
import {PRE_ENABLE_DISABLE_SUB_USER} from "../actions/sub-users/pre-enable-disable-sub-user-action";
import {ENABLE_DISABLE_SUB_USER_ERROR} from "../actions/sub-users/enable-disable-sub-user-error-action";
import {PRE_RESTORE_SUB_USER} from "../actions/sub-users/pre-restore-sub-user-action";
import {PRE_DELETE_SUB_USER} from "../actions/sub-users/pre-delete-sub-user-action";
import {POST_DELETE_SUB_USER_ACTION} from "../actions/sub-users/post-delete-sub-user-action";
import {POST_RESTORE_SUB_USER_ACTION} from "../actions/sub-users/post-restore-sub-user-action";
import {DELETE_SUB_USER_ERROR} from "../actions/sub-users/delete-sub-user-error-action";
import {RESTORE_SUB_USER_ERROR} from "../actions/sub-users/restore-sub-user-error-action";
import {POST_URT_ACTION} from "../actions/username-recovery/post-username-recovery-action";
import {POST_MANAGE_USER} from "../actions/users/post-manage-user-action";
import {PRE_MANAGE_USER} from "../actions/users/pre-manage-user-action";
import {MANAGE_USER_ERROR} from "../actions/users/manage-user-error-action";
import {PRE_PASSWORD_RESET_ACTION} from "../actions/password-reset/pre-password-reset-action";
import {POST_PASSWORD_RESET_ACTION} from "../actions/password-reset/post-password-reset-action";
import {PASSWORD_RESET_ERROR_ACTION} from "../actions/password-reset/password-reset-error-action";
import {PRE_ACCOUNT_VALIDATION_ACTION} from "../actions/password-reset/pre-account-validation-action";
import {POST_ACCOUNT_VALIDATION_ACTION} from "../actions/password-reset/post-account-validation-action";
import {ACCOUNT_VALIDATION_ERROR_ACTION} from "../actions/password-reset/account-validation-error-action";
import {PRE_SET_SECURITY_QUESTION_ACTION} from "../actions/password-reset/pre-set-security-question-action";
import {POST_SET_SECURITY_QUESTION_ACTION} from "../actions/password-reset/post-set-security-question-action";
import {SET_SECURITY_QUESTION_ERROR_ACTION} from "../actions/password-reset/set-security-question-error-action";
import {PRE_VERIFY_SECURITY_QUESTION_ACTION} from "../actions/password-reset/pre-verify-security-question-action";
import {POST_VERIFY_SECURITY_QUESTION_ACTION} from "../actions/password-reset/post-verify-security-question-action";
import {VERIFY_SECURITY_QUESTION_ERROR_ACTION} from "../actions/password-reset/verify-security-question-error-action";
import dismissLoaderAction, {DISMISS_LOADER_ACTION} from "../actions/loader/dismiss-loader-action";
import {POST_MANAGE_SUB_USER_PASSWORD} from "../actions/sub-users/post-manage-sub-user-password-action";
import {MANAGE_SUB_USER_PASSWORD_ERROR} from "../actions/sub-users/manage-sub-user-password-error-action";
import {PRE_MANAGE_SUB_USER_PASSWORD} from "../actions/sub-users/pre-manage-sub-user-password-action";

const initialState = () => ({
  "loading": false,
  "status": "",
  "successMessage": "",
  "secondaryMessage": "",
  "secondaryMessagePayLoad": {},
  "confirmation": {
    "label": "",
    "action": null,
    "route": null,
    "routeState": {}
  }
});

// eslint-disable-next-line complexity,max-lines-per-function
export default (state = initialState(), action = {}) => produce(state, draft => {
  switch (action.type) {
    case APPLICATION_START_ACTION:
    case PRE_SSO_LOGIN_ACTION:
    case PRE_URT_ACTION:
    case PRE_ACCOUNT_VALIDATION_ACTION:
    case PRE_SET_SECURITY_QUESTION_ACTION:
    case PRE_VERIFY_SECURITY_QUESTION_ACTION:
    case PRE_MANAGE_USER:
    case PRE_MANAGE_SUB_USER:
    case PRE_MANAGE_SUB_USER_PASSWORD:
    case PRE_CREATE_SUB_USER_ACTION:
    case PRE_ENABLE_DISABLE_SUB_USER:
    case PRE_DELETE_SUB_USER:
    case PRE_RESTORE_SUB_USER:
    case PRE_PASSWORD_RESET_ACTION:
      draft.loading = true;
      draft.status = "";

      return draft;
    case APPLICATION_LOADED_ACTION:
    case POST_SSO_LOGIN_ACTION:
    case POST_URT_ACTION:
    case POST_ACCOUNT_VALIDATION_ACTION:
    case POST_SET_SECURITY_QUESTION_ACTION:
    case POST_VERIFY_SECURITY_QUESTION_ACTION:
    case POST_ENABLE_DISABLE_SUB_USER:
    case URT_ERROR_ACTION:
    case ACCOUNT_VALIDATION_ERROR_ACTION:
    case SSO_LOGIN_ERROR_ACTION:
    case APPLICATION_ERROR_ACTION:
    case SET_SECURITY_QUESTION_ERROR_ACTION:
    case VERIFY_SECURITY_QUESTION_ERROR_ACTION:
      draft.status = "";
      draft.loading = false;

      return draft;

    case DISMISS_LOADER_ACTION:
      return initialState();

    case POST_MANAGE_SUB_USER:
    case POST_MANAGE_SUB_USER_PASSWORD:
    case POST_CREATE_SUB_USER_ACTION:
    case POST_MANAGE_USER:
      draft.status = "success";
      draft.successMessage = "accountManagement.changesSaved";
      draft.loading = false;

      return draft;
    case POST_PASSWORD_RESET_ACTION:
      draft.status = "success";
      draft.successMessage = "passwordReset.passwordResetConfirmation";
      draft.confirmation.label = "signIn";
      draft.confirmation.route = "/login";
      draft.confirmation.action = dismissLoaderAction;
      draft.loading = false;

      return draft;
    case POST_DELETE_SUB_USER_ACTION:
      draft.status = "success";
      draft.loading = false;
      draft.successMessage = "accountManagement.deleted";

      return draft;

    case POST_RESTORE_SUB_USER_ACTION:
      draft.status = "success";
      draft.loading = false;
      draft.successMessage = "accountManagement.restored";

      return draft;

    case CREATE_SUB_USER_ERROR_ACTION:
    case MANAGE_SUB_USER_ERROR:
    case MANAGE_SUB_USER_PASSWORD_ERROR:
    case ENABLE_DISABLE_SUB_USER_ERROR:
    case RESTORE_SUB_USER_ERROR:
    case DELETE_SUB_USER_ERROR:
    case PASSWORD_RESET_ERROR_ACTION:
    case MANAGE_USER_ERROR:
      draft.status = "failure";
      draft.loading = false;

      return draft;
    default:
      return state;
  }
});
