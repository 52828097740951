import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import PageHeader from "@internet-features/header";
import PageFooter from "@internet-features/footer";
import Router from "../router";
import CreateSubUser from "../create-sub-user/create-sub-user";
import ManageSubUserName from "../manage-sub-user/manage-sub-user-name-form";
import ManageSubUserPassword from "../manage-sub-user/manage-sub-user-password-form";
import ManageUserPasswordForm from "../users/manage-user-password-form";

class App extends React.Component {
  static get propTypes() {
    return {
      "isAnyModalOpen": PropTypes.bool
    };
  }

  render() {
    let displayClass = "app-wrapper";

    if (this.props.isAnyModalOpen) {
      displayClass = `${displayClass} modal-open`;
    }

    return (
      <div className={displayClass}>
        <CreateSubUser />
        <ManageSubUserName />
        <ManageSubUserPassword />
        <ManageUserPasswordForm />
        <div aria-hidden={this.props.isAnyModalOpen}>
          <PageHeader />
          <Router />
          <PageFooter />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  "isAnyModalOpen": state.forms.isAnyModalOpen
});

export default connect(mapStateToProps, null)(App);
