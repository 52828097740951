export const TOKEN_REFRESH_INITIALIZED = "authentication/TOKEN_REFRESH_INITIALIZED";

export default function tokenRefreshInitializedAction(handle) {
  return {
    "payload": {
      handle
    },
    "type": TOKEN_REFRESH_INITIALIZED
  };
}
