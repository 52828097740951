import validatePhoneNumber from "./phone-number-is-valid";
import validateMacAddress from "./mac-address-is-valid";
import isCaptchaValid from "./is-captcha-valid-aggregator";

export default function isUsernameRecoveryValid(phone, mac, captcha) {
  if (phone && mac && captcha) {
    return validatePhoneNumber(phone) && validateMacAddress(mac) && isCaptchaValid(captcha);
  }

  return false;
}
