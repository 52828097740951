import produce from "immer";
import {get} from "lodash";
import {SHOW_MANAGE_SUB_USER_NAME_MODAL} from "../actions/sub-users/show-manage-sub-user-name-modal-action";
import {HIDE_MANAGE_SUB_USER_NAME_MODAL} from "../actions/sub-users/hide-manage-sub-user-name-modal-action";
import {HIDE_CREATE_SUB_USER_MODAL} from "../actions/sub-users/hide-create-sub-user-modal-action";
import {UPDATE_USERNAME} from "../actions/sub-users/update-username-action";
import {VALIDATE_USERNAME} from "../actions/sub-users/validate-username-action";
import {FOCUS_USERNAME} from "../actions/sub-users/focus-username-action";
import {BLUR_USERNAME} from "../actions/sub-users/blur-username-action";
import {CREATE_SUB_USER_ERROR_ACTION} from "../actions/sub-users/create-sub-user-error-action";
import {POST_CREATE_SUB_USER_ACTION} from "../actions/sub-users/post-create-sub-user-action";
import {POST_MANAGE_SUB_USER} from "../actions/sub-users/post-manage-sub-user-action";
import {isUsernameValidAggregation} from "../validators/is-username-valid-aggregator";
import {SHOW_MANAGE_SUB_USER_PASSWORD_MODAL} from "../actions/sub-users/show-manage-sub-user-password-modal-action";
import {SHOW_CREATE_SUB_USER_MODAL} from "../actions/sub-users/show-create-sub-user-modal-action";
import {POST_MANAGE_SUB_USER_PASSWORD} from "../actions/sub-users/post-manage-sub-user-password-action";

const initialState = () => ({
  "username": "",
  "email": "",
  "domain": "",
  "usernameError": "",
  "isUsernameCharacters": true,
  "isUsernameLengthValid": false,
  "isUsernameNoStartEndDot": true,
  "focusUsername": false
});

// eslint-disable-next-line max-lines-per-function,complexity,max-statements
export default (state = initialState(), action = {}) => produce(state, draft => {
  switch (action.type) {
    case CREATE_SUB_USER_ERROR_ACTION:

      return updateUsernameFieldDraft(action, draft);
    case SHOW_CREATE_SUB_USER_MODAL:

      return initialState();
    case SHOW_MANAGE_SUB_USER_NAME_MODAL:
    case SHOW_MANAGE_SUB_USER_PASSWORD_MODAL:
      // eslint-disable-next-line no-param-reassign
      draft = initialState();
      draft.username = get(action.payload.managedUser, "userId");
      draft.email = get(action.payload.managedUser, "email");
      draft.domain = draft.email.substr(draft.email.indexOf("@") + 1);

      return draft;
    case HIDE_MANAGE_SUB_USER_NAME_MODAL:
    case HIDE_CREATE_SUB_USER_MODAL:
    case POST_CREATE_SUB_USER_ACTION:
    case POST_MANAGE_SUB_USER:
    case POST_MANAGE_SUB_USER_PASSWORD:

      return initialState();
    case UPDATE_USERNAME:
      draft.username = get(action, "payload.username");

      return draft;
    case VALIDATE_USERNAME:
     updateUsernameFieldDraft(action, draft);

     return draft;
    case FOCUS_USERNAME:
      draft.focusUsername = true;

      return draft;
    case BLUR_USERNAME:
      draft.focusUsername = false;

      return draft;
    default:
      return state;
  }
});


export function updateUsernameFieldDraft(action, draft) {
  const aggregate = isUsernameValidAggregation(draft.username);
  draft.isUsernameCharacters = aggregate.hasNeededChar;
  draft.isUsernameLengthValid = aggregate.isLengthValid;
  draft.isUsernameNoStartEndDot = aggregate.hasNoStartEndDot;
  draft.usernameError = "";

  if (!aggregate.isValid) {
    draft.usernameError = "usernameInvalidMessage";
  }

  return draft;
}
