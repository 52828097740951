import axios from "axios";
import "core-js";
import queryString from "querystring";
import preSSOLoginAction from "../../actions/authentication/pre-sso-login-action";
import ssoLoginErrorAction from "../../actions/authentication/sso-login-error-action";
import postSSOLoginAction from "../../actions/authentication/post-sso-login-action";
import setupTokenRefreshActionCreator from "./setup-token-refresh-action-creator";
import api from "../common/api-checks";
import token from "../common/api-token-checks";
import isLoginFieldsValid from "../../validators/is-login-fields-valid-aggregator";
import isApiDataValid from "../../validators/is-api-data-valid-aggregator";
import loginUserErrorAction from "../../actions/login-form/login-user-error-action";
import {get} from "lodash";
import trackData from "../../actions/helix/track-data-action";

// eslint-disable-next-line max-lines-per-function
export default function passwordLoginActionCreator() {
  /* eslint-disable max-statements */
  // eslint-disable-next-line max-lines-per-function
  return (dispatch, getState) => {
    const state = getState();

    const formData = {
      "username": get(state, "loginForm.username"),
      "password": get(state, "loginForm.password"),
      "recaptcha": get(state, "captcha.captchaToken")
    };

    dispatch(
        trackData(
        "SELFCARE_loginStart_manual",
            {"opUserText": formData.username}
        )
    );

    if (!isLoginFieldsValid(formData)) {
      dispatch(loginUserErrorAction("application.errors.genericError", formData));

      return false;
    }

    const apiData = {
      "client_id": getState().config.get("api.oauth.client.id"),
      "client_secret": getState().config.get("api.oauth.client.secret"),
      "grant_type": "password",
      "scope": getState().config.get("api.oauth.scopes")
    };

    if (!isApiDataValid(apiData)) {
      dispatch(ssoLoginErrorAction("application.errors.systemErrorMessage"), formData);

      return false;
    }

    dispatch(preSSOLoginAction(formData));

    const url = getState().config.get("api.oauth.auth");

    if (!url) {
      dispatch(ssoLoginErrorAction("OAuth URL Missing", formData));

      return false;
    }

    const payloadData = {
      ...apiData,
      ...formData
    };

    return axios({
      "data": queryString.stringify(payloadData),
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "method": "POST",
      url,
      "withCredentials": true
    }).then(async response => {
      if (!api.hasData(response) || !token.hasTokenFields(response.data)) {
        dispatch(ssoLoginErrorAction("OAuth Token is invalid", formData));

        dispatch(
            trackData(
                "SELFCARE_loginStop_manualFailure",
                {
                  "appErrorCode": response.status,
                  "opUserText": formData.username
                }
            )
        );

return false;
      }

      await dispatch(postSSOLoginAction(response.data));
      dispatch(setupTokenRefreshActionCreator());

      dispatch(
          trackData(
              "SELFCARE_loginStop_manualSuccess",
              {"opUserText": formData.username}
          )
      );

return true;
    })
    .catch(error => {
      dispatch(ssoLoginErrorAction(error, formData));

      dispatch(
          trackData(
              "SELFCARE_loginStop_manualFailure",
              {
                "appErrorCode": error.response ? error.response.status : 500,
                "opUserText": formData.username
              }
          )
      );
    });
  };
}
