const blockedPhrases = [
  "welcome",
  "abc",
  "qwer",
  "asdf",
  "zxcv",
  "123",
  "4321",
  "charter",
  "spectrum",
  "timewarner",
  "roadrunner",
  "password",
  "default",
  "changeme"
];

const regex = new RegExp(blockedPhrases.join("|"), "i");

export default value => {
  if (value !== "") {
    return !regex.test(value);
  }

  return true;
};
