export const RESTORE_SUB_USER_ERROR = "sub-users/RESTORE_SUB_USER_ERROR";

export default function restoreSubUserErrorAction(error) {
  return {
    "payload": {
      error
    },
    "type": RESTORE_SUB_USER_ERROR
  };
}
