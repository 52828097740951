export const SHOW_MANAGE_USER_PASSWORD_MODAL = "users/SHOW_MANAGE_USER_PASSWORD_MODAL";

export default function showManageUserPasswordModalAction(managedUser, returnFocusRef) {
  return {
    "payload": {
      managedUser,
      returnFocusRef
    },
    "type": SHOW_MANAGE_USER_PASSWORD_MODAL
  };
}
