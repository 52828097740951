import React from "react";
import PropTypes from "prop-types";
import {Translate} from "react-i18nify";
import Icon from "@internet-features/icon";
import "./error.scss";

class FormError extends React.Component {
  static propTypes = {
    "error": PropTypes.string,
    "errorHeader": PropTypes.string
  };

  render() {
    return (
      <div className="row flex-nowrap bg-light b-1-solid-secondary bl-4-solid-danger m-0 mb-4 py-2">
        <div className="error-icon-max-width col-1">
          <Icon
            name={"ki-caution-alert-f"}
            className="svg-fill-danger"
            aria-hidden={true}
            style={{
              "height": "18px",
              "width": "18px"
            }}
          />
        </div>
        <div className="col-11" role="alert">
          <Translate value={this.props.errorHeader || ""} default="application.errors.error" className="sr-only" />
          <Translate value={this.props.error || ""} default={"application.errors.default"} />
        </div>
      </div>
    );
  }
}

export default FormError;
