import axios from "axios";
import "core-js";
import queryString from "querystring";
import preCreateSubUserAction from "../../actions/sub-users/pre-create-sub-user-action";
import createSubUserErrorAction from "../../actions/sub-users/create-sub-user-error-action";
import postCreateSubUserAction from "../../actions/sub-users/post-create-sub-user-action";
import isCreateSubUserValid from "../../validators/is-create-sub-user-valid-aggregator";
import API from "../common/api-checks";
import UserCheck from "../common/api-user-checks";
import {get} from "lodash";

export default function createSubUserActionCreator() {
  /* eslint-disable max-statements */
  return (dispatch, getState) => {
    const state = getState();

    const formData = {
      "userId": get(state, "usernameField.username"),
      "firstName": get(state, "nameFields.firstName"),
      "lastName": get(state, "nameFields.lastName"),
      "password": get(state, "passwordFields.password"),
      "passwordConfirm": get(state, "passwordFields.passwordConfirm"),
      "recaptcha": get(state, "captcha.captchaToken")
    };

    if (!isCreateSubUserValid(formData)) {
      dispatch(createSubUserErrorAction("application.errors.genericError", formData));

      return false;
    }

    const url = state.config.get("api.urls.createSubUser");

    if (!url) {
      dispatch(createSubUserErrorAction("application.errors.subUserURLMissingMessage", formData));

      return false;
    }

    const payloadData = {
      "user_id": formData.userId,
      "first_name": formData.firstName,
      "last_name": formData.lastName,
      "password": formData.password,
      "recaptcha": formData.recaptcha
    };

    dispatch(preCreateSubUserAction());

    return axios({
      "headers": {
        ...API.getAuthorization(state),
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": queryString.stringify(payloadData),
      "method": "POST",
      url
    })
    .then(response => {
      if (API.hasData(response) && UserCheck.hasUserFields(response.data)) {
        dispatch(postCreateSubUserAction(response.data));

        return true;
      }
      dispatch(createSubUserErrorAction("application.errors.systemErrorMessage", formData));

      return false;
    })
    .catch(error => {
      dispatch(createSubUserErrorAction(error, formData));

      return false;
    });
  };
}
