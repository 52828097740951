import React, {Component} from "react";
import PropTypes from "prop-types";
import Icon from "@internet-features/icon";
import FormError from "../error/form";

class ModalHeader extends Component {
  constructor(props) {
    super(props);

    this.closeButtonRef = React.createRef();
  }

  static get propTypes() {
    return {
      "title": PropTypes.string,
      "error": PropTypes.string,
      "onHide": PropTypes.func
    };
  }

  render() {
    let title = "";
    let error = "";

    if (this.props.title) {
      title = <div className="modal-title m-0 p-0">
        <h1 className="modal-title h3">{this.props.title}</h1>
      </div>;
    }

    if (this.props.error) {
      error = <FormError error={this.props.error}/>;
    }

    return <>
      <div className="modal-upper-header">
        <button
          type="button"
          className="btn btn-link modal-close m-0 p-0"
          style={{"minWidth": "auto"}}
          onClick={this.props.onHide}
          aria-label="Close dialog"
          ref={this.closeButtonRef}>
          <Icon name="ki-x"/>
        </button>
      </div>
      <div className="modal-error-message m-0 p-0" aria-live="assertive">
        {error}
      </div>
      {title}
    </>;
  }
}

export default ModalHeader;
