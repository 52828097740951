import React from "react";
import {connect} from "react-redux";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Login from "../login";
import PropTypes from "prop-types";
import AccountInfo from "../account-info";
import SubAccountList from "../sub-accounts";
import PasswordReset from "../password-reset";
import UsernameRecovery from "../username-recovery";

class Router extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route
            path="/"
            exact={true}
            component={() => <Redirect to="/login" />}
          />

          <Route
            path="/login"
            component={() => {
              if (this.props.isAuthenticated) {
                return <Redirect to="/info" />;
              }

              return <Login />;
            }} />
          <Route
            path="/info"
            component={() => {
              if (!this.props.isAuthenticated) {
                return <Redirect to="/login" />;
              }

              return <AccountInfo />;
            }} />
          <Route
            path="/people"
            component={() => {
              if (!this.props.isAuthenticated) {
                return <Redirect to="/login" />;
              }

              return <SubAccountList />;
            }} />
          <Route
            path="/password-reset"
            component={() => <PasswordReset />} />
          <Route
            path="/username-recovery"
            component={() => <UsernameRecovery />} />
        </Switch>
      </BrowserRouter>
    );
  }
}

Router.propTypes = {
  "isAuthenticated": PropTypes.bool
};

const mapStateToProps = state => ({
  "isAuthenticated": state.auth.isAuthenticated
});

export default connect(mapStateToProps)(Router);
