export default phoneNumber => {
  let number = phoneNumber.replace(/[^\d]/g, "");

  if (number.replace(/[\D]/g, "").length === 10) {
    number = phoneNumber.replace(/[\D]/g, "");

    return number.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }

  return number;
};
