export const UPDATE_USERNAME = "sub-users/UPDATE_USERNAME";

export default function updateUsernameAction(username) {
  return {
    "payload": {
      username
    },
    "type": UPDATE_USERNAME
  };
}
