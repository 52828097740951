export const UPDATE_PASSWORD_CONFIRM = "sub-users/UPDATE_PASSWORD_CONFIRM";

export default function updatePasswordConfirmAction(passwordConfirm) {
  return {
    "payload": {
      passwordConfirm
    },
    "type": UPDATE_PASSWORD_CONFIRM
  };
}
