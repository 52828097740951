import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";

import axios from "axios";
import "core-js";

import store from "./store";
import App from "./components/app";

axios.defaults.withCredentials = true;

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("app")
);
