import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import getId from "react-id-generator";
import {Translate} from "react-i18nify";
import validatePasswordConfirmAction from "../../actions/sub-users/validate-password-confirm-action";
import togglePasswordConfirmShowHideAction from "../../actions/sub-users/toggle-password-confirm-show-hide-action";
import updatePasswordConfirmAction from "../../actions/sub-users/update-password-confirm-action";

class PasswordConfirmField extends React.Component {
  static get propTypes() {
    return {
      "inputRef": PropTypes.object,
      "passwordConfirm": PropTypes.string,
      "passwordConfirmError": PropTypes.string,
      "showPasswordConfirmChars": PropTypes.bool,
      "togglePasswordConfirmShowHide": PropTypes.func,
      "updatePasswordConfirm": PropTypes.func,
      "validatePasswordConfirm": PropTypes.func
    };
  }

  // eslint-disable-next-line max-lines-per-function,max-statements
  render() {
    let showHideTextKey = "password.show";
    let elementType = "password";

    if (this.props.showPasswordConfirmChars) {
      showHideTextKey = "password.hide";
      elementType = "text";
    }

    let passwordConfirmInputClass = "";
    let passwordConfirmError = "";

    const id = getId();
    const showError = this.props.passwordConfirmError !== "";

    if (showError) {
      passwordConfirmError = <Translate
        className="password-confirm-error d-block"
        value={`accountManagement.${this.props.passwordConfirmError}`}/>;
      passwordConfirmInputClass = "border-danger";
    }

    return (
      <div className="form-group">
        <label>
          <Translate value="accountManagement.manageConfirmNewPassword"/>
          <input
            aria-invalid={showError}
            aria-required
            aria-describedby={id}
            type={elementType}
            className={`password-confirm-input form-control ${passwordConfirmInputClass}`}
            onChange={this.props.updatePasswordConfirm}
            onBlur={this.props.validatePasswordConfirm}
            ref={this.props.inputRef}
            name="password-confirm"
            value={this.props.passwordConfirm}/>
        </label>
        <button
          onClick={this.props.togglePasswordConfirmShowHide}
          className="password-confirm-show-hide d-block d-lg-inline p-0 mx-0 mt-2 mb-3 mx-lg-3 my-lg-0 b-none bg-transparent"
          type="button">
          <small>
            <Translate className="text-primary" value={showHideTextKey} />
          </small>
        </button>
        <small
          className="text-danger"
          aria-live="polite"
          id={id}>
          {passwordConfirmError}
        </small>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  "passwordConfirm": state.passwordFields.passwordConfirm,
  "passwordConfirmError": state.passwordFields.passwordConfirmError,
  "showPasswordConfirmChars": state.passwordFields.showPasswordConfirmChars
});

const mapDispatchToProps = dispatch => ({
  "validatePasswordConfirm": () => {
    dispatch(validatePasswordConfirmAction(true));
  },
  "togglePasswordConfirmShowHide": () => {
    dispatch(togglePasswordConfirmShowHideAction());
  },
  "updatePasswordConfirm": event => {
    dispatch(updatePasswordConfirmAction(event.target.value));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordConfirmField);
