import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import setActiveUser from "../../actions/application/set-active-user";
import showCreateSubUser from "../../actions/sub-users/show-create-sub-user-modal-action";
import canCreateSubUser from "../../utilities/can-create-sub-user";
import "./sub-accounts.scss";
import {Link} from "react-scroll";
import {Translate} from "react-i18nify";

class SubAccountStickyList extends React.Component {
  constructor(props) {
    super(props);

    this.activeUser = null;
    this.setActiveUser = this.setActiveUser.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  static get propTypes() {
    return {
      "activeUser": PropTypes.object,
      "allowedAccounts": PropTypes.number,
      "canCreateSubUser": PropTypes.bool,
      "deniedCreateSubUserReason": PropTypes.string,
      "loggedInAs": PropTypes.string,
      "maxUsers": PropTypes.number,
      "setActiveUser": PropTypes.func,
      "showCreateSubUser": PropTypes.func,
      "usedAccounts": PropTypes.number,
      "user": PropTypes.shape(
        {
          "email": PropTypes.string,
          "firstName": PropTypes.string,
          "isHoh": PropTypes.bool,
          "lastName": PropTypes.string,
          "status": PropTypes.oneOf([
            "Enabled",
            "Disabled",
            "Pending Delete"
          ])
        }
      ),
      "userList": PropTypes.arrayOf(PropTypes.shape(
        {
          "email": PropTypes.string,
          "firstName": PropTypes.string,
          "isHoh": PropTypes.bool,
          "lastName": PropTypes.string,
          "status": PropTypes.oneOf([
            "Enabled",
            "Disabled",
            "Pending Delete"
          ])
        }
      )),
      "userRefs": PropTypes.array
    };
  }

  setActiveUser(value) {
    this.props.setActiveUser(value);
  }

  handleScroll() {
    const keys = Object.keys(this.props.userRefs);
    for (const key of keys) {
      if (this.props.userRefs[key].current !== null && this.props.userRefs[key].current.getBoundingClientRect().bottom > 0) {
        this.setActiveUser(key);
        break;
      }
    }
  }

  componentDidMount() {
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  createSubUserButton() {
    if (!canCreateSubUser(this.props)) {
      return "";
    }

    return <button id="sticky-list-add-user-button" className="btn btn-primary btn-block col-2"
            onClick={this.props.showCreateSubUser}>
      <Translate value="accountManagement.addPerson"/>
    </button>;
  }

  render() {
    let mainUserClass = "userListItem";

    if (this.props.activeUser.userId === this.props.user.email) {
      mainUserClass += " sub-account-active";
    }

    return (
      <div className="sub-account-list mb-5">
        <div className="d-none d-md-block">
          <div className={mainUserClass}>
            <Link to={this.props.user.email} href={`#${this.props.user.email}`} spy={true} smooth={true} duration={500}>
              {`${this.props.user.firstName} ${this.props.user.lastName}`}
            </Link>
          </div>
          {
            this.props.userList.map((user, index) => {
              let extraClass = "userListItem";

              if (this.props.activeUser.userId === user.email) {
                extraClass += " sub-account-active";
              }

              return <div key={index} className={extraClass}>
                <Link to={user.email} href={`#${user.email}`} spy={true} smooth={true} duration={500}>
                  {`${user.firstName} ${user.lastName}`}
                </Link>
              </div>;
            })
          }
          {this.createSubUserButton()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  "activeUser": state.activeUser,
  "maxUsers": state.user.maxUsers,
  "user": state.user,
  "userList": state.subUsers.users
});

const mapDispatchToProps = dispatch => ({
  "setActiveUser": userId => {
    dispatch(setActiveUser(userId));
  },
  "showCreateSubUser": () => {
    dispatch(showCreateSubUser());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SubAccountStickyList);
