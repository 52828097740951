import axios from "axios";
import "core-js";
import queryString from "querystring";
import {get} from "lodash";
import stripHex from "../../formatters/strip-hexadecimals";
import isAnswerNotEmpty from "../../validators/empty-field";
import securityQuestionAnswerErrorAction from "../../actions/password-reset/security-question-answer-error-action";
import verifySecurityQuestionErrorAction from "../../actions/password-reset/verify-security-question-error-action";
import preVerifySecurityQuestionAction from "../../actions/password-reset/pre-verify-security-question-action";
import postVerifySecurityQuestionAction from "../../actions/password-reset/post-verify-security-question-action";

export default function verifySecurityQuestionActionCreator(answer) {
  return (dispatch, getState) => {
    const state = getState();

    const formData = {
      "email": get(state, "emailAddress.emailAddress"),
      "userId": get(state, "emailAddress.emailAddress").split("@")[0],
      "macAddress": stripHex(get(state, "macAddress.macAddress")),
      "securityQuestion": get(state, "passwordReset.securityQuestion"),
      "securityAnswer": answer
    };

    if (!isAnswerNotEmpty(formData.securityAnswer)) {
      dispatch(securityQuestionAnswerErrorAction("passwordReset.error.emptyAnswer"));

      return false;
    }

    const url = getState().config.get("api.urls.securityAnswer");

    if (!url) {
      dispatch(verifySecurityQuestionErrorAction("URL Missing"));

      return false;
    }

    const payload = {
      "email": formData.email,
      "device_id": formData.macAddress,
      "question": formData.securityQuestion,
      answer
    };

    dispatch(preVerifySecurityQuestionAction());

    return axios({
        "headers": {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        "data": queryString.stringify(payload),
        "method": "POST",
        url
      }
    )
    .then(response => {
      if (!response.data || !response.data.success) {
        dispatch(verifySecurityQuestionErrorAction(response));

        return;
      }
      dispatch(postVerifySecurityQuestionAction(answer));
    })
    .catch(error => {
      dispatch(verifySecurityQuestionErrorAction(error.response.data));
    });
  };
}
