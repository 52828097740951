export const GET_USER_ACTION_ERROR = "users/GET_USER_ACTION";

export default function getUserActionError(error) {
  return {
    "payload": {
      error
    },
    "type": GET_USER_ACTION_ERROR
  };
}
