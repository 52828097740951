import {I18n} from "react-i18nify";

export const LOAD_TRANSLATIONS = "client/translate/LOAD_TRANSLATIONS";

export default function loadTranslations(translations) {
  return dispatch => {
    dispatch({
      "payload": translations,
      "type": LOAD_TRANSLATIONS
    });
    I18n.forceComponentsUpdate();
  };
}
