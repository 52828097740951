export default (token1 = "", token2 = "") => {
  if (token1 !== "" && token2 !== "") {
    // eslint-disable-next-line no-useless-escape
    const token = token1.replace(/([\[\](){}\\*+\-.^$/])/g, "\\$1");

    return !RegExp(token,"i").test(token2);
  }

  return true;
};
