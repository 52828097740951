import React, {Component} from "react";
import PropTypes from "prop-types";
import activeElement from "dom-helpers/activeElement";
import FocusLock from "react-focus-lock";
import ModalHeader from "./modal-header";

class Modal extends Component {
  constructor(props) {
    super(props);

    this.dialogRef = React.createRef();
    this.headerRef = React.createRef();
    this.invokedByElement = null;

    this.onClickBackdrop = this.onClickBackdrop.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  static get propTypes() {
    return {
      "className": PropTypes.string,
      "show": PropTypes.bool,
      "title": PropTypes.string,
      "error": PropTypes.string,
      "size": PropTypes.string,
      "onHide": PropTypes.func,
      "children": PropTypes.any,
      "returnFocusRef": PropTypes.object
    };
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      this.invokedByElement = activeElement();
    }

    return null;
  }

  onClickBackdrop(event) {
    if (event.target === this.dialogRef.current && this.props.onHide) {
      this.props.onHide();
    }
  }

  onKeyPress(event) {
    if (event.key === "Escape" && this.props.onHide) {
      this.props.onHide();
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyPress, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyPress, false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.show || !prevProps.show) {
      return;
    }

    if (this.props.returnFocusRef && this.props.returnFocusRef.current) {
      this.props.returnFocusRef.current.focus();

      return;
    }

    if (this.invokedByElement && this.invokedByElement.focus) {
      this.invokedByElement.focus();
      this.invokedByElement = null;
    }
  }

  render() {
    let displayClass = "show visible";
    let {title, error, children} = this.props;

    if (!this.props.show) {
      displayClass = "hide invisible";
      title = "";
      error = "";
      children = "";
    }

    let dialogClassName = `fade modal d-block ${displayClass}`;
    let documentClassName = "modal-dialog modal-dialog-centered";

    if (this.props.className) {
      dialogClassName = `${dialogClassName} ${this.props.className}`;
    }

    if (this.props.size) {
      documentClassName = `${documentClassName} modal-${this.props.size}`;
    }

    return <>
      <div className={`fade modal-backdrop d-block ${displayClass}`}></div>
      <div
        className={dialogClassName}
        aria-label={this.props.title}
        aria-modal={this.props.show}
        role="dialog"
        ref={this.dialogRef}
        onClick={this.onClickBackdrop}>
        <div
          className={documentClassName}
          role="document">
          <div className="modal-content">
            <FocusLock disabled={!this.props.show}>
              <ModalHeader
                title={title}
                error={error}
                onHide={this.props.onHide}
                ref={this.headerRef}/>
              <div className="modal-body">
                {children}
              </div>
            </FocusLock>
          </div>
        </div>
      </div>
    </>;
  }
}

export default Modal;
