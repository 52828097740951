import validateStringLength from "./string-length";
import validateRepeatChars from "./no-same-character-three-times";
import validateHasLower from "./string-has-lower";
import validateHasNumber from "./string-has-number";
import validateHasUpper from "./string-has-upper";
import validateHasOnlyPasswordChars from "./string-has-password-allowed-chars";
import validatePasswordNoUsername from "./tokens-not-duplicated";
import validatePasswordPhrases from "./string-blocked-phrases-for-password";
import validateFieldNotEmpty from "./empty-field";

export function isPasswordValidAggregation(password, userId) {
  const aggregate = {};
  aggregate.isNotEmpty = validateFieldNotEmpty(password);
  aggregate.isLengthValid = validateStringLength(password, 8, 16);
  aggregate.hasNoRepeatChars = validateRepeatChars(password);
  aggregate.hasNeededChar = validateHasLower(password) &&
    validateHasNumber(password) &&
    validateHasUpper(password) &&
    validateHasOnlyPasswordChars(password);
  aggregate.isPhraseChecked = validatePasswordPhrases(password);
  aggregate.isUserIdNotInPassword = validatePasswordNoUsername(userId, password);
  aggregate.isValid = aggregate.isNotEmpty &&
    aggregate.hasNoRepeatChars &&
    aggregate.isLengthValid &&
    aggregate.isPhraseChecked &&
    aggregate.isUserIdNotInPassword &&
    aggregate.hasNeededChar;

  return aggregate;
}

export default function isPasswordValid(password, userId) {
  return isPasswordValidAggregation(password, userId).isValid;
}
