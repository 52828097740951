export const PASSWORD_RESET_ERROR_ACTION = "password-reset/PASSWORD_RESET_ERROR_ACTION";

export default function passwordResetErrorAction(error) {
  return {
    "payload": {
      error
    },
    "type": PASSWORD_RESET_ERROR_ACTION
  };
}
