import produce from "immer";
import {UPDATE_LOGIN_USERNAME} from "../actions/login-form/update-login-username-action";
import {UPDATE_LOGIN_PASSWORD} from "../actions/login-form/update-login-password-action";
import {VALIDATE_LOGIN_USERNAME} from "../actions/login-form/validate-login-username-action";
import {VALIDATE_LOGIN_PASSWORD} from "../actions/login-form/validate-login-password-action";
import {SSO_LOGIN_ERROR_ACTION} from "../actions/authentication/sso-login-error-action";
import {PRE_SSO_LOGIN_ACTION} from "../actions/authentication/pre-sso-login-action";
import {POST_SSO_LOGIN_ACTION} from "../actions/authentication/post-sso-login-action";
import {GET_SUB_USER_ACTION_ERROR} from "../actions/sub-users/get-sub-user-action-error-action";
import {GET_USER_ACTION_ERROR} from "../actions/users/get-user-action-error";
import {SET_REMEMBER_ME} from "../actions/login-form/remember-me-action";
import {SIGN_OUT} from "@internet-features/header/src/actions/sign-out-action";
import {LOGIN_USER_ERROR} from "../actions/login-form/login-user-error-action";
import validateEmail from "../validators/email";
import validateEmptyField from "../validators/empty-field";
import {get, has} from "lodash";

const initialState = () => {
  const state = {
    "loginMessage": "",
    "password": "",
    "passwordError": "",
    "passwordIsValid": true,
    "rememberMe": false,
    "username": "",
    "usernameError": "",
    "usernameIsValid": true
  };

  if (localStorage.getItem("loginUsername")) {
    state.username = localStorage.getItem("loginUsername");
    state.rememberMe = true;
  }

  return state;
};

// eslint-disable-next-line max-lines-per-function,complexity,max-statements
export default (state = initialState(), action = {}) => produce(state, draft => {
  switch (action.type) {
    case PRE_SSO_LOGIN_ACTION:
      draft.loginMessage = "";

      return draft;
    case SSO_LOGIN_ERROR_ACTION:
      // Skip error messages for SSO Login for Captcha
      if (get(action, "payload.error.response.status") === 401 &&
        get(action, "payload.error.response.data.title") === "invalid_captcha"
      ) {
        draft.loginMessage = "loginForm.errors.401";

        return draft;
      }

      if (get(action, "payload.error.response.status") === 423 &&
        get(action, "payload.error.response.data.title") === "account_locked"
      ) {
        draft.loginMessage = "loginForm.accountLock";

        return draft;
      }

      if (get(action, "payload.error.response.status") === 401 &&
        get(action, "payload.error.response.data.title") === "ip_locked"
      ) {
        draft.loginMessage = "loginForm.ipLock";

        return draft;
      }

      if (has(action, "payload.error.response.status")) {
        draft.loginMessage = `loginForm.errors.${action.payload.error.response.status.toString()}`;

        return draft;
      }
      draft.loginMessage = "loginForm.errors.default";

      return draft;
    case POST_SSO_LOGIN_ACTION:
      if (state.rememberMe) {
        localStorage.setItem("loginUsername", state.username);

        return draft;
      }
      localStorage.removeItem("loginUsername");

      return draft;
    case GET_SUB_USER_ACTION_ERROR:
    case GET_USER_ACTION_ERROR:
      draft.loginMessage = "loginForm.errors.userFetch";

      return draft;
    case SET_REMEMBER_ME:
      draft.rememberMe = get(action,"payload");

      return draft;
    case SIGN_OUT:
      if (state.rememberMe) {
        // eslint-disable-next-line no-param-reassign
        draft = initialState();
        draft.username = state.username;
        draft.rememberMe = state.rememberMe;

        return draft;
      }

      return initialState();
    case UPDATE_LOGIN_USERNAME:
      draft.username = get(action,"payload.username");

      return draft;
    case VALIDATE_LOGIN_USERNAME:
      draft.usernameIsValid = validateEmail(state.username);
      if (!draft.usernameIsValid) {
        draft.usernameError = "loginForm.emailHelp";
      }

      if (draft.usernameIsValid && state.rememberMe) {
        localStorage.setItem("loginUsername", draft.username);
      }

      return draft;
    case UPDATE_LOGIN_PASSWORD:
      draft.password = get(action, "payload.password");
      draft.passwordError = "";

      return draft;
    case VALIDATE_LOGIN_PASSWORD:
      draft.passwordIsValid = validateEmptyField(state.password);

      if (!draft.passwordIsValid) {
        draft.passwordError = "loginForm.passwordHelp";
      }

      return draft;
    case LOGIN_USER_ERROR:
      // eslint-disable-next-line no-case-declarations
      draft.username = get(action, "payload.data.username");
      draft.password = get(action, "payload.data.password");

      draft.usernameIsValid = validateEmptyField(draft.username);
      if (!draft.usernameIsValid) {
        draft.usernameError = "loginForm.emailHelp";
      }

      draft.passwordIsValid = validateEmptyField(draft.password);
      if (!draft.passwordIsValid) {
        draft.passwordError = "loginForm.passwordHelp";
      }

      draft.loginMessage = get(action, "payload.error");

      return draft;
    default:
      return state;
  }
});
