import produce from "immer";
import {UPDATE_CURRENT_PASSWORD} from "../actions/users/update-current-password-action";
import {VALIDATE_CURRENT_PASSWORD} from "../actions/users/validate-current-password-action";
import {TOGGLE_CURRENT_PASSWORD_SHOW_HIDE} from "../actions/users/toggle-current-password-show-hide-action";
import {SHOW_MANAGE_USER_PASSWORD_MODAL} from "../actions/users/show-manage-user-password-modal-action";
import {HIDE_MANAGE_USER_PASSWORD_MODAL} from "../actions/users/hide-manage-user-password-modal-action";
import {MANAGE_USER_ERROR} from "../actions/users/manage-user-error-action";
import {POST_MANAGE_USER} from "../actions/users/post-manage-user-action";
import validateFieldNotEmpty from "../validators/empty-field";
import {get} from "lodash";

const initialState = () => ({
  "currentPassword": "",
  "currentPasswordError": "",
  "showCurrentPasswordChars": false,
  "isPasswordEmpty": true
});


export default (state = initialState(), action = {}) => produce(state, draft => {
  switch (action.type) {
    case UPDATE_CURRENT_PASSWORD:
      draft.currentPassword = get(action, "payload.currentPassword");
      draft.currentPasswordError = "";

      return draft;
    case VALIDATE_CURRENT_PASSWORD:
      draft.currentPasswordError = "";
      if (!validateFieldNotEmpty(state.currentPassword)) {
        draft.currentPasswordError = "currentPasswordEmptyMessage";
      }

      return draft;
    case TOGGLE_CURRENT_PASSWORD_SHOW_HIDE:
      draft.showCurrentPasswordChars = !state.showCurrentPasswordChars;

      return draft;
    case SHOW_MANAGE_USER_PASSWORD_MODAL:
    case HIDE_MANAGE_USER_PASSWORD_MODAL:
    case POST_MANAGE_USER:

      return initialState();
    case MANAGE_USER_ERROR:
      draft.isPasswordEmpty = validateFieldNotEmpty(state.currentPassword);

      if (!draft.isPasswordEmpty) {
        draft.currentPasswordError = "currentPasswordEmptyMessage";
      }

      return draft;
    default:
      return state;
  }
});
