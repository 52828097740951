export const POST_URT_ACTION = "username-recovery/POST_URT_ACTION";

export default function postUsernameRecoveryAction(email) {
  return {
    "payload": {
      email
    },
    "type": POST_URT_ACTION
  };
}
