import produce from "immer";
import {UPDATE_LAST_NAME} from "../actions/sub-users/update-last-name-action";
import {VALIDATE_LAST_NAME} from "../actions/sub-users/validate-last-name-action";
import {UPDATE_FIRST_NAME} from "../actions/sub-users/update-first-name-action";
import {VALIDATE_FIRST_NAME} from "../actions/sub-users/validate-first-name-action";
import {SHOW_MANAGE_SUB_USER_NAME_MODAL} from "../actions/sub-users/show-manage-sub-user-name-modal-action";
import {SHOW_CREATE_SUB_USER_MODAL} from "../actions/sub-users/show-create-sub-user-modal-action";
import {HIDE_CREATE_SUB_USER_MODAL} from "../actions/sub-users/hide-create-sub-user-modal-action";
import {HIDE_MANAGE_SUB_USER_NAME_MODAL} from "../actions/sub-users/hide-manage-sub-user-name-modal-action";
import {CREATE_SUB_USER_ERROR_ACTION} from "../actions/sub-users/create-sub-user-error-action";
import {MANAGE_SUB_USER_ERROR} from "../actions/sub-users/manage-sub-user-error-action";
import {POST_CREATE_SUB_USER_ACTION} from "../actions/sub-users/post-create-sub-user-action";
import {POST_MANAGE_SUB_USER} from "../actions/sub-users/post-manage-sub-user-action";
import {isNameValidAggregation} from "../validators/is-name-valid-aggregator";
import {get} from "lodash";
import {POST_MANAGE_SUB_USER_PASSWORD} from "../actions/sub-users/post-manage-sub-user-password-action";

const initialState = () => ({
  "firstName": "",
  "firstNameError": "",
  "lastName": "",
  "lastNameError": "",
  "isNameModified": false
});

// eslint-disable-next-line max-statements,max-lines-per-function,complexity
export default (state = initialState(), action = {}) => produce(state, draft => {

  switch (action.type) {
    case CREATE_SUB_USER_ERROR_ACTION:
    case MANAGE_SUB_USER_ERROR:
      updateFirstNameFieldDraft(action, draft);

      return updateLastNameFieldDraft(action, draft);
    case UPDATE_LAST_NAME:
      draft.lastName = get(action, "payload.lastName");
      draft.isNameModified = true;

      return draft;
    case VALIDATE_LAST_NAME:

      return updateLastNameFieldDraft(action, draft);
    case UPDATE_FIRST_NAME:
      draft.firstName = get(action, "payload.firstName");
      draft.isNameModified = true;

      return draft;
    case VALIDATE_FIRST_NAME:

      return updateFirstNameFieldDraft(action, draft);
    case SHOW_MANAGE_SUB_USER_NAME_MODAL:
      // eslint-disable-next-line no-param-reassign
      draft = initialState();
      draft.firstName = get(action.payload.managedUser, "firstName");
      draft.lastName = get(action.payload.managedUser, "lastName");

      return draft;
    case SHOW_CREATE_SUB_USER_MODAL:

      return initialState();
    case HIDE_CREATE_SUB_USER_MODAL:
    case HIDE_MANAGE_SUB_USER_NAME_MODAL:
    case POST_CREATE_SUB_USER_ACTION:
    case POST_MANAGE_SUB_USER:
    case POST_MANAGE_SUB_USER_PASSWORD:

      return initialState();
    default:
      return state;
  }
});

export function updateFirstNameFieldDraft(action, draft) {
  const aggregate = isNameValidAggregation(draft.firstName);

  if (!aggregate.isNotEmpty) {
    draft.firstNameError = "firstNameNotEmpty";

    return draft;
  }
  if (!aggregate.hasNeededChar) {
    draft.firstNameError = "firstNameCharacter";

    return draft;
  }
  if (!aggregate.isLengthValid) {
    draft.firstNameError = "firstNameLength";

    return draft;
  }
  draft.firstNameError = "";

  return draft;
}

export function updateLastNameFieldDraft(action, draft) {
  const aggregate = isNameValidAggregation(draft.lastName);

  if (!aggregate.isNotEmpty) {
    draft.lastNameError = "lastNameNotEmpty";

    return draft;
  }
  if (!aggregate.hasNeededChar) {
    draft.lastNameError = "lastNameCharacter";

    return draft;
  }
  if (!aggregate.isLengthValid) {
    draft.lastNameError = "lastNameLength";

    return draft;
  }
  draft.lastNameError = "";

  return draft;
}
