import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Modal from "../modal";
import {Translate, I18n} from "react-i18nify";
import hideManageSubUserPasswordModal from "../../actions/sub-users/hide-manage-sub-user-password-modal-action";
import manageSubUserPasswordActionCreator from "../../action-creators/users/manage-sub-user-password-action-creator";
import PasswordFields from "../password-fields/index";
import trackData from "../../actions/helix/track-data-action";
import checkForError from "../../utilities/track-ui-error";

class ManageSubUserPassword extends React.Component {
  constructor(props) {
    super(props);

    this.passwordRef = React.createRef();
    this.passwordConfirmRef = React.createRef();

    this.setFocusToError = this.setFocusToError.bind(this);
    this.manageSubUserSubmit = this.manageSubUserSubmit.bind(this);
  }

  static get propTypes() {
    return {
      "formErrorMessage": PropTypes.string,
      "hidePasswordForm": PropTypes.func,
      "isManagePasswordOpen": PropTypes.bool,
      "returnFocusRef": PropTypes.object,
      "submitPassword": PropTypes.func,
      "passwordError": PropTypes.string,
      "passwordConfirmError": PropTypes.string,
      "trackError": PropTypes.func
    };
  }

  setFocusToError() {
    if (this.props.passwordError && this.passwordRef.current) {
      this.passwordRef.current.focus();

      return;
    }
    if (this.props.passwordConfirmError && this.passwordConfirmRef.current) {
      this.passwordConfirmRef.current.focus();
    }
  }

  async manageSubUserSubmit(event) {
    event.preventDefault();
    await this.props.submitPassword();
    this.setFocusToError();

    return false;
  }

  componentDidUpdate(prevProps) {
    checkForError(prevProps, this.props, "accountManagement", "passwordError", this.passwordRef);
    checkForError(prevProps, this.props, "accountManagement", "passwordConfirmError", this.passwordConfirmRef);
  }

  render() {
    return <Modal
      show={this.props.isManagePasswordOpen}
      size="lg"
      title={I18n.t("accountManagement.manageUpdatePassword")}
      error={this.props.formErrorMessage}
      onHide={this.props.hidePasswordForm}
      returnFocusRef={this.props.returnFocusRef}>
      <form className="manage-sub-user-password" onSubmit={this.manageSubUserSubmit}>
        <div className="form-section">
          <PasswordFields inputPasswordRef={this.passwordRef}
                          inputPasswordConfirmRef={this.passwordConfirmRef}
          />
        </div>
        <div className="form-row m-0">
          <div className="col-12 col-lg-3 order-1 order-lg-0 p-0 m-0 mr-lg-4">
            <button
              className="btn btn-secondary btn-block m-0 manage-sub-user-cancel-btn"
              type="reset"
              onClick={this.props.hidePasswordForm}>
              <Translate value="accountManagement.cancel"/>
            </button>
          </div>
          <div className="col-12 col-lg-3 order-0 order-lg-1 p-0 m-0 mb-3 mb-lg-0">
            <button
              className="btn btn-primary btn-block m-0 manage-sub-user-submit-btn"
              type="submit">
              <Translate value="accountManagement.save"/>
            </button>
          </div>
        </div>
      </form>
    </Modal>;
  }
}

const mapStateToProps = state => ({
  "formErrorMessage": state.apiError.errorMessage,
  "isManagePasswordOpen": state.forms.isManageSubUserPasswordFormOpen,
  "returnFocusRef": state.forms.returnFocusRef,
  "passwordError": state.passwordFields.passwordError,
  "passwordConfirmError": state.passwordFields.passwordConfirmError
});

const mapDispatchToProps = dispatch => ({
  "hidePasswordForm": () => dispatch(hideManageSubUserPasswordModal()),
  "submitPassword": () => dispatch(manageSubUserPasswordActionCreator()),
  "trackError": errorMessage => dispatch(trackData(
      "SELFCARE_error_updatePasswordError",
      {
        "appErrorType": "generic",
        "appErrorMessage": errorMessage
      }
  ))
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageSubUserPassword);
