import axios from "axios";
import "core-js";
import {get} from "lodash";
import queryString from "querystring";
import preManageSubUserPasswordAction from "../../actions/sub-users/pre-manage-sub-user-password-action";
import isPasswordFormValidAggregator from "../../validators/is-password-form-valid-aggregator";
import API from "../../action-creators/common/api-checks";
import UserCheck from "../../action-creators/common/api-user-checks";
import postManageSubUserPasswordAction from "../../actions/sub-users/post-manage-sub-user-password-action";
import manageSubUserPasswordErrorAction from "../../actions/sub-users/manage-sub-user-password-error-action";

// eslint-disable-next-line camelcase
export default function manageSubUserPasswordActionCreator() {
  /* eslint-disable max-statements */
  return (dispatch, getState) => {

    const state = getState();

    const formData = {
      "userId": get(state, "usernameField.username"),
      "password": get(state, "passwordFields.password"),
      "passwordConfirm": get(state, "passwordFields.passwordConfirm")
    };

    if (!isPasswordFormValidAggregator(formData)) {
      dispatch(manageSubUserPasswordErrorAction("application.errors.genericError", formData));

      return false;
    }

    let url = state.config.get("api.urls.manageSubUserPassword");
    if (!url) {
      dispatch(manageSubUserPasswordErrorAction("application.errors.subUserURLMissingMessage", formData));

      return false;
    }

    url = url.replace(/{user_id}/, formData.userId);

    const payloadData = {
      "new_password": formData.password,
      "re_new_password": formData.passwordConfirm
    };

    dispatch(preManageSubUserPasswordAction());

    return axios({
      "headers": {
        ...API.getAuthorization(state),
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": queryString.stringify(payloadData),
      "method": "PATCH",
      url
    })
    .then(response => {
      if (API.hasData(response) && UserCheck.hasUserFields(response.data)) {
        dispatch(postManageSubUserPasswordAction(response.data));

        return true;
      }
      dispatch(manageSubUserPasswordErrorAction("application.errors.systemErrorMessage", formData));

      return false;
    })
    .catch(error => {
      dispatch(manageSubUserPasswordErrorAction(error, formData));

      return false;
    });
  };
}
