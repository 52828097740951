export const UPDATE_EMAIL_ADDRESS_ACTION = "email-address/UPDATE_EMAIL_ADDRESS_ACTION";

export default function updateEmailAddressAction(emailAddress) {
  return {
    "payload": {
      emailAddress
    },
    "type": UPDATE_EMAIL_ADDRESS_ACTION
  };
}
