export const SSO_LOGIN_ERROR_ACTION = "authentication/SSO_LOGIN_ERROR_ACTION";

export default function ssoLoginErrorAction(error) {
  return {
    "payload": {
      error
    },
    "type": SSO_LOGIN_ERROR_ACTION
  };
}
