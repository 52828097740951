import {createStore, applyMiddleware, compose} from "redux";
import {createBrowserHistory} from "history";
import thunk from "redux-thunk";

import combineReducers from "./combineReducers";
import initializeTranslations from "./components/translate/actions/initialize-action";
import initializeAnalytics from "./actions/helix/initialize-action";

export const history = createBrowserHistory();

const enhancers = [];
const initialState = {};
const middleware = [thunk];

if (process.env.NODE_ENV === "development") {
  // eslint-disable-next-line no-underscore-dangle
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );


const store = createStore(combineReducers, initialState, composedEnhancers);

initializeTranslations(store);
initializeAnalytics(store);

export default store;
