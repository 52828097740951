import {every, has, partial} from "lodash";

export const userKeys = [
  "first_name",
  "last_name",
  "is_hoh",
  "email",
  "status"
];

export default {
  "hasUserFields": data => {
    if (!data || !every(userKeys, partial(has, data))) {

    return false;
  }

  return data;
  }
};
