export const SET_SECURITY_QUESTION_ERROR_ACTION = "password-reset/SET_SECURITY_QUESTION_ERROR_ACTION";

export default function setSecurityQuestionErrorAction(error) {
  return {
    "payload": {
      error
    },
    "type": SET_SECURITY_QUESTION_ERROR_ACTION
  };
}
