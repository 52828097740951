import React from "react";
import PropTypes from "prop-types";
import AccountInfoCard from "./account-info";
import {connect} from "react-redux";
import Tabs from "../tabs/index";
import Loader from "../loader";
import applicationStartActionCreator from "../../action-creators/application-start-action-creator";
import trackPageView from "../../actions/helix/track-pageview-action";

class AccountInfo extends React.Component {
  static get propTypes() {
    return {
      "appStart": PropTypes.func,
      "loading": PropTypes.bool,
      "loadingStatus": PropTypes.string,
      "loaderSuccessMessage": PropTypes.string,
      "loaderFailureMessage": PropTypes.string,
      "trackPageView": PropTypes.func,
      "user": PropTypes.shape(
        {
          "email": PropTypes.string,
          "firstName": PropTypes.string,
          "isHoh": PropTypes.bool,
          "lastName": PropTypes.string,
          "status": PropTypes.oneOf([
            "Enabled",
            "Disabled",
            "Pending Delete"
          ])
        }
      )
    };
  }

  componentDidMount() {
    this.props.appStart();
    this.props.trackPageView();
  }

  render() {
    return (
      <div className="container">
        <Loader fullscreen={true}
                overlay={true}
                show={this.props.loading}
                status={this.props.loadingStatus}
                successMessage={this.props.loaderSuccessMessage}
                failureMessage={this.props.loaderFailureMessage} />
        <Tabs showUserList={this.props.user.isHoh}/>
        <div className="row">
          <div className="account-list-cards col-md-8 col-12">
            <AccountInfoCard user={this.props.user} forceManageButton={true} />
          </div>
          <div className="account-list-quick-nav col-3 d-sm-none d-md-block">
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  "loading": state.loader.loading,
  "loadingStatus": state.loader.status,
  "loaderSuccessMessage": state.loader.successMessage,
  "loaderFailureMessage": state.apiError.errorMessage,
  "user": state.user
});

function mapDispatchToProps(dispatch) {
  return {
    "appStart": () => dispatch(applicationStartActionCreator()),
    "trackPageView": () => dispatch(trackPageView("SELFCARE_pageView_yourInfo", "yourInfo"))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountInfo);
