export const UPDATE_FIRST_NAME = "sub-users/UPDATE_FIRST_NAME";

export default function updateFirstNameAction(firstName) {
  return {
    "payload": {
      firstName
    },
    "type": UPDATE_FIRST_NAME
  };
}
