export const MANAGE_USER_ERROR = "users/MANAGE_USER_ERROR";

export default function manageUserErrorAction(error = "", data) {
  return {
    "payload": {
      error,
      data
    },
    "type": MANAGE_USER_ERROR
  };
}
