// allays import the .min version unless you need to debug Quantum calls
// the file size is significantly smaller
import Helix from "@charter/helix/build/venona/portals.min";

export default class AnalyticsService {
  static instance = null;

  constructor(config) {
    try {
      if (config) {
        this.config = config;
      } else {
        // eslint-disable-next-line no-console
        console.error("Analytics Service | Error - No Config provided.");

        return null;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`Analytics Service | Error on Constructor : ${err}`);

      return null;
    }
    this.init();
  }

  init() {
    try {
      // Before initialization you should choose how persist Analytics data

      // Helix.enablePersistence();
      // this can be omitted if you are using local storage since ver 2.10.x of Helix

      // set the collector end point
      // important to remember to define the collector endpoints
      // for the different analytics environments PROD vs DEVELOPMENT

      // Helix.set("endpoint", this.config.endpoint);
      // this can be auto detected base on the `domain` and `appEnvName`

      Helix.set("endpoint", this.config.endpoint);
      Helix.set("sendInterval", 3000);
      Helix.init(this.config).then(() => {
        if (Helix.get("currentState") === "blank") {
          Helix.trackStartSession(this.config.startSession, this.config);
        }
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  // eslint-disable-next-line valid-jsdoc
  /**
   * @doc method
   * @name analytics#track
   * @methodOf analytics
   * @returns {}
   * @description Calls Helix Track
   */
  track(id, adHocData, options) {
    const sendInterval = -1;

    try {
      if (adHocData && adHocData.sendImmediately) {
        Helix.set("sendInterval", 0);
        delete adHocData.sendImmediately;
      }
      Helix.track(id, adHocData, options);
    } catch (err) {
      if (this.debugAppEvents) {
        // eslint-disable-next-line no-console
        console.error(err);

        return;
      }
    } finally {
      if (sendInterval !== -1) {
        Helix.set("sendInterval", sendInterval);
      }
    }
  }

  // eslint-disable-next-line valid-jsdoc
  /**
   * @doc method
   * @name analytics#trackPageView
   * @methodOf analytics
   * @returns {}
   * @description Calls Helix Track with a Complete PageView Cycle (Init-Completed). To be used when no Page load time calculation is required.
   */
  // eslint-disable-next-line max-params
  trackPageView(id, pageName, adHocData, pageUrl) {
    try {
      for (const msgEventType of [
"pageViewInit",
"pageViewCompleted"
]) {
        Helix.track(id, {
          msgEventType,
          "currPageUrl": pageUrl || window.location.href,
          "currPageName": pageName,
          "currPageAppSection": "selfCareMain",
          "currPageIsLazyLoad": false,
          "msgTriggeredBy": "user",
          ...adHocData
        });
      }
    } catch (err) {
      if (this.debugAppEvents) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
  }

  set(id, value) {
    try {
      Helix.set(id, value);
    } catch (err) {
      if (this.debugAppEvents) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
  }

  // eslint-disable-next-line consistent-return
  get(value) {
    try {
      return Helix.get(value);
    } catch (err) {
      if (this.debugAppEvents) {
        // eslint-disable-next-line no-console
        console.error(err);

        return null;
      }
    }
  }
}
