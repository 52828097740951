export const SECURITY_QUESTION_ERROR_ACTION = "password-reset/SECURITY_QUESTION_ERROR";

export default function securityQuestionAction(error) {
  return {
    "payload": {
      error
    },
    "type": SECURITY_QUESTION_ERROR_ACTION
  };
}
