import React from "react";
import PropTypes from "prop-types";
import {Translate} from "react-i18nify";
import AccountInfoMenu from "./account-info-menu";

class AccountInfoCard extends React.Component {
  static get propTypes() {
    return {
      "index": PropTypes.number,
      "showManageUserPassword": PropTypes.func,
      "user": PropTypes.shape({
        "email": PropTypes.string,
        "firstName": PropTypes.string,
        "isHoh": PropTypes.bool,
        "lastName": PropTypes.string,
        "password": PropTypes.string,
        "status": PropTypes.oneOf([
          "Enabled",
          "Disabled",
          "Pending Delete"
        ])
      }),
      "userRefs": PropTypes.array
    };
  }

  render() {
    let accountTypeTranslation = "accountManagement.StandardAccountLabel";
    if (this.props.user.isHoh) {
      accountTypeTranslation = "accountManagement.PrimaryAccountLabel";
    }

    return (
      <>
        <div className="card account-card mb-5" id={this.props.user.email}>
          <div className="card-body pb-0">
            <h3 className="card-title">{this.props.user.firstName} {this.props.user.lastName}</h3>
            <p className="card-subtitle mb-2 text-muted small font-italic">
              <Translate value={accountTypeTranslation} />
            </p>
          </div>
        </div>
        <div className="card account-card mb-5" id={this.props.user.email}>
          <div className="card-body pb-0">
            <div className="row">
              <h4 className="card-title mb-4 col-9"><Translate value="accountManagement.contactInfo" /></h4>
              <AccountInfoMenu className="col-3 text-right" user={this.props.user}/>
            </div>
            <div className="row border-bottom px-0 pt-1 pb-3 mx-0 my-2">
              <div className="font-weight-bold col-12 col-sm-4 p-0" aria-hidden>
                <Translate value="accountManagement.emailAddress"/>
              </div>
              <div className="text-sm-right col-12 col-sm-8 p-0">
                <Translate className="sr-only" value="accountManagement.emailAddress"/>
                &nbsp;
                {this.props.user.email}
              </div>
            </div>
            <div className="row border-bottom px-0 pt-1 pb-3 mx-0 my-2">
              <div className="font-weight-bold col-12 col-sm-4 p-0" aria-hidden>
                <Translate value="accountManagement.firstNameLabel"/>
              </div>
              <div className="text-sm-right col-12 col-sm-8 p-0">
                <Translate className="sr-only" value="accountManagement.firstNameLabel"/>
                &nbsp;
                {this.props.user.firstName}
              </div>
            </div>
            <div className="row px-0 pt-1 pb-3 mx-0 my-2">
              <div className="font-weight-bold col-12 col-sm-4 p-0" aria-hidden>
                <Translate value="accountManagement.lastNameLabel"/>
              </div>
              <div className="text-sm-right col-12 col-sm-8 p-0">
                <Translate className="sr-only" value="accountManagement.lastNameLabel"/>
                &nbsp;
                {this.props.user.lastName}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AccountInfoCard;
