import produce from "immer";
import {get} from "lodash";
import {POST_CREATE_SUB_USER_ACTION} from "../actions/sub-users/post-create-sub-user-action";
import {CREATE_SUB_USER_ERROR_ACTION} from "../actions/sub-users/create-sub-user-error-action";
import {PRE_CREATE_SUB_USER_ACTION} from "../actions/sub-users/pre-create-sub-user-action";
import {MANAGE_SUB_USER_ERROR} from "../actions/sub-users/manage-sub-user-error-action";
import {PRE_MANAGE_SUB_USER} from "../actions/sub-users/pre-manage-sub-user-action";
import {POST_MANAGE_SUB_USER} from "../actions/sub-users/post-manage-sub-user-action";
import {HIDE_CREATE_SUB_USER_MODAL} from "../actions/sub-users/hide-create-sub-user-modal-action";
import {HIDE_MANAGE_SUB_USER_NAME_MODAL} from "../actions/sub-users/hide-manage-sub-user-name-modal-action";
import {HIDE_MANAGE_SUB_USER_PASSWORD_MODAL} from "../actions/sub-users/hide-manage-sub-user-password-modal-action";
import {ENABLE_DISABLE_SUB_USER_ERROR} from "../actions/sub-users/enable-disable-sub-user-error-action";
import {DELETE_SUB_USER_ERROR} from "../actions/sub-users/delete-sub-user-error-action";
import {RESTORE_SUB_USER_ERROR} from "../actions/sub-users/restore-sub-user-error-action";
import {SHOW_MANAGE_SUB_USER_NAME_MODAL} from "../actions/sub-users/show-manage-sub-user-name-modal-action";
import {SHOW_MANAGE_SUB_USER_PASSWORD_MODAL} from "../actions/sub-users/show-manage-sub-user-password-modal-action";
import {SHOW_CREATE_SUB_USER_MODAL} from "../actions/sub-users/show-create-sub-user-modal-action";
import {SHOW_MANAGE_USER_PASSWORD_MODAL} from "../actions/users/show-manage-user-password-modal-action";
import {HIDE_MANAGE_USER_PASSWORD_MODAL} from "../actions/users/hide-manage-user-password-modal-action";
import {POST_MANAGE_USER} from "../actions/users/post-manage-user-action";
import {PRE_MANAGE_USER} from "../actions/users/pre-manage-user-action";
import {MANAGE_USER_ERROR} from "../actions/users/manage-user-error-action";
import {URT_ERROR_ACTION} from "../actions/username-recovery/username-recovery-error-action";
import {PASSWORD_RESET_ERROR_ACTION} from "../actions/password-reset/password-reset-error-action";
import {POST_PASSWORD_RESET_ACTION} from "../actions/password-reset/post-password-reset-action";
import {PRE_PASSWORD_RESET_ACTION} from "../actions/password-reset/pre-password-reset-action";
import {ACCOUNT_VALIDATION_ERROR_ACTION} from "../actions/password-reset/account-validation-error-action";
import {SET_SECURITY_QUESTION_ERROR_ACTION} from "../actions/password-reset/set-security-question-error-action";
import {PRE_URT_ACTION} from "../actions/username-recovery/pre-username-recovery-action";
import {POST_URT_ACTION} from "../actions/username-recovery/post-username-recovery-action";
import {PRE_ACCOUNT_VALIDATION_ACTION} from "../actions/password-reset/pre-account-validation-action";
import {POST_ACCOUNT_VALIDATION_ACTION} from "../actions/password-reset/post-account-validation-action";
import {PRE_SET_SECURITY_QUESTION_ACTION} from "../actions/password-reset/pre-set-security-question-action";
import {POST_SET_SECURITY_QUESTION_ACTION} from "../actions/password-reset/post-set-security-question-action";
import {VERIFY_SECURITY_QUESTION_ERROR_ACTION} from "../actions/password-reset/verify-security-question-error-action";
import {PRE_VERIFY_SECURITY_QUESTION_ACTION} from "../actions/password-reset/pre-verify-security-question-action";
import {POST_VERIFY_SECURITY_QUESTION_ACTION} from "../actions/password-reset/post-verify-security-question-action";
import {POST_MANAGE_SUB_USER_PASSWORD} from "../actions/sub-users/post-manage-sub-user-password-action";
import {MANAGE_SUB_USER_PASSWORD_ERROR} from "../actions/sub-users/manage-sub-user-password-error-action";
import {PRE_MANAGE_SUB_USER_PASSWORD} from "../actions/sub-users/pre-manage-sub-user-password-action";

const initialState = () => ({
  "errorMessage": ""
});

function processErrors(error, draft) {
  // This is why we don't mix types
  if (!error) {
    draft.errorMessage = "application.errors.default";

    return draft;
  } else if (typeof error === "string") {
    draft.errorMessage = error;

    return draft;

  } else if (!get(error, "response.status")) {
    draft.errorMessage = "application.errors.default";

    return draft;
  }

  const status = get(error, "response.status");

  switch (status) {
    case 403:
      draft.errorMessage = "application.errors.unauthorizedRequestMessage";

      if (error.response.title === "ip_forbidden") {
        draft.errorMessage = "application.errors.createSubUserGeoIPDenied";
      }

      return draft;
    case 401:
    case 404:
      draft.errorMessage = "application.errors.notFoundMessage";

      return draft;

    case 422:
      draft.errorMessage = "application.errors.genericError";

      return draft;

    default:
      draft.errorMessage = "application.errors.default";

      return draft;
  }
}

// eslint-disable-next-line max-statements,max-lines-per-function,complexity
export default (state = initialState(), action = {}) => produce(state, draft => {
  const error = get(action, "payload.error", null);

  switch (action.type) {
    case SHOW_MANAGE_SUB_USER_PASSWORD_MODAL:
    case SHOW_MANAGE_SUB_USER_NAME_MODAL:
    case SHOW_CREATE_SUB_USER_MODAL:
    case SHOW_MANAGE_USER_PASSWORD_MODAL:

      return initialState();
    case CREATE_SUB_USER_ERROR_ACTION:
      if (!error || !get(error, "response.status", false)) {
        return processErrors(error, draft);
      }

      if (error.response.status === 409) {
        draft.errorMessage = "accountManagement.subUserError.usernameAlreadyExists";

        return draft;
      }

      if (error.response.status === 429) {
        draft.errorMessage = "accountManagement.subUserError.tooManyUsersMessage";

        return draft;
      }

      return processErrors(error, draft);
    case ACCOUNT_VALIDATION_ERROR_ACTION:
    case URT_ERROR_ACTION:
      if (!error || !error.status || error.status === 404) {
        draft.errorMessage = "application.errors.default";

        return draft;
      }

      if (error.status === 422) {
        draft.errorMessage = "application.errors.noAccount";

        return draft;
      }

      if (error.status === 429) {
        draft.errorMessage = "application.errors.429";

        return draft;
      }

      return draft;
    case ENABLE_DISABLE_SUB_USER_ERROR:
    case RESTORE_SUB_USER_ERROR:
    case DELETE_SUB_USER_ERROR:
    case MANAGE_SUB_USER_ERROR:
    case MANAGE_SUB_USER_PASSWORD_ERROR:
    case MANAGE_USER_ERROR:
    case PASSWORD_RESET_ERROR_ACTION:
    case SET_SECURITY_QUESTION_ERROR_ACTION:

      return processErrors(error, draft);
    case PRE_CREATE_SUB_USER_ACTION:
    case PRE_MANAGE_SUB_USER:
    case PRE_MANAGE_SUB_USER_PASSWORD:
    case PRE_MANAGE_USER:
    case PRE_PASSWORD_RESET_ACTION:
    case PRE_URT_ACTION:
    case PRE_ACCOUNT_VALIDATION_ACTION:
    case PRE_SET_SECURITY_QUESTION_ACTION:
    case PRE_VERIFY_SECURITY_QUESTION_ACTION:
    case POST_CREATE_SUB_USER_ACTION:
    case POST_MANAGE_SUB_USER:
    case POST_MANAGE_SUB_USER_PASSWORD:
    case POST_MANAGE_USER:
    case POST_PASSWORD_RESET_ACTION:
    case POST_URT_ACTION:
    case POST_ACCOUNT_VALIDATION_ACTION:
    case POST_SET_SECURITY_QUESTION_ACTION:
    case POST_VERIFY_SECURITY_QUESTION_ACTION:
    case HIDE_CREATE_SUB_USER_MODAL:
    case HIDE_MANAGE_SUB_USER_NAME_MODAL:
    case HIDE_MANAGE_SUB_USER_PASSWORD_MODAL:
    case HIDE_MANAGE_USER_PASSWORD_MODAL:
      draft.errorMessage = "";

      return draft;
    case VERIFY_SECURITY_QUESTION_ERROR_ACTION:
      if (!error || !error.status || error.status === 404) {
        draft.errorMessage = "application.errors.default";

        return draft;
      }

      if (error.status === 422) {
        draft.errorMessage = "passwordReset.error.invalidAnswer";

        return draft;
      }

      if (error.status === 429) {
        draft.errorMessage = "application.errors.429";

        return draft;
      }

      return draft;
    default:
      return state;
  }
});
