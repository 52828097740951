import produce from "immer";
import {SHOW_CREATE_SUB_USER_MODAL} from "../actions/sub-users/show-create-sub-user-modal-action";
import {HIDE_CREATE_SUB_USER_MODAL} from "../actions/sub-users/hide-create-sub-user-modal-action";
import {POST_CREATE_SUB_USER_ACTION} from "../actions/sub-users/post-create-sub-user-action";
import {SHOW_MANAGE_SUB_USER_NAME_MODAL} from "../actions/sub-users/show-manage-sub-user-name-modal-action";
import {HIDE_MANAGE_SUB_USER_NAME_MODAL} from "../actions/sub-users/hide-manage-sub-user-name-modal-action";
import {SHOW_MANAGE_SUB_USER_PASSWORD_MODAL} from "../actions/sub-users/show-manage-sub-user-password-modal-action";
import {HIDE_MANAGE_SUB_USER_PASSWORD_MODAL} from "../actions/sub-users/hide-manage-sub-user-password-modal-action";
import {POST_MANAGE_SUB_USER} from "../actions/sub-users/post-manage-sub-user-action";
import {SHOW_MANAGE_USER_PASSWORD_MODAL} from "../actions/users/show-manage-user-password-modal-action";
import {HIDE_MANAGE_USER_PASSWORD_MODAL} from "../actions/users/hide-manage-user-password-modal-action";
import {POST_MANAGE_USER} from "../actions/users/post-manage-user-action";
import {POST_MANAGE_SUB_USER_PASSWORD} from "../actions/sub-users/post-manage-sub-user-password-action";

export const initialState = () => ({
  "isCreateSubUserFormOpen": false,
  "isManageSubUserNameFormOpen": false,
  "isManageSubUserPasswordFormOpen": false,
  "isManageUserPasswordFormOpen": false,
  "isAnyModalOpen": false,
  "returnFocusRef": null
});

function setAnyModalOpenFlag(draft) {
  draft.isAnyModalOpen = draft.isCreateSubUserFormOpen ||
    draft.isManageSubUserNameFormOpen ||
    draft.isManageSubUserPasswordFormOpen ||
    draft.isManageUserPasswordFormOpen;
}

export default (state = initialState(), action = {}) => produce(state, draft => {
  switch (action.type) {
    case SHOW_CREATE_SUB_USER_MODAL:
      // eslint-disable-next-line no-param-reassign
      draft = initialState();
      draft.isCreateSubUserFormOpen = true;
      setAnyModalOpenFlag(draft);

      return draft;
    case SHOW_MANAGE_SUB_USER_NAME_MODAL:
      // eslint-disable-next-line no-param-reassign
      draft = initialState();
      draft.isManageSubUserNameFormOpen = true;
      draft.returnFocusRef = action.payload.returnFocusRef;
      setAnyModalOpenFlag(draft);

      return draft;
    case SHOW_MANAGE_SUB_USER_PASSWORD_MODAL:
      // eslint-disable-next-line no-param-reassign
      draft = initialState();
      draft.isManageSubUserPasswordFormOpen = true;
      draft.returnFocusRef = action.payload.returnFocusRef;
      setAnyModalOpenFlag(draft);

      return draft;
    case SHOW_MANAGE_USER_PASSWORD_MODAL:
      // eslint-disable-next-line no-param-reassign
      draft = initialState();
      draft.isManageUserPasswordFormOpen = true;
      draft.returnFocusRef = action.payload.returnFocusRef;
      setAnyModalOpenFlag(draft);

      return draft;
    case HIDE_CREATE_SUB_USER_MODAL:
    case HIDE_MANAGE_SUB_USER_NAME_MODAL:
    case HIDE_MANAGE_SUB_USER_PASSWORD_MODAL:
    case HIDE_MANAGE_USER_PASSWORD_MODAL:
    case POST_CREATE_SUB_USER_ACTION:
    case POST_MANAGE_SUB_USER:
    case POST_MANAGE_SUB_USER_PASSWORD:
    case POST_MANAGE_USER:
      return {
        ...initialState(),
        "returnFocusRef": draft.returnFocusRef
      };
    default:
      return state;
  }
});
