import {combineReducers} from "redux";
import authReducer from "./reducers/auth-reducer";
import apiErrorReducer from "./reducers/api-error-reducer";
import captchaReducer from "./reducers/captcha-reducer";
import configReducer from "@internet-features/config";
import currentPasswordReducer from "./reducers/current-password-reducer";
import errorReducer from "./reducers/application-error-reducer";
import hamburgerMenuReducer from "@internet-features/header/dist/hamburger-menu/reducers/hamburger-menu-reducer.js";
import loginFormReducer from "./reducers/login-form-reducer";
import translateReducer from "./components/translate/reducers/translate-reducer";
import loadingReducer from "./reducers/loader-reducer";
import activeUserReducer from "./reducers/active-user-reducer";
import ssoTokenReducer from "./reducers/sso-token-reducer";
import emailAddressFieldReducer from "./reducers/email-address-field-reducer";
import userReducer from "./reducers/user-reducer";
import usernameFieldReducer from "./reducers/username-field-reducer";
import macAddressFieldReducer from "./reducers/mac-address-field-reducer";
import phoneNumberFieldReducer from "./reducers/phone-number-field-reducer";
import passwordResetReducer from "./reducers/password-reset-reducer";
import usernameRecoveryReducer from "./reducers/username-recovery-reducer";
import subUserReducer from "./reducers/sub-user-reducer";
import passwordReducer from "./reducers/password-reducer";
import nameReducer from "./reducers/name-reducer";
import formReducer from "./reducers/form-reducer";
import securityQuestionReducer from "./reducers/security-question-form-reducer";
import helixReducer from "./reducers/helix-reducer";


export default combineReducers({
  "activeUser": activeUserReducer,
  "apiError": apiErrorReducer,
  "auth": authReducer,
  "captcha": captchaReducer,
  "config": configReducer,
  "currentPasswordField": currentPasswordReducer,
  "emailAddress": emailAddressFieldReducer,
  "error": errorReducer,
  "forms": formReducer,
  "hamburgerMenu": hamburgerMenuReducer,
  "helix": helixReducer,
  "loader": loadingReducer,
  "loginForm": loginFormReducer,
  "macAddress": macAddressFieldReducer,
  "nameFields": nameReducer,
  "passwordFields": passwordReducer,
  "passwordReset": passwordResetReducer,
  "phoneNumber": phoneNumberFieldReducer,
  "securityQuestion": securityQuestionReducer,
  "subUsers": subUserReducer,
  "token": ssoTokenReducer,
  "translate": translateReducer,
  "user": userReducer,
  "usernameField": usernameFieldReducer,
  "usernameRecovery": usernameRecoveryReducer
});
