export const VERIFY_SECURITY_QUESTION_ERROR_ACTION = "password-reset/VERIFY_SECURITY_QUESTION_ERROR_ACTION";

export default function verifySecurityQuestionErrorAction(error) {
  return {
    "payload": {
      error
    },
    "type": VERIFY_SECURITY_QUESTION_ERROR_ACTION
  };
}
