/* eslint-disable max-lines */
import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Translate, I18n} from "react-i18nify";
import Captcha from "../captcha";
import Loader from "../loader";
import FormError from "../error/form";
import "./login.scss";
import updateLoginUsername from "../../actions/login-form/update-login-username-action";
import updateLoginPassword from "../../actions/login-form/update-login-password-action";
import validateLoginUsernameAction from "../../actions/login-form/validate-login-username-action";
import validateLoginPasswordAction from "../../actions/login-form/validate-login-password-action";
import setRememberMe from "../../actions/login-form/remember-me-action";
import passwordLoginActionCreator from "../../action-creators/authentication/password-login-action-creator";
import trackPageView from "../../actions/helix/track-pageview-action";
import checkForError from "../../utilities/track-ui-error";
import trackData from "../../actions/helix/track-data-action";

class Login extends React.Component {
  constructor (props) {
    super(props);

    this.setFocusToError = this.setFocusToError.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showRenderErrors = this.showRenderErrors.bind(this);

    this.usernameRef = React.createRef();
    this.passwordRef = React.createRef();
    this.captchaRef = React.createRef();
    this.renderErrors = true;
  }

  static get propTypes () {
    return {
      "authenticate": PropTypes.func,
      "helix": PropTypes.object,
      "loading": PropTypes.bool,
      "loginMessage": PropTypes.string,
      "passwordError": PropTypes.string,
      "passwordIsValid": PropTypes.bool,
      "rememberMe": PropTypes.bool,
      "setRememberMe": PropTypes.func,
      "trackPageView": PropTypes.func,
      "trackError": PropTypes.func,
      "trackSelectAction": PropTypes.func,
      "trackSelectActionPRT": PropTypes.func,
      "trackSelectActionURT": PropTypes.func,
      "updatePassword": PropTypes.func,
      "updateUsername": PropTypes.func,
      "username": PropTypes.string,
      "usernameError": PropTypes.string,
      "usernameIsValid": PropTypes.bool,
      "validatePassword": PropTypes.func,
      "validateUsername": PropTypes.func
    };
  }

  setFocusToError() {
    if (this.props.usernameError && this.usernameRef.current) {
      this.usernameRef.current.focus();

      return;
    }
    if (this.props.passwordError && this.passwordRef.current) {
      this.passwordRef.current.focus();
    }
  }

  shouldComponentUpdate() {
    return this.renderErrors;
  }

  showRenderErrors(val) {
    this.renderErrors = val;
  }

  // eslint-disable-next-line max-statements
  async handleSubmit (event) {
    event.preventDefault();
    await this.props.authenticate();

    this.setFocusToError();
    if (this.captchaRef.current) {
      this.captchaRef.current.reset();
    }

    return false;
  }

  componentDidMount() {
    this.props.trackPageView();
  }

  componentDidUpdate(prevProps) {
    checkForError(prevProps, this.props, null, "usernameError");
    checkForError(prevProps, this.props, null, "passwordError");
  }

  // eslint-disable-next-line max-lines-per-function,max-statements
  render () {
    let loginError = "";

    let passwordDescriptionClass = "d-none";
    let passwordInputClass = "";
    let usernameDescriptionClass = "d-none";
    let usernameInputClass = "";

    if (!this.props.usernameIsValid) {
      usernameDescriptionClass = "d-block";
      usernameInputClass = "border-danger";
    }

    if (!this.props.passwordIsValid) {
      passwordDescriptionClass = "d-block";
      passwordInputClass = "border-danger";
    }

    if (this.props.loginMessage.length) {
      loginError = <FormError error={this.props.loginMessage}/>;
    }

    return (
      <div role="main" className="row mb-3 justify-content-center align-items-center">
        <section className="col-11 col-md-8 col-xl-6 my-5 login-form-max-width">
          <div className="card row justify-content-center align-items-center">
            <div className="card-body col-12 col-lg-8">
              <h1 className="h2 mb-4 text-center login-title">
                <Translate value="loginForm.title"/>
              </h1>
              <form onSubmit={event => this.handleSubmit(event)}>
                <div className="form-group" aria-live="assertive">{loginError}</div>
                <div className="form-group">
                  <label className="w-100">
                    <Translate value="loginForm.emailLabel"/>
                    <input
                      type="email"
                      ref={this.usernameRef}
                      className={`username-input form-control focus-outline-primary ${usernameInputClass}`}
                      autoFocus={true}
                      value={this.props.username}
                      aria-describedby="usernameHelp"
                      aria-invalid={!this.props.usernameIsValid}
                      onChange={this.props.updateUsername}
                      onBlur={this.props.validateUsername}
                    />
                  </label>
                  <small
                    id="usernameHelp"
                    className={`text-danger ${usernameDescriptionClass}`}
                    aria-hidden={this.props.usernameIsValid}
                  >
                    <Translate value="loginForm.emailHelp"/>
                  </small>
                </div>
                <div className="form-group">
                  <label className="w-100">
                    <Translate value="loginForm.passwordLabel"/>
                    <input
                      type="password"
                      ref={this.passwordRef}
                      className={`password-input form-control focus-outline-primary ${passwordInputClass}`}
                      aria-describedby="passwordHelp"
                      aria-invalid={!this.props.passwordIsValid}
                      onChange={this.props.updatePassword}
                      onBlur={this.props.validatePassword}
                    />
                  </label>
                  <small
                    id="passwordHelp"
                    className={`text-danger ${passwordDescriptionClass}`}
                    aria-hidden={this.props.passwordIsValid}
                  >
                    <Translate value="loginForm.passwordHelp"/>
                  </small>
                </div>
                  <Captcha captchaRef={this.captchaRef} />
                <div className="form-group">
                  <div className="form-group">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input focus-outline-primary cursor-pointer"
                        id="rememberMeCheckbox"
                        onChange={this.props.setRememberMe}
                        defaultChecked={this.props.rememberMe}
                        aria-label={I18n.t("loginForm.rememberMeLabel")}
                        onMouseEnter={() => this.showRenderErrors(false)}
                        onMouseLeave={() => this.showRenderErrors(true)}
                      />
                      <label htmlFor="rememberMeCheckbox" className="cursor-pointer" aria-hidden={true}>
                        <Translate value="loginForm.rememberMeLabel"/>
                      </label>
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={this.props.loading}
                    className="btn btn-lg btn-primary btn-block focus-outline-primary"
                  >
                    <Translate value="signIn"/>
                  </button>
                </div>
              </form>
              <div>
                <div className="row text-center justify-content-center mb-2">
                  <a
                    onMouseEnter={() => this.showRenderErrors(false)}
                    onMouseLeave={() => this.showRenderErrors(true)}
                    href="/username-recovery"
                    onClick={this.props.trackSelectActionURT}>
                    <Translate value="loginForm.forgotEmail"/>
                  </a>
                </div>
                <div className="row text-center justify-content-center mb-3">
                  <a
                    onMouseEnter={() => this.showRenderErrors(false)}
                    onMouseLeave={() => this.showRenderErrors(true)}
                    href="/password-reset"
                    onClick={this.props.trackSelectActionPRT}>
                    <Translate value="loginForm.forgotPassword"/>
                  </a>
                </div>
              </div>
            </div>
            <div>
            </div>
            <Loader fullscreen={false} overlay={true} show={this.props.loading}/>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  "helix": state.helix.analyticsService,
  "loading": state.loader.loading,
  "loginMessage": state.loginForm.loginMessage,
  "passwordError": state.loginForm.passwordError,
  "passwordIsValid": state.loginForm.passwordIsValid,
  "rememberMe": state.loginForm.rememberMe,
  "username": state.loginForm.username,
  "usernameError": state.loginForm.usernameError,
  "usernameIsValid": state.loginForm.usernameIsValid
});

const mapDispatchToProps = dispatch => ({
  "authenticate": () => dispatch(passwordLoginActionCreator()),
  "setRememberMe": event => dispatch(setRememberMe(event.target.checked)),
  "updateUsername": event => dispatch(updateLoginUsername(event.target.value)),
  "updatePassword": event => dispatch(updateLoginPassword(event.target.value)),
  "validatePassword": () => dispatch(validateLoginPasswordAction()),
  "validateUsername": () => dispatch(validateLoginUsernameAction()),
  "trackPageView": () => dispatch(trackPageView("SELFCARE_pageView_login", "login")),
  "trackSelectActionURT": () => dispatch(trackData("SELFCARE_selectAction_usernameRecovery")),
  "trackSelectActionPRT": () => dispatch(trackData("SELFCARE_selectAction_passwordRecovery")),
  "trackError": errorMessage => dispatch(trackData(
      "SELFCARE_error_login",
      {
        "appErrorType": "generic",
        "appErrorMessage": errorMessage
      }
  ))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
