import produce from "immer";
import isTokenValid from "../validators/is-sso-token-valid";
import isTokenExpired from "../validators/is-sso-token-expired";
import {POST_SSO_LOGIN_ACTION} from "../actions/authentication/post-sso-login-action";
import {SSO_LOGIN_ERROR_ACTION} from "../actions/authentication/sso-login-error-action";
import {SSO_REFRESH_ERROR_ACTION} from "../actions/authentication/sso-refresh-error-action";
import {PRE_SSO_REFRESH} from "../actions/authentication/pre-sso-refresh-action";
import {POST_SSO_REFRESH} from "../actions/authentication/post-sso-refresh-action";
import {SIGN_OUT} from "@internet-features/header/src/actions/sign-out-action";
import {TOKEN_REFRESH_INITIALIZED} from "../actions/authentication/token-refresh-initialized-action";
import {getConfigData} from "@internet-features/config/src/reducers/config-reducer";
import {get} from "lodash";

function initialState() {
  const initValue = {
    "accessToken": null,
    "expiresIn": null,
    "refreshToken": null,
    "tokenType": null,
    "refreshTokenTryCount": 0,
    "refreshHandle": null
  };

  if (!localStorage.getItem("sso")) {
    return initValue;
  }

  const token = JSON.parse(localStorage.getItem("sso"));
  token.refreshHandle = null;

  if (!isTokenExpired(token.expiresIn)) {

    return token;
  }

  return initValue;
}

function getExpireTime(action, expireDrift) {
  const expireDate = new Date();
  const expiresInSeconds = (get(action, "payload.token.expires_in") - expireDrift) * 1000;

  expireDate.setTime(expireDate.getTime() + expiresInSeconds);

  return expireDate.getTime();
}

const resetDraft = draft => {
  draft.tokenType = null;
  draft.expiresIn = null;
  draft.accessToken = null;
  draft.refreshToken = null;
  draft.refreshTokenTryCount = 0;
  if (draft.refreshHandle) {
    clearTimeout(draft.refreshHandle);
  }
  draft.refreshHandle = null;

  return draft;
};

const reset = draft => {
  if (localStorage.getItem("sso")) {
    localStorage.removeItem("sso");
  }

  return resetDraft(draft);
};

export default (state = initialState(), action = {}) => produce(state, draft => {
  const token = get(action, "payload.token");

  switch (action.type) {
    case TOKEN_REFRESH_INITIALIZED:
      draft.refreshHandle = get(action, "payload.handle");

      return draft;
    case PRE_SSO_REFRESH:
      draft.refreshTokenTryCount += 1;

      return draft;
    case POST_SSO_REFRESH:
      draft.refreshTokenTryCount = 0;
    // eslint-disable-next-line no-fallthrough
    case POST_SSO_LOGIN_ACTION:
      if (!isTokenValid(token)) {
        return initialState();
      }

      // eslint-disable-next-line no-case-declarations
      const config = getConfigData();

// eslint-disable-next-line no-case-declarations
      const expireTime = getExpireTime(action, get(config, "api.oauth.refresh.expireDrift"));

      if (isTokenExpired(expireTime)) {
        return initialState();
      }

      /* eslint-disable camelcase */
      draft.tokenType = get(action, "payload.token.token_type");
      draft.expiresIn = expireTime;
      draft.accessToken = get(action, "payload.token.access_token");
      draft.refreshToken = get(action, "payload.token.refresh_token");
      /* eslint-enable */

      localStorage.setItem("sso", JSON.stringify(draft));

      return draft;
    case SSO_REFRESH_ERROR_ACTION:
    case SSO_LOGIN_ERROR_ACTION:
      if (get(action, "payload.error.response.status") === 401 ||
        get(action, "payload.error.response.data.title") === "invalid_sso_token"
      ) {
        return reset(draft);
      }

      return state;
    case SIGN_OUT:
      return reset(draft);
    default:
      return state;
  }
});
