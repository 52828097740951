import axios from "axios";
import "core-js";
import queryString from "querystring";
import preSSORefreshAction from "../../actions/authentication/pre-sso-refresh-action";
import createSSORefreshErrorAction from "../../actions/authentication/sso-refresh-error-action";
import postSSORefreshAction from "../../actions/authentication/post-sso-refresh-action";
import setupTokenRefreshActionCreator from "./setup-token-refresh-action-creator";
import api from "../common/api-checks";
import checks from "../common/api-token-checks";
import signOut from "@internet-features/header/src/actions/sign-out-action";
import {get} from "lodash";

// eslint-disable-next-line max-lines-per-function
export default function tokenRefreshActionCreator() {
  // eslint-disable-next-line max-statements
  return (dispatch, getState) => {
    const state = getState();

    // eslint-disable-next-line prefer-destructuring
    const refreshToken = get(state, "token.refreshToken");
    if (!refreshToken) {
      dispatch(signOut());

      return false;
    }

    const url = state.config.get("api.oauth.auth");
    const clientId = state.config.get("api.oauth.client.id");
    const clientSecret = state.config.get("api.oauth.client.secret");

    if (!url) {
      dispatch(createSSORefreshErrorAction("OAuth URL Missing"));

      return false;
    }

    if (!clientId) {
      dispatch(createSSORefreshErrorAction("OAuth Client Id Missing"));

      return false;
    }

    if (!clientSecret) {
      dispatch(createSSORefreshErrorAction("OAuth Client Secret Missing"));

      return false;
    }

    dispatch(preSSORefreshAction());

    const payload = {
      "client_id": clientId,
      "client_secret": clientSecret,
      "grant_type": "refresh_token",
      "refresh_token": refreshToken
    };

    return axios({
        "data": queryString.stringify(payload),
        "headers": {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        "method": "POST",
        url,
        "withCredentials": true
    }).then(async response => {
      if (api.hasData(response) && checks.hasTokenFields(response.data)) {
        await dispatch(postSSORefreshAction(response.data));
        dispatch(setupTokenRefreshActionCreator());

        return true;
      }

      return dispatch(signOut());
    })
    .catch(error => {
      // eslint-disable-next-line no-shadow
      const state = getState();
      if (error.message === "Network Error" && get(state, "token.refreshTokenTryCount") < state.config.get("api.oauth.refresh.retryCount")) {
        dispatch(setupTokenRefreshActionCreator());

        return true;
      }

      return dispatch(signOut());
    });
  };
}
