export const POST_GET_SUB_USER_ACTION = "sub-users/POST_GET_SUB_USER_ACTION";

export default function postGetSubUserAction(users) {
  return {
    "payload": {
      users
    },
    "type": POST_GET_SUB_USER_ACTION
  };
}
