import axios from "axios";
import "core-js";
import preRestoreSubUserAction from "../../actions/sub-users/pre-restore-sub-user-action";
import restoreSubUserErrorAction from "../../actions/sub-users/restore-sub-user-error-action";
import postRestoreSubUserAction from "../../actions/sub-users/post-restore-sub-user-action";
import API from "../common/api-checks";
import checker from "../common/api-user-checks";
import validateUser from "../../validators/is-user-valid";

export default function restoreSubUserActionCreator(user) {
  /* eslint-disable max-statements */
  return (dispatch, getState) => {
    const state = getState();

    if (!validateUser(user)) {
      dispatch(preRestoreSubUserAction("accountManagement.subUserError.invalidUserMessage"));

      return false;
    }

    if (user.status !== "Pending Delete") {

      return false;
    }

    let url = state.config.get("api.urls.restoreSubUser");

    if (!url) {
      dispatch(restoreSubUserErrorAction("application.errors.subUserURLMissingMessage"));

      return false;
    }

    url = url.replace(/{user_id}/, user.userId);

    dispatch(preRestoreSubUserAction());

    return axios({
      "headers": {
        ...API.getAuthorization(state),
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "method": "POST",
      url
    })
      .then(response => {
        // eslint-disable-next-line no-extra-parens
        if (API.hasData(response) && checker.hasUserFields(response.data)) {
          dispatch(postRestoreSubUserAction(response.data));

          return true;
        }
        dispatch(restoreSubUserErrorAction("application.errors.systemErrorMessage"));

        return false;
      })
      .catch(error => {
        dispatch(restoreSubUserErrorAction(error));

        return false;
      });
  };
}
